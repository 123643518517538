import { logo } from "Utils/Images";
import React from "react";
import { GiCommercialAirplane } from "react-icons/gi";
import { useNavigate } from "react-router-dom";

export default function Logo({ airplanePosition }) {
  const navigate = useNavigate();
  return (
    <div className="relative  ">
      <img
        src={logo}
        alt="Maharaja flight"
        className="relative -left-12 w-52 cursor-pointer sm:-left-16 sm:w-72 "
        onClick={() => {
          navigate("/");
          window.scrollTo(0, 0);
        }}
      />
      <GiCommercialAirplane
        className={`absolute top-1 w-3 text-red-500 sm:left-16 sm:w-6  ${
          airplanePosition ? airplanePosition : "left-16"
        } sm:top-2 `}
        size={35}
      />
    </div>
  );
}
