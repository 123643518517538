// store.jsx
// store/store.js
import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import userReducer from "./userSlice";
import offerReducer from "./StoreExculsiceOffersData/offersSlice"; // Only import the offerReducer

const rootReducer = combineReducers({
  user: userReducer,
  // Add other reducers as needed
  offers: offerReducer, // Only include the offer slice
});

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  // Add middleware or enhancers as needed
});

const persistor = persistStore(store);

export default store; // <-- Corrected export

export { persistor, store }; // <-- Export persistor separately if needed
