import React from "react";
import Logo from "components/maharjalogowithplane/Logo";
export default function WhyBook({ Options, heading, icon }) {
  return (
    <section className="m-auto my-0 mb-10 max-w-8xl px-5 sm:my-10 sm:mb-20 sm:mt-12 sm:px-0">
      <div className="mb-5 ">
        <Logo />
        <h2 className="madimi-one-regular text-md -mt-5  font-semibold text-gray-800  sm:text-3xl">
          Why Book {heading} With maharajaflights.com?
        </h2>
      </div>
      <div className="flex flex-wrap  justify-start gap-5 lg:px-0 ">
        {/* Using array map to render each hotel option */}
        {Options.map((item, index) => (
          <div
            key={index}
            className=" sm:box_shadow mt-0 flex h-20 w-full gap-5 rounded-none border-0 border-b border-gray-200 px-0 text-start sm:mt-16 sm:block sm:h-48 sm:w-72 sm:gap-0 sm:rounded-lg sm:border-2   sm:p-5 sm:px-0 sm:text-center"
          >
            <div className="m-auto mt-4 h-10 w-10 rounded-full bg-transparent object-cover p-0 sm:-mt-16 sm:h-20 sm:w-20 sm:bg-white sm:p-4">
              <img src={item.icon} alt="" className="object-cover" />
            </div>
            <div className="flex-1">
              <h2 className="text-md mt-2 font-semibold sm:mt-3 sm:text-lg">
                {item.title}
              </h2>
              <p className="hidden px-5 text-sm sm:block">{item.description}</p>
              <p className="block text-sm leading-4 sm:hidden">
                {item.shortDis}
              </p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
