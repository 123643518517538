import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { FaUser } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/userSlice";
import { useSelector } from "react-redux";
import { selectUserInfo } from "../../redux/userSlice";
import { useNavigate } from "react-router-dom";
import userResources from "api/userResources";
import { toast } from "react-toastify";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function LoginProfileDropdown() {
  const userInfo = useSelector(selectUserInfo);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // console.log(userInfo, "pa");
  return (
    <Menu as="div" className="relative ml-3">
      <div>
        <Menu.Button
          className={`relative mr-2 flex rounded-full text-sm focus:outline-none focus:ring-2 focus:ring-white `}
        >
          <span className="absolute -inset-1.5" />
          <span className="sr-only">Open user menu</span>
          {userInfo.status === true ? (
            <div>
              {userInfo?.data?.full_path ===
                "https://api.maharajaflights.com/storage" ||
              userInfo?.data?.full_path === "" ? (
                <FaUser size={24} color="red" />
              ) : (
                <img
                  className="h-8 w-8 rounded-full"
                  src={userInfo.data?.full_path}
                  alt={userInfo.data?.name}
                />
              )}
            </div>
          ) : (
            ""
          )}
        </Menu.Button>
      </div>
      {userInfo.status ? (
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="ring-black absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-opacity-5 focus:outline-none">
            <Menu.Item>
              {({ active }) => (
                <p
                  onClick={() => {
                    navigate("/profile");
                    window.scrollTo(0, 0);
                  }}
                  className={classNames(
                    active ? "bg-gray-100" : "",
                    "block px-4 py-2 text-sm text-gray-700"
                  )}
                >
                  Your Profile
                </p>
              )}
            </Menu.Item>
            {/* <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  className={classNames(
                    active ? "bg-gray-100" : "",
                    "block px-4 py-2 text-sm text-gray-700"
                  )}
                >
                  Settings
                </a>
              )}
            </Menu.Item> */}
            <Menu.Item>
              {({ active }) => (
                <p
                  className={classNames(
                    active ? "bg-gray-100" : "",
                    "block px-4 py-2 text-sm text-gray-700"
                  )}
                  onClick={() => {
                    (async () => {
                      try {
                        const res = await userResources.logout();
                        if (res.status) {
                          navigate("/");
                          dispatch(logout());
                        }
                        toast.success(res.message);
                      } catch (error) {
                        toast.error("Logout failed:");
                        // Handle Logout error
                      }
                    })();
                  }}
                >
                  Sign out
                </p>
              )}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      ) : (
        ""
      )}
    </Menu>
  );
}
