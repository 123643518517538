import MaharajaLogo from "components/MaharajaLogo/MaharajaLogo";
import Loginsignup from "components/loginsignup/Loginsignup";
import React, { useState } from "react";
import { selectUserInfo } from "../../redux/userSlice";
import { useSelector } from "react-redux";
export default function MobileDrawer() {
  const userInfo = useSelector(selectUserInfo);
  const [hidden, sethidden] = useState("hidden");
  return (
    <div className="drawer relative  ">
      <input id="my-drawer" type="checkbox" className="drawer-toggle" />
      <div className="drawer-content h-12 w-5 rounded-md bg-red-500">
        {/* Page content here */}
        <label className="swap-rotate swap" htmlFor="my-drawer">
          <input type="checkbox" />

          {/* <svg
            className="swap-off fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 512 512"
            onClick={() => sethidden("block")}
          >
            <path d="M64,384H448V341.33H64Zm0-106.67H448V234.67H64ZM64,128v42.67H448V128Z" />
          </svg> */}
          <div
            className="swap-off  fill-current"
            onClick={() => sethidden("block")}
          ></div>

          <svg
            className="swap-on fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 512 512"
            onClick={() => sethidden("block")}
          >
            <polygon points="400 145.49 366.51 112 256 222.51 145.49 112 112 145.49 222.51 256 112 366.51 145.49 400 256 289.49 366.51 400 400 366.51 289.49 256 400 145.49" />
          </svg>
        </label>
      </div>
      <div className="drawer-side z-50">
        <label
          htmlFor="my-drawer"
          aria-label="close sidebar"
          className="drawer-overlay"
          onClick={() => sethidden("hidden")}
        ></label>
        <div className="menu min-h-full w-80 bg-white text-base-content">
          {/* Sidebar content here */}
          <div className="flex  justify-between px-4">
            <div>{<MaharajaLogo text="text-gray-800" />}</div>
            <label
              className="swap-rotate swap relative top-3  left-9"
              htmlFor="my-drawer"
            >
              {/* this hidden checkbox controls the state */}
              <input type="checkbox" />

              {/* hamburger icon */}
              {/* close icon */}
              <svg
                className={`${hidden} swap-off rounded-full bg-red-500 fill-current p-1 text-white hover:bg-white hover:text-red-600`}
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 512 512"
                onClick={() => sethidden("hidden")}
              >
                <polygon points="400 145.49 366.51 112 256 222.51 145.49 112 112 145.49 222.51 256 112 366.51 145.49 400 256 289.49 366.51 400 400 366.51 289.49 256 400 145.49" />
              </svg>
            </label>
          </div>
          {/* <ul className="text-gray-800">
            <li>
              <p>Sidebar Item 1</p>
            </li>
            <li>
              <p>Sidebar Item 2</p>
            </li>
          </ul> */}
          <div className="absolute bottom-24 w-72">
            {userInfo?.status ? (
              <div className="flex items-center gap-5">
                <div className=" flex h-[60px] w-[60px] items-center justify-center rounded-full border-[4px] border-white bg-pink-400 dark:!border-navy-700">
                  <img
                    className="h-full w-full rounded-full"
                    src={userInfo?.data?.full_path}
                    alt=""
                  />
                </div>
                <h3 className="text-xl text-gray-800">
                  Welcome {userInfo?.data?.name}
                </h3>
              </div>
            ) : (
              <Loginsignup bg={"bg-carousal"} padding={"p-3"} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
