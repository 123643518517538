import userResources from "api/userResources";
import React, { useState } from "react";
import { toast } from "react-toastify";

export default function BookingForm({ image, resoures }) {
  const [formData, setFormData] = useState({
    full_name: "",
    email: "",
    phone_number: "",
    message: "",
    departure_date: "",
    return_date: "",
    passengers: 1,
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;

    // For number of passengers, ensure that the value is a positive integer
    const newValue =
      name === "passengers" ? Math.max(1, parseInt(value) || 1) : value;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: newValue,
    }));
  };

  const handleIncrement = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      passengers: prevFormData.passengers + 1,
    }));
  };

  const handleDecrement = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      passengers: Math.max(1, prevFormData.passengers - 1),
    }));
  };

  const validateForm = () => {
    const errors = {};

    if (!formData.full_name.trim()) {
      errors.full_name = "Full name is required";
    }

    if (!formData.email.trim()) {
      errors.email = "Email address is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email address is invalid";
    }

    if (!formData.phone_number.trim()) {
      errors.phone_number = "Phone number is required";
    }

    if (!formData.departure_date.trim()) {
      errors.departure_date = "Departure date is required";
    }

    if (!formData.return_date.trim()) {
      errors.return_date = "Return date is required";
    }

    if (formData.return_date.trim() < formData.departure_date.trim()) {
      errors.return_date = "Return date must be greater than Departure date";
    }

    if (!formData.passengers || formData.passengers < 1) {
      errors.passengers = "Number of passengers must be at least 1";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateForm();

    if (isValid) {
      console.log(formData);
      try {
        const response = await userResources[resoures](formData);

        console.log(response);
        if (response.status) {
          toast.success("Booking Enquiry submitted successfully!");
          setFormData({
            full_name: "",
            email: "",
            phone_number: "",
            message: "",
            departure_date: "",
            return_date: "",
            passengers: 1,
          });
          setErrors({});
        }
      } catch (error) {
        toast.error("Something went wrong !");
      }
      // Clear form data after successful submission
    }
  };

  const formFields = [
    {
      name: "full_name",
      label: "Full Name",
      type: "text",
      placeholder: "Enter your full name",
      required: true,
    },
    {
      name: "email",
      label: "Email Address",
      type: "email",
      placeholder: "Enter your email address",
      required: true,
    },
    {
      name: "phone_number",
      label: "Phone Number",
      type: "tel",
      placeholder: "Enter your phone number",
      required: true,
    },
    {
      name: "departure_date",
      label: "Departure Date",
      type: "date",
      placeholder: "Enter departure date",
      required: true,
    },
    {
      name: "return_date",
      label: "Return Date",
      type: "date",
      placeholder: "Enter return date",
      required: true,
    },
    {
      name: "passengers",
      label: "Number of Passengers",
      type: "text",
      placeholder: "Enter number of passengers",
      required: true,
    },
    {
      name: "message",
      label: "Message",
      type: "textarea",
      placeholder: "Enter your message",
    },
  ];

  return (
    <div className="mx-auto my-10 flex max-w-8xl flex-col sm:my-24 lg:flex-row">
      <div className="mb-8 w-full lg:mb-0 lg:w-1/2 lg:pr-8">
        <img src={image} alt="Charter Plane" className="w-full" />
      </div>
      <div className=" w-full px-5 py-0 text-gray-800 sm:px-0 lg:w-1/2 lg:pl-8">
        <div className="max-w-8xl">
          <h2 className="mb-4 text-2xl font-bold text-red-600">
            Booking Enquiry
          </h2>
          <form className="space-y-4" onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
              {formFields.map((field, index) => (
                <div key={index}>
                  <label
                    htmlFor={field.name}
                    className="block text-sm font-medium text-gray-800"
                  >
                    {field.label}
                    {field.required && <span className="text-red-600">*</span>}
                  </label>
                  {field.name === "passengers" ? (
                    <div className="mt-1 flex items-center">
                      <button
                        type="button"
                        onClick={handleDecrement}
                        className={`p-2 text-gray-700 focus:text-gray-600 focus:outline-none sm:p-3.5 ${
                          formData.passengers <= 1 ? "" : "bg-carousal"
                        }`}
                      >
                        <svg
                          className="h-4 w-4"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M20 12H4"
                          />
                        </svg>
                      </button>
                      <input
                        type="text"
                        id={field.name}
                        name={field.name}
                        value={formData[field.name]}
                        onChange={handleChange}
                        className={`ml-2 mr-2 w-16  rounded-md border bg-white p-2 px-2 py-1 text-center shadow-sm outline-none focus:border-gray-800 focus:ring-red-500 sm:p-3.5 sm:text-sm ${
                          errors[field.name] ? "border-red-500" : ""
                        }`}
                        placeholder={field.placeholder}
                      />
                      <button
                        type="button"
                        onClick={handleIncrement}
                        className="bg-carousal p-2 text-gray-700 focus:text-gray-600 focus:outline-none sm:p-3.5"
                      >
                        <svg
                          className="h-4 w-4"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                          />
                        </svg>
                      </button>
                    </div>
                  ) : field.type === "textarea" ? (
                    <textarea
                      id={field.name}
                      name={field.name}
                      value={formData[field.name]}
                      onChange={handleChange}
                      className={`col-span-2 grid w-full rounded-md border bg-white py-2 px-2 shadow-sm outline-none focus:border-gray-800 focus:ring-red-500 sm:text-sm ${
                        errors[field.name] ? "border-red-500" : ""
                      }`}
                      placeholder={field.placeholder}
                      rows="4"
                    ></textarea>
                  ) : (
                    <input
                      type={field.type}
                      id={field.name}
                      name={field.name}
                      value={formData[field.name]}
                      onChange={handleChange}
                      className={`w-full rounded-md border bg-white px-2 py-3 shadow-sm outline-none focus:border-gray-800 focus:ring-red-500 sm:text-sm ${
                        errors[field.name] ? "border-red-500" : ""
                      }`}
                      placeholder={field.placeholder}
                    />
                  )}
                  {errors[field.name] && (
                    <p className="mt-1 text-sm text-red-500">
                      {errors[field.name]}
                    </p>
                  )}
                </div>
              ))}
              <div className="flex flex-col justify-end">
                <button
                  type="submit"
                  className="bg-carousal relative bottom-0 w-full rounded-md border py-3 px-4 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
