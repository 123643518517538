import React from "react";
import avatar from "../../../../assets/img/auth/admin_picture.png";
import banner from "assets/img/profile/banner.png";
import Card from "components/card";

const Banner = ({ height, width, bottom }) => {
  return (
    <Card extra={"items-center w-full h-full p-[16px] bg-cover"}>
      {/* Background and profile */}
      <div
        className="relative mt-1 flex h-32 w-full justify-center rounded-xl bg-cover"
        style={{ backgroundImage: `url(${banner})` }}
      >
        <div
          className={`absolute ${bottom ? bottom : "-bottom-12"} flex ${
            height ? height : " h-[87px]"
          }
          ${
            width ? width : " w-[87px]"
          }  items-center justify-center rounded-full border-[4px] border-white bg-pink-400 dark:!border-navy-700`}
        >
          <img className="h-full w-full rounded-full" src={avatar} alt="" />
        </div>
      </div>

      {/* Name and position */}
      <div
        className={`${height ? "mt-28" : "mt-16"} flex flex-col items-center`}
      >
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Rajan Khadwal
        </h4>
        <p className="text-base font-normal text-gray-600">CEO</p>
      </div>

      {/* Achievements and Support */}
      <div className="mt-6 mb-3 flex gap-4 md:!gap-14">
        <div className="flex flex-col items-center justify-center">
          <p className="text-2xl font-bold text-navy-700 dark:text-white">17</p>
          <p className="text-sm font-normal text-gray-600">Achievements</p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <p className="text-2xl font-bold text-navy-700 dark:text-white">
            9.7K
          </p>
          <p className="text-sm font-normal text-gray-600">Support</p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <p className="text-2xl font-bold text-navy-700 dark:text-white">
            32 Years
          </p>
          <p className="text-sm font-normal text-gray-600">Experience</p>
        </div>
      </div>
      {/* <div className="mt-3 block text-center text-sm sm:hidden">
        Rajan Khadwal, CEO of Maharaja Flights Limited, brings an impressive
        breadth of experience spanning over 32 years in various leadership
        roles. Leaving Law enforcement career Rajan transitioned into the
        aviation sector, where he excelled as an airline executive. His
        expertise also extends to wellness, serving as a director for an
        Ayurveda company.
      </div> */}
      <div className="mt-3  text-center text-sm ">
        Rajan Khadwal, CEO of Maharaja Flights Limited, brings an impressive
        breadth of experience spanning over 32 years in various leadership
        roles. Leaving Law enforcement career Rajan transitioned into the
        aviation sector, where he excelled as an airline executive. His
        expertise also extends to wellness, serving as a director for an
        Ayurveda company. A national-level athlete, Rajan has also competed in
        hockey, handball, and swimming, infusing a spirit of resilience and
        teamwork into his professional endeavors. Additionally, Rajan is a
        committed meditation instructor and spiritual guide, enriching his
        community with mindfulness and inner peace. His diverse skills and
        unwavering commitment make him a dynamic leader, guiding Maharaja
        Flights Limited with a vision rooted in service and excellence. Rajan’s
        leadership is integral to our mission of providing exceptional travel
        experiences.
      </div>
    </Card>
  );
};

export default Banner;
