import React from "react";
import { car } from "Utils/Images";
import { motion } from "framer-motion";

export default function Hero() {
  return (
    <section className=" py-0 sm:py-20 ">
      <div className="relative z-[1] grid grid-cols-1 items-center sm:gap-0 md:grid-cols-2">
        <div className="relative left-0  mb-10  px-5 text-center sm:left-32 sm:mb-0 sm:px-0 sm:text-left">
          <h1 className="madimi-one-regular lg:leading-12 text-2xl font-semibold leading-8 sm:text-6xl sm:leading-8 md:leading-none">
            Find, book
            <br /> and rent a car <span className="text-red-600">Easily</span>
          </h1>
          <p className="mt-3 text-sm leading-5 sm:text-lg">
            Convenient, Comfortable Car Rentals.
            <br /> From Compact Cars to Luxury SUVs, We Have Your Perfect Ride.
          </p>
          <div className="mt-5">
            <button className="bg-carousal mx-1 rounded-md py-2 px-6">
              Get Deals
            </button>
            <button className="bg-carousal mx-1 rounded-md py-2 px-6">
              Book Now
            </button>
          </div>
        </div>
        <motion.div
          initial={{ opacity: 0, x: 100 }} // Start 100px to the right and invisible
          animate={{ opacity: 1, x: 0 }} // Animate to the original position and fully visible
          transition={{ duration: 0.5 }} // Duration of the animation
          className="text-2xl font-bold "
        >
          <img src={car} alt="best rental car" />
        </motion.div>
      </div>
      {/* <form action="" className="flex"></form> */}
    </section>
  );
}
