import userResources from "api/userResources";
import React, { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-tailwindcss-select";
import { toast } from "react-toastify";

export default function CreateOffer() {
  const location = useLocation();
  const navigate = useNavigate();
  const updateData = location?.state?.rowData;
  console.log(updateData, "updateData");

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    control,
    reset,
  } = useForm({
    defaultValues: {
      terms: [{ value: "" }],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "terms",
  });
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState("");
  const [innerSelectedImage, setInnerSelectedImage] = useState(null);
  const [innerPreviewUrl, setInnerPreviewUrl] = useState("");
  // const [offerfor, setOfferfor] = useState({}); // Change initial state to null
  const [offerList, setOfferList] = useState([]);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const getCouponList = async () => {
    setLoading(true);
    try {
      const response = await userResources.couponList();
      const formattedOptions = response.data?.data?.map((item) => ({
        value: item.id,
        label: `${item.coupon_code} (${item.discount}%)`,
        expiryDate: item.expiry_date,
      }));
      setLoading(false);
      setOptions(formattedOptions);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    setLoading(false);
  };

  const onSubmit = async (data) => {
    setDisabled(true);
    // Create a new FormData object to send data and files
    const formData = new FormData();
    formData.append("booking_validaity", watch("booking_validaity"));
    formData.append("heading", watch("heading"));
    formData.append("long_paragraph", watch("long_paragraph"));
    formData.append("paragraph", watch("paragraph"));
    formData.append("coupon_id", watch("coupon_id"));
    formData.append("deal_id", watch("deal_id"));
    if (updateData) {
      formData.append("id", updateData?.id);
    }
    // Check if terms is an array and has values
    if (Array.isArray(data.terms) && data.terms.length > 0) {
      data.terms.forEach((term, index) => {
        formData.append(`terms[${index}][name]`, term.value);
        if (updateData && term.hasOwnProperty("id")) {
          formData.append(`terms[${index}][id]`, term.id);
        }
      });
    } else {
      console.error("terms is not a valid array or is empty:", data.terms);
    }

    // Append the image files (assuming image and innerImage are FileList objects)
    if (data.image && data.image[0]) {
      formData.append("image", data.image[0]);
    }

    if (data.innerImage && data.innerImage[0]) {
      formData.append("inner_image", data.innerImage[0]);
    }

    try {
      const response = await userResources.createOffer(formData);
      if (response.status) {
        setDisabled(false);
        navigate("/admin/offers-list");
        reset();

        toast("Offer created successfully!");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast("Something went wrong!");
      setDisabled(false);
    }
  };

  useEffect(() => {
    getCouponList();
    getDealList();
  }, []);

  const getDealList = async () => {
    try {
      setLoading(true);
      const response = await userResources.getDealList();
      // Format options for the select component
      const formattedOptions =
        response?.data?.data.map((deal) => ({
          value: deal.id,
          label: deal.name,
        })) || [];
      setOfferList(formattedOptions);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (fields.length === 0) {
      append({ value: "" });
    }
  }, [fields, append]);

  useEffect(() => {
    if (updateData) {
      setValue("booking_validaity", updateData?.coupon?.expiry_date);
      setValue("heading", updateData?.heading);
      setValue("long_paragraph", updateData?.long_paragraph);
      setValue("phone_number", updateData?.phone_number);
      setValue("coupon_id", updateData?.coupon.id);
      setValue("deal_id", updateData?.deal.id);
      setValue("paragraph", updateData?.paragraph);
    }
  }, [updateData, setValue]);

  useEffect(() => {
    if (selectedImage) {
      const objectUrl = URL.createObjectURL(selectedImage);
      setPreviewUrl(objectUrl);

      // Clean up the object URL to avoid memory leaks
      return () => URL.revokeObjectURL(objectUrl);
    } else if (updateData?.image) {
      setPreviewUrl(
        `https://api.maharajaflights.com/storage/${updateData.image}`
      );
    } else {
      setPreviewUrl("");
    }
  }, [selectedImage, updateData]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
    }
  };

  useEffect(() => {
    if (innerSelectedImage) {
      const objectUrl = URL.createObjectURL(innerSelectedImage);
      setInnerPreviewUrl(objectUrl);

      // Clean up the object URL to avoid memory leaks
      return () => URL.revokeObjectURL(objectUrl);
    } else if (updateData?.image) {
      setInnerPreviewUrl(
        `https://api.maharajaflights.com/storage/${updateData.inner_image}`
      );
    } else {
      setInnerPreviewUrl("");
    }
  }, [innerSelectedImage, updateData]);

  const handleInnerImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setInnerSelectedImage(file);
    }
  };

  useEffect(() => {
    // Clear existing fields to prevent duplication
    remove();

    if (
      updateData &&
      Array.isArray(updateData.terms) &&
      updateData.terms.length > 0
    ) {
      // Append existing terms from updateData
      updateData.terms.forEach((item) => {
        append({
          id: item.id || "",
          value: item.name,
        });
      });
    } else {
      // Append a default term if no existing terms are available
      append({
        value: "",
      });
    }
  }, [updateData, append, remove]);

  return (
    <section className="mx-auto mt-5 max-w-6xl">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="mb-4 grid rounded px-2 pt-6 pb-8 shadow-lg sm:px-8 lg:grid-cols-2 lg:gap-x-4"
      >
        <div className="mb-4 lg:col-span-1">
          <label
            className="mb-2.5 block font-medium text-gray-800 dark:text-white"
            htmlFor="heading"
          >
            Heading
          </label>
          <input
            {...register("heading")}
            className="focus:shadow-outline border-stroke text-black dark:border-form-strokedark dark:bg-form-input w-full rounded-lg border bg-transparent py-2 px-3 shadow outline-none focus:border-primary focus-visible:shadow-none dark:text-white dark:focus:border-primary"
            id="heading"
            type="text"
            placeholder="Heading"
          />
        </div>
        {/* <div className="mb-4 lg:col-span-1">
          <label
            className="mb-2.5 block font-medium text-gray-800 dark:text-white"
            htmlFor="coupon"
          >
            Offer For
          </label>
          <input
            {...register("offer")}
            className="focus:shadow-outline border-stroke text-black dark:border-form-strokedark dark:bg-form-input w-full rounded-lg border bg-transparent py-2 px-3 shadow outline-none focus:border-primary focus-visible:shadow-none dark:text-white dark:focus:border-primary"
            id="offer"
            type="text"
            placeholder="flight,hotel,cruise"
          />
        </div> */}
        <div className="mb-4 lg:col-span-1">
          <label
            className="mb-2.5 block font-medium text-gray-800 dark:text-white"
            htmlFor="offer"
          >
            Offer For
          </label>
          <Select
            {...register(`deal_id`, {
              required: "required.",
            })}
            value={offerList.find(
              (source) => source.value == watch("deal_id") // Use watch to get the current value
            )} // Controlled value
            onChange={(selectedOption) => {
              setValue("deal_id", selectedOption?.value || ""); // Update handler using setValue from React Hook Form
              // clearErrors("deal_id");
            }}
            options={offerList}
            loading={loading}
            className="focus:shadow-outline border-stroke text-black dark:border-form-strokedark dark:bg-form-input w-full rounded-lg border bg-transparent py-2 px-3 shadow outline-none focus:border-primary focus-visible:shadow-none dark:text-white dark:focus:border-primary"
          />
        </div>
        <div className="mb-4 lg:col-span-2">
          <label
            className="mb-2.5 block font-medium text-gray-800 dark:text-white"
            htmlFor="paragraph"
          >
            Paragraph
          </label>
          <textarea
            {...register("paragraph")}
            className="focus:shadow-outline hide-scroll-bar border-stroke text-black dark:border-form-strokedark dark:bg-form-input w-full rounded-lg border bg-transparent py-2 px-3 shadow outline-none focus:border-primary focus-visible:shadow-none dark:text-white dark:focus:border-primary"
            id="paragraph"
            placeholder="Paragraph"
            rows={3}
          ></textarea>
        </div>
        <div className="mb-4 lg:col-span-2">
          <label
            className="mb-2.5 block font-medium text-gray-800 dark:text-white"
            htmlFor="long_paragraph"
          >
            Long Paragraph
          </label>
          <textarea
            {...register("long_paragraph")}
            className="focus:shadow-outline hide-scroll-bar border-stroke text-black dark:border-form-strokedark dark:bg-form-input w-full rounded-lg border bg-transparent py-2 px-3 shadow outline-none focus:border-primary focus-visible:shadow-none dark:text-white dark:focus:border-primary"
            id="long_paragraph"
            placeholder="Long Paragraph"
            rows={5}
          ></textarea>
        </div>

        <div className="mb-4 lg:col-span-1">
          <label
            className="mb-2.5 block font-medium text-gray-800 dark:text-white"
            htmlFor="coupon"
          >
            Coupon
          </label>
          <Select
            {...register("coupon_id", {
              required: "Coupon is required.",
            })}
            value={options.find(
              (source) => source.value == watch("coupon_id") // Controlled value
            )}
            onChange={(selectedOption) => {
              setValue("coupon_id", selectedOption?.value || "");
              setValue("booking_validaity", selectedOption?.expiryDate || "");
            }}
            options={options}
            loading={loading}
            className="focus:shadow-outline border-stroke text-black dark:border-form-strokedark dark:bg-form-input w-full rounded-lg border bg-transparent py-2 px-3 shadow outline-none focus:border-primary focus-visible:shadow-none dark:text-white dark:focus:border-primary"
          />
        </div>

        <div className="mb-4 lg:col-span-1">
          <label
            className="mb-2.5 block font-medium text-gray-800 dark:text-white"
            htmlFor="booking_validaity"
          >
            Booking Validity
          </label>
          <input
            {...register("booking_validaity")} // Corrected the name here
            className="focus:shadow-outline border-stroke text-black dark:border-form-strokedark dark:bg-form-input w-full cursor-not-allowed rounded-lg border bg-transparent py-2 px-3 shadow outline-none focus:border-primary focus-visible:shadow-none dark:text-white dark:focus:border-primary"
            id="booking_validaity"
            type="text"
            placeholder="Booking Validity"
            disabled={true}
          />
        </div>

        <div className="mb-4 lg:col-span-1">
          <div className="grid grid-cols-3 justify-between">
            <div className="col-span-2">
              <label
                className="mb-2.5 block font-medium text-gray-800 dark:text-white"
                htmlFor="image"
              >
                Image
              </label>
              <input
                {...register("image")}
                className="file-input file-input-bordered file-input-info w-full max-w-lg bg-white sm:max-w-sm"
                id="image"
                type="file"
                onChange={handleImageChange}
              />
            </div>
            {previewUrl && (
              <div className="col-span-1 flex justify-end">
                <img
                  src={previewUrl}
                  alt="Selected Preview"
                  className="h-20 w-20 object-cover"
                />
              </div>
            )}
          </div>
        </div>
        <div className="mb-4 lg:col-span-1">
          <div className="grid grid-cols-3 justify-between">
            <div className="col-span-2">
              <label
                className="mb-2.5 block font-medium text-gray-800 dark:text-white"
                htmlFor="image"
              >
                Inner Image
              </label>
              <input
                {...register("innerImage")}
                className="file-input file-input-bordered file-input-info w-full max-w-lg bg-white sm:max-w-sm"
                id="innerImage"
                type="file"
                onChange={handleInnerImageChange}
              />
            </div>
            {innerPreviewUrl && (
              <div className="col-span-1 flex justify-end">
                <img
                  src={innerPreviewUrl}
                  alt="Selected Preview"
                  className="h-20 w-20 object-cover"
                />
              </div>
            )}
          </div>
        </div>
        <div className="mb-4 lg:col-span-2">
          <label
            className="mb-2.5 block font-medium text-gray-800 dark:text-white"
            htmlFor="terms"
          >
            Terms & Conditions
          </label>
          {fields.map((field, index) => (
            <div key={field.id} className="mb-2 flex">
              <input
                {...register(`terms.${index}.value`, {
                  required: true,
                })}
                defaultValue={field.value} // set up defaultValue correctly
                className="focus:shadow-outline border-stroke text-black dark:border-form-strokedark dark:bg-form-input w-full rounded-lg border bg-transparent py-2 px-3 shadow outline-none focus:border-primary focus-visible:shadow-none dark:text-white dark:focus:border-primary"
              />
              <button
                type="button"
                onClick={() => remove(index)}
                className="focus:shadow-outline ml-2 rounded bg-red-500 py-2 px-4 font-bold text-white hover:bg-red-700 focus:outline-none"
              >
                Remove
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={() => append({ value: "" })}
            className="focus:shadow-outline rounded bg-blue-500 py-2 px-4 font-bold text-white hover:bg-blue-700 focus:outline-none"
          >
            Add Term
          </button>
        </div>
        <div className="flex items-center justify-end lg:col-span-2">
          <button
            className="focus:shadow-outline rounded bg-blue-500 py-2 px-4 font-bold text-white hover:bg-blue-700 focus:outline-none"
            type="submit"
            disabled={disabled}
          >
            {updateData ? "Update" : " Submit"}
          </button>
        </div>
      </form>
    </section>
  );
}
