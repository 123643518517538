import React, { useState } from "react";
import AmadeusFlightStatusResources from "api/AmadeusFlightStatusResources/AmadeusFlightStatusResources";
import { earth } from "Utils/Images";
import { toast } from "react-toastify";
import { IoIosAirplane } from "react-icons/io";
export default function FlightTracker() {
  const [formDisplay, setFromDisplay] = useState("block");
  const [carrierCode, setCarrierCode] = useState("");
  const [flightNumber, setFlightNumber] = useState("");
  const [scheduledDepartureDate, setScheduledDepartureDate] = useState("");
  const [flightData, setFlightData] = useState([]);
  console.log(flightData);

  const flightStatusget = async () => {
    if (!carrierCode || !flightNumber || !scheduledDepartureDate) {
      toast.error("Please fill in all fields.");
      return;
    }

    const data = { carrierCode, flightNumber, scheduledDepartureDate };

    try {
      const response = await AmadeusFlightStatusResources.flightStatus(data);
      setFromDisplay("hidden");
      setFlightData(response.data.data);
      if (response.status) {
        setCarrierCode("");
        setFlightNumber("");
        setFlightNumber("");
        setFromDisplay("hidden");
        toast.success("Flight status retrieved successfully!");
      }
    } catch (error) {
      toast.error("Failed to fetch flight status. Please try again later.");
    }
  };

  return (
    <section
      className={`relative flex h-screen w-full items-center justify-center  px-5 text-gray-800 `}
      style={{
        backgroundImage: `url(${earth})`,
      }}
    >
      <div
        className={`${formDisplay} w-full max-w-md  rounded-lg bg-gray-100  p-8 shadow-md`}
      >
        <h2 className="mb-4 text-2xl font-semibold">Flight Tracker</h2>
        <div className="mb-4">
          <label htmlFor="carrierCode" className="mb-1 block">
            Carrier Code:
          </label>
          <input
            id="carrierCode"
            type="text"
            value={carrierCode}
            onChange={(e) => setCarrierCode(e.target.value)}
            placeholder="Enter carrier code"
            className="w-full rounded-md border-gray-300 bg-white px-3 py-2 shadow-md focus:border-blue-500 focus:outline-none"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="flightNumber" className="mb-1 block">
            Flight Number:
          </label>
          <input
            id="flightNumber"
            type="text"
            value={flightNumber}
            onChange={(e) => setFlightNumber(e.target.value)}
            placeholder="Enter flight number"
            className="w-full rounded-md border-gray-300 bg-white px-3 py-2 shadow-md focus:border-blue-500 focus:outline-none"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="scheduledDepartureDate" className="mb-1 block">
            Scheduled Departure Date:
          </label>
          <input
            id="scheduledDepartureDate"
            type="date"
            value={scheduledDepartureDate}
            onChange={(e) => setScheduledDepartureDate(e.target.value)}
            placeholder="Select scheduled departure date"
            className="w-full rounded-md border-gray-300 bg-white px-3 py-2 shadow-md focus:border-blue-500 focus:outline-none"
          />
        </div>
        <button
          className="bg-carousal w-full rounded-md  py-2 text-white transition duration-300 "
          onClick={flightStatusget}
        >
          Track Flight
        </button>
      </div>

      {/* <div className="absolute top-5 w-full text-white">
        <video
          src={loadingVideoEarth}
          autoPlay
          muted
          controls={false}
          playsInline
        />
      </div> */}
      <div
        className={`${
          formDisplay === "block" ? "hidden" : "block"
        } shadow-lg" w-full max-w-2xl overflow-hidden  rounded-lg bg-gray-100 `}
      >
        <section>
          {flightData.map((item, index) => (
            <div key={index}>
              <header className="bg-carousa flex items-center justify-between rounded-lg rounded-b-none bg-gray-800 p-5  text-white">
                <div>
                  <p className="text-3xl font-semibold">
                    {item?.flightDesignator?.carrierCode}{" "}
                    {item?.flightDesignator?.flightNumber}
                  </p>
                  <p>
                    Scheduled Departure Date: {item?.scheduledDepartureDate}
                  </p>
                </div>
                <div>
                  <p className="text-3xl">{item?.type}</p>
                </div>
              </header>
              <div className="flex items-start justify-between  p-5">
                {item?.flightPoints.map((timeing, indexing) => (
                  <div key={indexing}>
                    <div className="flex items-center gap-4  ">
                      <h3 className="text-3xl font-semibold">
                        {timeing?.iataCode}
                      </h3>
                      {indexing !== item?.flightPoints.length - 1 && (
                        <div className="relative ">
                          <p className="airplane absolute -top-0">
                            <IoIosAirplane size={45} color="red" />
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="grid-container">
                      <div className="departure ">
                        {timeing?.departure?.timings?.map((e, indx) => (
                          <div key={indx}>
                            <h3>Departure</h3>
                            <p>Qualifier: {e?.qualifier}</p>
                            {/* Splitting date and time */}
                            {e?.value && (
                              <div>
                                <p>
                                  Date: {new Date(e.value).toLocaleDateString()}
                                </p>
                                <p>
                                  Time: {new Date(e.value).toLocaleTimeString()}
                                </p>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                      <div className="arrival">
                        {timeing?.arrival?.timings?.map((e, indx) => (
                          <div key={indx}>
                            <h3>Arrival</h3> {/* Changed to Arrival */}
                            <p>Qualifier: {e?.qualifier}</p>
                            {/* Splitting date and time */}
                            {e?.value && (
                              <div>
                                <p>
                                  Date: {new Date(e.value).toLocaleDateString()}
                                </p>
                                <p>
                                  Time: {new Date(e.value).toLocaleTimeString()}
                                </p>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </section>
      </div>
    </section>
  );
}
