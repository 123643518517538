import React from "react";
import PrivacyContentComponent from "components/PrivacyContentComponent/PrivacyContentComponent";
import UserNavbar from "components/UserNavbar/UserNavbar";
import ScrollToTopOnRouteChange from "components/ScrollToTopOnRouteChange/ScrollToTopOnRouteChange";
import ContactNumber from "components/ContactNumber";

export default function TermsAndConditions() {
  const termsAndConditions = [
    {
      // title: "Terms and Conditions",
      content:
        "Please read these Terms and Conditions of use carefully before using or obtaining any content, products, or services through our website. Access to and use of this website is subject to acceptance of the terms and conditions below ('terms', which include our privacy policy to be bound by these terms). If you do not accept all of these terms, then please do not use this website.",
    },
    {
      title: "Insurance",
      content:
        "In case the purchase of the air ticket(s) by you is refused or denied for any reason, and in case you have already purchased an insurance policy prior to such refusal or denial, any amount paid by you for purchasing such policy or any amount to be recovered by you on such policy will not be refunded or paid to you by Maharaja Flights Limited.",
    },
    {
      title: "Check latest travel advisories",
      content:
        "It is your responsibility to check latest travel advisories for both departure and destination countries before boarding your flights.",
    },
    {
      title: "Reconfirm your flights at least 72 hrs before departure",
      content:
        "It is your responsibility to check your flight timings at least 72 hrs before your flight departure. Please contact our office during our regular business hours for any schedule change in your flights. You may contact Airline directly in case of urgent schedule changes.",
    },
    {
      title: "Age restrictions",
      content:
        "Minors (as defined under the laws of their jurisdiction or residence) are not eligible to register for, use or Purchase the Products or Services available on the Site.",
    },
    {
      title: "Flight rules and restrictions",
      content:
        "Some tickets are non-refundable and no changes are allowed. Tickets are nontransferable and name changes are not allowed. Please read important information regarding airline liability limitations. If you change your reservation, airlines may charge a fee and fare difference to use your ticket as credit for a new reservation. This fee varies by airline, market, and specific fare rules and may be CAD/USD $150 or more for domestic tickets and CAD/USD $300 or more for international tickets. Fare difference is subject to the seat availability at the time of checking new dates and seat availability may change by the airline at any time.",
    },
    {
      title: "Discount on Airline tickets",
      content:
        "Maharaja Flights Limited honors the discounted price quoted to our customers. In some cases Airlines full fare is charged, we will refund the discounted amount either on your credit card within 72 hrs or will send you the email transfer within 24 hours.",
    },
    {
      title: "Price Drop Guarantee Terms & Conditions",
      content: [
        "This Promotion is valid for Tickets purchased until August 31, 2024",
        "Price Drop Guarantee applies on same flights which includes Airlines, Flight numbers, same routing, dates, Cabin, Origin and Destination.",
        "Credit must be used by same passenger only.",
        "Credit will be given for difference between original and new lower price and maximum credit is $100.",
        "Example- Original price was $1020 and new lower price is $900 , the difference is $120 but the client will get maximum $100 not $120.",
        "This is one time promotion only. Credit can only be taken one time.",
        "Price comparison must be with CANADIAN TRAVEL WEBSITES and in the same currency.",
        "Future Travel credit must be used within one year from the date of original tickets purchased.",
        "In case of original flights changed or cancelled, then this future travel credit will be nullified means will not have any value.",
        "Credit can be used for any Airlines new flight booking.",
        "Promotion is valid for seat occupying passengers, not for Infant.",
        "Credit will be refunded when you purchase new Air tickets.",
      ],
    },
    {
      title: "How the Price Drop Guarantee Claim Procedure Works",
      content: [
        "Submitting a Claim:",
        "Step 1: Using our booking engine or any Canadian based TICO registered web site, begin a search for the exact city pair and dates.",
        "Step 2: Find your flights (same airline, same routing same airports, times & flight numbers, Cabin). Please pay special attention to the currency.",
        "Step 3: Send us the screenshot of your purchased flights at contact@maharajaflights.com",
        "Step 4: Add in subject Price Drop Claim screenshot",
      ],
    },
    {
      title: "ACCURACY OF INFORMATION",
      content:
        "Maharaja Flights Limited makes every effort to ensure that the prices on the website are accurate, however errors do occur and we reserve the right to refuse to accept and/or the right to cancel and refund any booking purchased at any price published in error.",
    },
    {
      title: "Travel Documents",
      content: [
        "For travel outside Canada, most countries require a passport valid for at least six months beyond your return date. In addition, depending on your passport's country of issue and the country you are visiting, you may also need a visa and/or medical inoculations. Entry to another country may be refused if all required travel documents are not complete. It is your responsibility to ensure that you have the appropriate travel documentation required by the countries you are traveling to and connecting through, and for re-entry back into Canada. When traveling with children, additional documentation may also be necessary. Note that living standards in other countries and the standards and conditions with respect to the provision of utilities, services and accommodation may differ from those found in Canada.",
      ],
    },
    {
      title: "Important Visa information",
      content: [
        "If you are traveling via any USA airport, you must be either Canadian Citizen or must hold a valid US visa.",
        "If you are transiting via Europe, you cannot have 2 Transit points in Europe unless you are a Canadian citizen or have valid Schengen Visa.",
        "If you are transiting via Saudia and transit hours are more than 8 hours, you need Transit visa.",
        "Please check Transit visa or destination country's visa requirements by checking IATA link check visa requirements.",
        "Your name also should be exactly as per passport. Names changes are not allowed by Airline. Airline may deny you boarding if your name on the passport does not match with name on your airline ticket.",
      ],
    },
    {
      title: "Changes",
      content:
        "Any aspect of the Site may be changed, deleted, updated, discontinued, suspended, or modified at any time, and without prior notice to you. However, we make no commitment to update the information contained on this Site. You agree that the Travel Agency shall not be liable to you for any delay or other damages that might result from such modification, suspension, or discontinuance. We may also, at any time, change or impose fees for certain services, or establish or change general practices and limits concerning certain services. We may also modify these Terms & Conditions.",
    },
    {
      title: "Credit Card Bookings",
      content:
        "The submission of your Credit Card number for deposits and/or final payment indicates your acceptance of our booking terms and conditions, whether or not you have actually signed the appropriate draft. The credit card used must belong to one of the passengers in the reservation. Third party Credit Cards will absolutely not be accepted. Billing address must be provided.",
    },
    {
      title: "Content",
      content:
        "The Content is intended for information purposes only. Although we exercise reasonable efforts to ensure their quality and accuracy, there might be errors, or the information provided may not be complete, current, or applicable to your particular situation. Further, information provided regarding the service, products, etc. have been provided to us by the vendors. We assume no liability or responsibility for any errors or omissions. You are responsible for evaluating the accuracy, completeness, and usefulness of any opinion, advice, or other content available through the Site or obtained from a linked site. You should not take any action based on information on this Site until you have received a confirmation of your transaction. We send confirmations within 24 hours of your Purchase. If you have not received a confirmation of your purchase via e-mail within that period please contact our customer service by email at",
    },
  ];

  return (
    <section className="text-gray-800">
      <UserNavbar />
      <ScrollToTopOnRouteChange />

      <div className="m-auto max-w-8xl">
        <div className="container mx-auto p-4">
          <h1 className="mb-4 text-2xl font-bold">Terms and Conditions</h1>
          {termsAndConditions.map((section, index) => (
            <div key={index} className="mb-8">
              <h2 className="mb-2 text-xl font-semibold">{section.title}</h2>
              {Array.isArray(section.content) ? (
                <ul className="list-inside list-disc">
                  {section.content.map((item, idx) => (
                    <li key={idx} className="mb-2">
                      {item}
                    </li>
                  ))}
                </ul>
              ) : (
                <p>{section.content}</p>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
