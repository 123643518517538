import PrivacyContentComponent from "components/PrivacyContentComponent/PrivacyContentComponent";
import ScrollToTopOnRouteChange from "components/ScrollToTopOnRouteChange/ScrollToTopOnRouteChange";
import UserNavbar from "components/UserNavbar/UserNavbar";
import React from "react";
import ContactNumber from "components/ContactNumber";

export default function PrivacyPolicy() {
  const policysData = [
    {
      id: "1",
      heading:
        "Maharaja Flights Limited Collects the following personal information when you book online on website or through call Centre with our travel Consultant.",
      list: [
        "Complete Name as per travel document.",
        "Telephone contact.",
        "Email ID.",
        "Date of birth.",
        "Some Airlines requires passport details too.",
      ],
    },
    {
      id: "2",
      heading: "Payment",
      list: [
        "Complete credit card number.",
        "Expiry Date.",
        "Billing details as per credit card bank record.",
        "Written credit card authorization in some cases.",
      ],
    },
    {
      id: "3",
      heading: "Why personal information",
      list: [
        "Name: - Airlines requires name for reserving seat in the airplane and to associate auxiliary services requested by client.",
        "Telephone and email are needed to communicate with client to deliver the information before purchase and after to deliver the purchased product, invoice or schedule change updates and also to use in case of emergency.",
        "Date of birth / Passport details are required by Airport security for security protocols.",
        "Credit card details are required to charge the purchased value amount and to verify details with Bank to protect fraudulent use.",
      ],
    },
    {
      id: "4",
      heading: "Is the shared information safe?",
      paragraph:
        "Security is our very high priority without any compromise.\n\n1. We do not sell or trade the information to any company.\n2. We do not use information for marketing purposes.\n3. We share booking associated information with suppliers only to process the purchased transaction. However, they are trusted and registered business operators.",
    },
    {
      id: "5",
      heading: "Security",
      paragraph:
        "We take precautions to protect your information. When you submit sensitive information via the website, your information is protected both online and offline. Wherever we collect sensitive information (such as credit card data), that information is encrypted and transmitted to us in a secure way. You can verify this by looking for a closed lock icon at the bottom of your web browser, or looking for 'https' at the beginning of the address of the web page. While we use encryption to protect sensitive information transmitted online, we also protect your information offline. Only employees who need the information to perform a specific job (for example, billing or customer service, ticketing) are granted access to personally identifiable information. The computers/servers in which we store personally identifiable information are kept in a secure environment.",
    },
    {
      id: "6",
      heading: "Third Party links",
      paragraph:
        "We include 3rd party link on our website to provide travel-related information like visa requirement, destination details, Hotel pictures etc. Those link owners have their own Privacy policy.",
    },
    {
      id: "7",
      heading: "Terms and Conditions",
      paragraph:
        "Social media platforms showing any contents for Maharaja Flights Limited are not in our control because these platforms are not run by Maharaja Flights Limited. You must read the 'Terms & Condition' and give your consent by clicking on 'I agree' box for using Nanak Flights website before completing your purchase transaction.",
    },
    {
      id: "8",
      heading: "Privacy Policy updates.",
      paragraph:
        "When any change will be made to our Privacy Policy, we will post on the website with the modification date. Present policy is modified on 13 Feb 2024",
    },
    {
      id: "9",
      heading: "Our Contact",
      paragraph:
        "For any of your concerns or questions about Privacy Policy, please contact us at the following Email: ",
      Contect: <ContactNumber headingBg={"bg-primary"} text="text-white" />,
      email: "contact@maharajaflights.com",
    },
  ];

  return (
    <section className="text-gray-800">
      <UserNavbar />
      <ScrollToTopOnRouteChange />

      <div className="m-auto max-w-8xl">
        <div className="m-5">
          {policysData.map((item) => (
            <PrivacyContentComponent
              key={item.id}
              heading={item?.heading}
              list={item?.list}
              paragraph={item?.paragraph}
              link={item?.link}
              email={item?.email}
              ContactNumber={item?.Contect}
            />
          ))}
        </div>
      </div>
    </section>
  );
}
