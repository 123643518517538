import { Fragment, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { GiCommercialAirplane, GiCruiser } from "react-icons/gi";
import { GiIndiaGate } from "react-icons/gi";
import { FaBlog, FaHotel, FaSuitcase } from "react-icons/fa";
import { GiPalmTree } from "react-icons/gi";
import { MdDirectionsRailwayFilled } from "react-icons/md";
import { FaCar } from "react-icons/fa";
import { IoMdBus } from "react-icons/io";
import { FaSimCard } from "react-icons/fa";
import { GiFishingBoat } from "react-icons/gi";
import { MdOutlineContactPhone } from "react-icons/md";
import { BsInfoCircleFill } from "react-icons/bs";
import { BsCreditCard2FrontFill } from "react-icons/bs";
import aboutUsIcon from "../../assets/img/auth/group.png";
import { GiHelicopter } from "react-icons/gi";
import termsandconditions from "../../assets/img/auth/agreement.png";
import { MdOutlinePets } from "react-icons/md";
import { MdLuggage } from "react-icons/md";
import { Link, useLocation, useNavigate } from "react-router-dom";
import admin_image from "../../assets/img/auth/admin_picture.png";
import MaharajaLogo from "components/MaharajaLogo/MaharajaLogo";
import LoginRegisterButton from "components/LoginRegisterButton/LoginRegisterButton";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/userSlice";
import { useSelector } from "react-redux";
import { selectUserInfo } from "../../redux/userSlice";
import LoginProfileDropdown from "components/LoginProfileDropdown/LoginProfileDropdown";

export default function UserNavbar() {
  const userInfo = useSelector(selectUserInfo);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [height, setHeight] = useState({});

  const toggleHeight = (index) => {
    setHeight((prevState) => ({
      ...Object.fromEntries(
        Object.keys(prevState).map((key) => [
          key,
          key === index ? "h-full" : "h-14",
        ])
      ),
      [index]: prevState[index] === "h-full" ? "h-14" : "h-full",
    }));
  };

  const navigation = [
    {
      name: "Travel in india",
      href: "#",
      icon: (
        <GiIndiaGate className="text-red-500 sm:text-white" size={"20px"} />
      ),
      dic: [
        {
          name: "Flight",
          href: "/flights",
          icon: <GiCommercialAirplane size={"15px"} />,
        },
        {
          name: "Train",
          href: "/train",
          icon: <MdDirectionsRailwayFilled size={"15px"} />,
        },
        {
          name: "Car",
          href: "/car-rental",
          icon: <FaCar color="text-white sm:text-red-500" size={"15px"} />,
        },
        // { name: "Transfer", href: "/car" },
        {
          name: "Bus",
          href: "/bus",
          icon: <IoMdBus color="text-white sm:text-red-500" size={"15px"} />,
        },
        {
          name: "Prepaid Sim",
          href: "/prepaid-sim",
          icon: <FaSimCard color="text-white sm:text-red-500" size={"15px"} />,
        },
      ],
      color:
        location.pathname === "/" ||
        location.pathname === "/flights" ||
        location.pathname === "/flights/oneway" ||
        location.pathname === "/flights/round-trip" ||
        location.pathname === "/flights/multi-city"
          ? " text-red-500"
          : "text-gray-300  hover:text-red-500",
    },
    {
      name: "Hotel",
      href: "/hotels",
      icon: <FaHotel className="text-red-500 sm:text-white" size={"20px"} />,
      color:
        location.pathname === "/hotels"
          ? " text-red-500"
          : "text-gray-300  hover:text-red-500",
    },
    {
      name: "Holidays",
      href: "/holidays",
      icon: <GiPalmTree className="text-red-500 sm:text-white" size={"20px"} />,

      color:
        location.pathname === "/holidays"
          ? " text-red-500"
          : "text-gray-300  hover:text-red-500",
    },
    {
      name: "Cruise",
      href: "/cruise",
      icon: <GiCruiser className="text-red-500 sm:text-white" size={"20px"} />,

      color:
        location.pathname === "/cruise"
          ? " text-red-500"
          : "text-gray-300  hover:text-red-500",
    },
    {
      name: "Charters",
      // href: "#",
      icon: (
        <GiHelicopter className="text-red-500 sm:text-white" size={"20px"} />
      ),
      dic: [
        {
          name: "Plane",
          href: "/plane",
          icon: (
            <GiCommercialAirplane
              color="text-white sm:text-red-500"
              size={"20px"}
            />
          ),
        },
        {
          name: "Yacht",
          href: "/yacht",
          icon: (
            <GiFishingBoat color="text-white sm:text-red-500" size={"20px"} />
          ),
        },
      ],
      color:
        location.pathname === "/charters-yacht" ||
        location.pathname === "/charters-plane"
          ? " text-red-500"
          : "text-gray-300  hover:text-red-500",
    },
    {
      name: "Useful info",
      // href: "#",
      icon: (
        <BsInfoCircleFill
          className="mb-1 text-red-500 sm:text-white"
          size={"15px"}
        />
      ),
      dic: [
        {
          name: "Baggage information",
          href: "/baggage-information",
          icon: <MdLuggage color="text-white sm:text-red-500" size={"20px"} />,
        },
        {
          name: "Traveling with pets",
          href: "/traveling-with-pets",
          icon: (
            <MdOutlinePets color="text-white sm:text-red-500" size={"20px"} />
          ),
        },
        {
          name: "Credit Card Authorization",
          href: "/credit-card-authorization",
          icon: (
            <BsCreditCard2FrontFill
              color="text-white sm:text-red-500"
              size={"20px"}
            />
          ),
        },
        {
          name: "Terms and conditions",
          href: "/privacy-policy",
          icon: <img src={termsandconditions} width={18} alt=""></img>,
        },
      ],
      color:
        location.pathname === "/baggage-information" ||
        location.pathname === "/traveling-with-pets" ||
        location.pathname === "/credit-card-authorization" ||
        location.pathname === "/privacy-policy"
          ? " text-red-500"
          : "text-gray-300  hover:text-red-500",
    },

    {
      name: "more",
      // href: "#",
      icon: (
        <span className="loading loading-dots loading-xs text-red-500 sm:text-white"></span>
      ),
      dic: [
        {
          name: "Contact Us",
          href: "/contact-us",
          icon: (
            <MdOutlineContactPhone
              color="text-white sm:text-red-500"
              size={"20px"}
            />
          ),
        },
        {
          name: "About Us",
          href: "/about-us",
          icon: <img src={aboutUsIcon} width={18} alt="About Us"></img>,
        },
        {
          name: "Careers",
          href: "/careers",
          icon: <FaSuitcase size={"20px"} />,
        },
        {
          name: "Blog",
          href: "/blogs",
          icon: <FaBlog size={"20px"} />,
        },
      ],
      color:
        location.pathname === "/more"
          ? " text-red-500"
          : "text-gray-300  hover:text-red-500",
    },
    {
      register: (
        // <div className="dropdown-top dropdown mr-2  w-full">
        //   <div
        //     tabIndex={0}
        //     role="button"
        //     className="btn m-1 mr-1 w-full border-none bg-red-500 py-0 uppercase text-white hover:bg-white hover:text-red-500"
        //   >
        //     login/register
        //   </div>
        //   <ul
        //     tabIndex={0}
        //     className="menu dropdown-content z-[1]  w-full rounded-box bg-white p-2 shadow"
        //   >
        //     <div className="flex items-center rounded-2xl p-3 hover:bg-gray-200">
        //       <img src={user} alt="" />

        //       <a
        //         className="ml-3 w-full text-gray-900"
        //         onClick={() => setOpenLogin(true)}
        //       >
        //         Login
        //       </a>
        //     </div>
        //     <div className="flex items-center rounded-2xl p-3 hover:bg-gray-200">
        //       <img src={signup} alt="" className="w-6" />

        //       <a
        //         className="ml-3 w-full text-gray-900"
        //         onClick={() => setOpenSignUp(true)}
        //       >
        //         Sign Up
        //       </a>
        //     </div>

        //     {/* <div className="collapse hover:bg-gray-200">
        //       <input type="checkbox" className="peer" />
        //       <div className="collapse-title flex items-center text-primary-content ">
        //         <img src={language} alt="language" className="w-6" />

        //         <a className="ml-3 w-full text-gray-900">Language's</a>
        //       </div>
        //       <div className="peer-checked:bg-gray-150 collapse-content text-primary-content peer-checked:text-gray-900">
        //         <div className="mb-4 ml-1 flex items-center">
        //           <img src={canadaflag} alt="canada flag" />
        //           <p className="ml-4">English (Canadian)</p>
        //         </div>
        //         <div className="ml-1 flex items-center">
        //           <img src={indiaflag} alt="india flag" />
        //           <p className="ml-4">Hindi (India)</p>
        //         </div>
        //       </div>
        //     </div> */}
        //   </ul>
        // </div>
        <div>
          {userInfo.status ? (
            ""
          ) : (
            <div className="mr-2 sm:mr-0">
              <LoginRegisterButton dropDown={"dropdown-top"} />
            </div>
          )}
        </div>
      ),
    },
  ];

  return (
    <Disclosure
      as="nav"
      // className={`sticky top-0 z-10  bg-gray-800 ${isVisible ? "hidden" : ""}`}
      className={`z-10 bg-[#000317]`}
    >
      {({ open }) => (
        <>
          <div className="mx-auto max-w-8xl sm:pt-2 ">
            <div className="relative flex h-16 items-center justify-center sm:justify-between md:justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden md:flex lg:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-white  hover:text-white focus:outline-none ">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              {/* Logo */}
              <MaharajaLogo text="text-white" />
              <div className="first-letter: hidden sm:ml-24 sm:hidden md:hidden lg:block">
                <div className="flex space-x-4 ">
                  {navigation.map((item, index) => (
                    <div
                      key={index}
                      className="dropdown-hover dropdown-bottom dropdown flex flex-col items-center justify-center bg-transparent text-gray-800"
                    >
                      {item.icon}
                      <div
                        key={index}
                        tabIndex="0"
                        role="button"
                        className="bg-transparent"
                      >
                        <Link
                          key={item.name}
                          to={item.href}
                          className={`
                            ${item.color} rounded-md px-3 py-2 text-sm font-medium
                          `}
                        >
                          {item.name}
                        </Link>
                      </div>
                      {/* Check if 'dic' exists before rendering the dropdown */}
                      {item.dic && (
                        <ul
                          tabIndex="0"
                          className="menu dropdown-content z-[99] w-60 rounded-box bg-white p-2 shadow focus:bg-transparent "
                        >
                          {item.dic.map((innerItem, innerIndex) => (
                            <div
                              key={innerIndex}
                              className="flex w-full cursor-pointer items-center hover:text-red-500"
                            >
                              {innerItem.icon}

                              <p
                                onClick={() => {
                                  navigate(innerItem.href);
                                  window.scrollTo(0, 0);
                                }}
                                className="w-full p-2"
                              >
                                {innerItem.name}
                              </p>
                            </div>
                          ))}
                        </ul>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                {/* <button
                  type="button"
                  className="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                >
                  <span className="absolute -inset-1.5" />
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button> */}

                <div className="flex flex-col items-center justify-center">
                  {/* Profile dropdown */}
                  <LoginProfileDropdown />
                  {/* login/register */}
                  <div className="hidden sm:block ">
                    {userInfo?.status ? (
                      <div>
                        <h3 className="text-white">{userInfo?.data?.name}</h3>
                      </div>
                    ) : (
                      <LoginRegisterButton dropDown={"dropdown-bottom"} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* login modal */}
          {/* <Login open={openLogin} setOpen={setOpenLogin} />
          <SignUp open={openSignUp} setOpen={setOpenSignUp} /> */}
          <Disclosure.Panel className="sm:hidden md:block">
            <div className="space-y-1 px-2 pb-3 pt-2 text-white">
              {navigation.map((item, index) => (
                <div key={index}>
                  <div className="flex items-start">
                    <div className="relative top-5">{item.icon}</div>

                    {item.name && item.dic ? (
                      <div
                        tabIndex={0}
                        className={`collapse-plus collapse ${
                          height[index] || "h-14"
                        }`}
                        onClick={() => toggleHeight(index)}
                      >
                        <input type="radio" name="my-accordion-3" />
                        <div className="collapse-title text-xl font-medium">
                          {item.href ? (
                            <div
                              onClick={() => {
                                navigate(item.href);
                                window.scrollTo(0, 0);
                              }}
                              className="hover:text-red-500"
                            >
                              {item.name}
                            </div>
                          ) : (
                            <p className="hover:text-red-500">{item.name}</p>
                          )}
                        </div>
                        <div className="">
                          {item.dic.map((e, innerIndex) => (
                            <Disclosure.Button
                              tabIndex={0}
                              key={innerIndex}
                              className="z-50  w-full items-center hover:text-red-500"
                            >
                              <div
                                className="flex w-full items-center"
                                onClick={() => {
                                  navigate(e.href);
                                  window.scrollTo(0, 0);
                                }}
                              >
                                {e.icon}
                                <p className="ml-3">{e.name}</p>
                              </div>
                            </Disclosure.Button>
                          ))}
                        </div>
                      </div>
                    ) : (
                      // If 'name' exists but 'dic' doesn't, render only the 'name'
                      <div className="collapse-title block w-full text-xl font-medium">
                        <Disclosure.Button className="block hover:text-red-500">
                          <div
                            onClick={() => {
                              navigate(item.href);
                              window.scrollTo(0, 0);
                            }}
                          >
                            {item.name}
                          </div>
                        </Disclosure.Button>
                      </div>
                    )}
                  </div>
                  <div>{item.register}</div>
                </div>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
