import { twitter } from "Utils/Images";
import { instagram } from "Utils/Images";
import { facebook } from "Utils/Images";
import { whatsapp } from "Utils/Images";
import userResources from "api/userResources";
import ContactNumber from "components/ContactNumber";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function UserFooter() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const onSubmit = async (data) => {
    try {
      const res = await userResources.subscribe(data);
      if (res.status === 200) {
        toast.success("Thanks! For Connecting Us.");
        reset();
      }
    } catch (error) {
      toast.error("Something went wrong!");
      console.error("API error:", error);
    }
  };

  return (
    <section className="bg-[#000317]">
      <footer className=" m-auto max-w-8xl py-10  px-5 pb-24 text-white lg:px-0">
        <div className="grid grid-cols-1 justify-between gap-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 lg:gap-0">
          <div>
            <h6 className="footer-title text-base tracking-wider">Services</h6>
            <p
              onClick={() => {
                navigate("/flights/round-trip");
                window.scrollTo(0, 0);
              }}
              className=" link-hover link whitespace-nowrap text-base"
            >
              Flights
            </p>

            <p
              onClick={() => {
                navigate("/hotels");
                window.scrollTo(0, 0);
              }}
              className="link-hover link whitespace-nowrap text-base"
            >
              Hotels
            </p>
            <p
              onClick={() => {
                navigate("/holidays");
                window.scrollTo(0, 0);
              }}
              className="link-hover link whitespace-nowrap text-base"
            >
              Holidays
            </p>
            <p
              onClick={() => {
                navigate("/cruise");
                window.scrollTo(0, 0);
              }}
              className="link-hover link whitespace-nowrap text-base"
            >
              Cruise
            </p>
            <p
              onClick={() => {
                navigate("/plane");
                window.scrollTo(0, 0);
              }}
              className="link-hover link whitespace-nowrap text-base"
            >
              Charter a Private Plane
            </p>
            <p
              onClick={() => {
                navigate("/yacht");
                window.scrollTo(0, 0);
              }}
              className="link-hover link whitespace-nowrap text-base"
            >
              Charter Your Private Yacht
            </p>
          </div>
          <div>
            <h6 className="footer-title text-base tracking-wider">Company</h6>
            <p
              onClick={() => {
                navigate("/careers");
                window.scrollTo(0, 0);
              }}
              className="link-hover link whitespace-nowrap text-base"
            >
              Careers
            </p>
            <p
              onClick={() => {
                navigate("/faq");
                window.scrollTo(0, 0);
              }}
              className="link-hover link whitespace-nowrap text-base"
            >
              FAQ's
            </p>
            <p
              onClick={() => {
                navigate("/blogs");
                window.scrollTo(0, 0);
              }}
              className="link-hover link whitespace-nowrap text-base"
            >
              Blog
            </p>

            <p
              onClick={() => {
                navigate("/about-us");
                window.scrollTo(0, 0);
              }}
              className="link-hover link whitespace-nowrap text-base"
            >
              About us
            </p>
            <p
              onClick={() => {
                navigate("/contact-us");
                window.scrollTo(0, 0);
              }}
              className="link-hover link whitespace-nowrap text-base"
            >
              Contact Us
            </p>
            <p
              onClick={() => {
                navigate("/maharaja-technologies");
                window.scrollTo(0, 0);
              }}
              className="link-hover link whitespace-nowrap text-base"
            >
              Maharaja Technologies
            </p>
          </div>
          <div>
            <h6 className="footer-title text-base ">Travel resources</h6>
            <p
              onClick={() => {
                navigate("/baggage-information");
                window.scrollTo(0, 0);
              }}
              className="link-hover link whitespace-nowrap text-base"
            >
              Baggage information
            </p>
            <p
              onClick={() => {
                navigate("/Visa");
                window.scrollTo(0, 0);
              }}
              className="link-hover link whitespace-nowrap text-base"
            >
              Visa information
            </p>
            <p
              onClick={() => {
                navigate("/traveling-with-pets");
                window.scrollTo(0, 0);
              }}
              className="link-hover link whitespace-nowrap text-base"
            >
              Traveling with pets
            </p>
            <p
              onClick={() => {
                navigate("/credit-card-authorization");
                window.scrollTo(0, 0);
              }}
              className="link-hover link whitespace-nowrap text-base"
            >
              Credit Card Authorization
            </p>

            <p
              onClick={() => {
                navigate("/terms-and-conditions");
                window.scrollTo(0, 0);
              }}
              className="link-hover link whitespace-nowrap text-base"
            >
              Terms and conditions
            </p>
            <p
              onClick={() => {
                navigate("/privacy-policy");
                window.scrollTo(0, 0);
              }}
              className="link-hover link whitespace-nowrap text-base"
            >
              Privacy policy
            </p>
            <p
              onClick={() => {
                navigate("/flight-tracker");
                window.scrollTo(0, 0);
              }}
              className="link-hover link whitespace-nowrap text-base"
            >
              Flight Tracker
            </p>
          </div>
          <div>
            <ContactNumber />
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <h6 className="footer-title whitespace-nowrap text-base tracking-wider">
              Subscribe for latest Update
            </h6>
            <fieldset className="form-control w-80">
              <label className="label ">
                <span className="label-text whitespace-nowrap text-base text-white">
                  Enter your email address
                </span>
              </label>
              <div className="join">
                <input
                  {...register("email", {
                    required: true,
                  })}
                  type="text"
                  placeholder="username@gmail.com"
                  className="input join-item input-bordered w-48 text-white focus:border-none sm:w-44"
                />
                <button className="bg-carousal btn join-item">Subscribe</button>
              </div>
              <div className="mt-5 grid grid-cols-7">
                {[
                  { name: whatsapp, link: "https://www.whatsapp.com" },
                  { name: instagram, link: "https://www.instagram.com" },
                  { name: twitter, link: "https://twitter.com" },
                  { name: facebook, link: "https://www.facebook.com" },
                ].map((socialMedia, index) => (
                  <a href={socialMedia.link} key={index}>
                    <img
                      src={socialMedia.name}
                      alt={`Social Media ${index}`}
                      width={24}
                      height={24}
                      className="cursor-pointer"
                    />
                  </a>
                ))}
              </div>
              <div className="mt-5">
                <h2 className="text-blue-500">TICO REGISTRATION NO.:</h2>
                <h3>50027532</h3>
              </div>
            </fieldset>
          </form>
        </div>
      </footer>
    </section>
  );
}
