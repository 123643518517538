import React from "react";
import ScrollToTopOnRouteChange from "components/ScrollToTopOnRouteChange/ScrollToTopOnRouteChange";
import UserNavbar from "components/UserNavbar/UserNavbar";
import avatar from "../../../assets/img/auth/admin_picture.png";
import { charterplanesm } from "Utils/Images";
import Banner from "components/Charters/Banner";
import { aboutmaharjabg } from "Utils/Images";
import About from "components/Charters/About";
import { aboutmaharja } from "Utils/Images";
import { accountManager } from "Utils/Images";
import { ChiefInnovationExecutive } from "Utils/Images";
import { DirectorBusinessDevelopment } from "Utils/Images";
import Heading from "components/Heading/Heading.";
import { technologybglg } from "Utils/Images";
import { digitalmarkting } from "Utils/Images";
import { paras } from "Utils/Images";
import { asha } from "Utils/Images";

export default function TechTeam() {
  const teamMembers = [
    {
      name: "Paras Grover",
      position: "Chief technology officer",
      image: paras,
    },
    {
      name: "Rohit yadav",
      position: "Backend developer",
      image: accountManager,
    },
    {
      name: "Priya singh",
      position: "seo specialist",
      image: DirectorBusinessDevelopment,
    },
    {
      name: "Asha",
      position: "Software tester",
      image: asha,
    },
  ];

  return (
    <section className="text-gray-800">
      <UserNavbar />
      <ScrollToTopOnRouteChange />
      <Banner
        charterplane={technologybglg}
        charterplanesm={charterplanesm}
        heading={"Empowering Travel Agencies with Technology"}
        paragraph={
          "At Maharaja Technologies, we specialize in empowering small and home-based travel agencies to establish a formidable online presence. With our expertise in web development, SEO, and digital marketing, we provide tailored solutions that help travel agencies thrive in the competitive online landscape."
        }
        darkness={"rgba(0, 0, 0, 0.7)"}
      />
      <div className="m-auto max-w-8xl">
        <Heading
          Heading={"Our Expertise"}
          paragraph={
            "Tailored Web Development, SEO, and Travel API Integration for Agencies."
          }
          discription={
            "Our solutions are customized to the unique needs of travel agencies, providing dynamic packaging tools and state-of-the-art booking engines."
          }
        />
        <About
          bussinesperson={aboutmaharja} // Change to your Maharaja logo
          heading={"Tailored Solutions for Travel Agencies"}
          paragraph={
            "At Maharaja Technologies, we understand that every travel agency is different, with its own set of challenges and goals. That's why we don't believe in one-size-fits-all solutions. Instead, we take the time to understand your specific needs and objectives before crafting a customized solution that addresses them head-on. Whether you're looking to revamp your existing website, optimize your SEO strategy, or integrate new APIs, our team is here to help you every step of the way."
          }
          security={{
            first: "Advanced Web Development",
            second: "SEO Optimization",
            third: "Integration of Travel APIs",
            fourth: "Dynamic Packaging Tools",
            fifth: "State-of-the-Art Booking Engines",
          }}
          marginMt={"mt-10"}
        />
        <Heading
          Heading={"Digital Marketing Solutions"}
          paragraph={
            "Elevate Your Online Presence with SEO and Digital Marketing for Travel Success."
          }
          discription={
            "We employ keyword optimization, content creation, and social media management to ensure your website ranks highly and attracts significant traffic."
          }
        />
        <About
          bussinesperson={digitalmarkting} // Change to your Maharaja logo
          heading={"Increasing Online Visibility"}
          paragraph={
            "Our digital marketing services extend to social media management and pay-per-click advertising campaigns, tailored for the travel industry. By targeting the right audience with compelling content and strategic advertising, we help you generate leads and increase conversions, ultimately driving revenue growth for your travel agency."
          }
          security={{
            first: "Advanced SEO Strategies",
            second: "Content Creation",
            third: "Social Media Management",
            fourth: "Pay-Per-Click Advertising",
          }}
          border={"border-b-2"}
          marginMt={"mt-10"}
          direction={"lg:flex-row"}
        />
        <Heading
          Heading={"Analytics and Tech Support"}
          paragraph={" Drive Growth with Analytics Tools and Tech Support."}
          discription={
            "Our tech support team ensures your systems run smoothly, allowing you to focus on growing your business."
          }
        />
        <About
          bussinesperson={aboutmaharja} // Change to your Maharaja logo
          heading={"Comprehensive Support"}
          paragraph={
            "Leverage our technological expertise and industry knowledge to succeed in the online travel market. Whether you need assistance troubleshooting technical issues, implementing new features, or optimizing your website's performance, our dedicated support team is here to help. With round-the-clock assistance and proactive maintenance, we ensure that your systems operate smoothly, allowing you to focus on what you do best – running your travel agency."
          }
          security={{
            first: "Comprehensive Analytics Tools",
            second: "Dedicated Tech Support",
            third: "Focus on Business Growth",
          }}
          marginMt={"mt-10"}
        />
        <Heading
          Heading={"Tech team"}
          paragraph={"Expert Web Development, SEO & Travel API Solutions."}
          discription={
            "Designed for travel agencies, we offer dynamic packaging tools and advanced booking engines to enhance efficiency and client satisfaction."
          }
        />

        <div className="mb-5 grid grid-cols-1 gap-4 px-5 md:grid-cols-4">
          {teamMembers.map((member, index) => (
            <div
              key={index}
              className="rounded-md border border-gray-300 p-4 text-center"
            >
              <img
                src={member.image}
                alt={member.name}
                className="mb-4 h-auto w-full rounded-md"
              />
              <div className="text-lg font-semibold uppercase">
                {member.name}
              </div>
              <div className="text-sm uppercase text-gray-600">
                {member.position}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
