import React, { useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import PopularFlightRoutesCard from "components/PopularFlightRoutesCard/PopularFlightRoutesCard";
import { indiaGate } from "Utils/Images"; // Use your actual image paths
import { NewYork } from "Utils/Images";
import { LosAngeles } from "Utils/Images";
import { Chicago } from "Utils/Images";
import { Montreal } from "Utils/Images";
import { CalgaryTower } from "Utils/Images";
import { Vancouver } from "Utils/Images";
import { cntower } from "Utils/Images";
import { Miami } from "Utils/Images";
import { hollywood } from "Utils/Images";
import { Houston } from "Utils/Images";
import { WillisTower } from "Utils/Images";

export default function PopularFlightRoutes() {
  const [showAll, setShowAll] = useState(false);

  // Array of flight route data
  const flightRoutes = [
    {
      title: "Toronto flights",
      imageSrc: cntower, // Use an image representing Mumbai
      destinations: ["Amritsar", "Delhi", "Mumbai", "Chennai", "Kolkata"],
    },
    {
      title: "Montreal Flights",
      imageSrc: Montreal, // Use an image representing Delhi
      destinations: ["Amritsar", "Delhi", "Mumbai", "Chennai", "Kolkata"],
    },

    {
      title: "Calgary Flights",
      imageSrc: CalgaryTower, // Use an image representing Goa
      destinations: ["Amritsar", "Delhi", "Mumbai", "Chennai", "Kolkata"],
    },
    {
      title: "Vancouver Flights",
      imageSrc: Vancouver, // Use an image representing Toronto
      destinations: ["Amritsar", "Delhi", "Mumbai", "Chennai", "Kolkata"],
    },
    {
      title: "New York Flights",
      imageSrc: NewYork, // Use an image representing Chandigarh
      destinations: ["Amritsar", "Delhi", "Mumbai", "Chennai", "Kolkata"],
    },
    {
      title: "Miami Flights",
      imageSrc: Miami, // Use an image representing Vancouver
      destinations: ["Amritsar", "Delhi", "Mumbai", "Chennai", "Kolkata"],
    },
    {
      title: "Los Angeles  Flights",
      imageSrc: hollywood, // Use an image representing Montreal
      destinations: ["Amritsar", "Delhi", "Mumbai", "Chennai", "Kolkata"],
    },
    {
      title: "Chicago Flights",
      imageSrc: WillisTower, // Use an image representing New York
      destinations: ["Amritsar", "Delhi", "Mumbai", "Chennai", "Kolkata"],
    },
    {
      title: "Houston Flights",
      imageSrc: Houston, // Use an image representing Los Angeles
      destinations: ["Amritsar", "Delhi", "Mumbai", "Chennai", "Kolkata"],
    },

    // {
    //   title: "Miami Flights",
    //   imageSrc: indiaGate, // Use an image representing Miami
    //   destinations: ["Los Angeles", "New York", "Toronto"],
    // },
    // {
    //   title: "Chicago Flights",
    //   imageSrc: indiaGate, // Use an image representing Chicago
    //   destinations: ["Toronto", "New York", "Los Angeles"],
    // },
    // {
    //   title: "San Francisco Flights",
    //   imageSrc: indiaGate, // Use an image representing San Francisco
    //   destinations: ["New York", "Los Angeles", "Vancouver"],
    // },
  ];

  // Show only 9 cards by default
  const cardsToShow = showAll ? flightRoutes : flightRoutes.slice(0, 9);

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const controls = useAnimation();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [inView, controls]);

  return (
    <section ref={ref} className="m-auto max-w-8xl px-5 sm:px-0">
      <motion.h4
        initial={{ opacity: 0 }}
        animate={inView ? { opacity: 1 } : { opacity: 0 }}
        transition={{ duration: 0.5 }}
        className="text-xl sm:text-4xl"
      >
        Popular <span className="font-bold">Flight Routes</span>
      </motion.h4>
      <motion.div
        className="my-5 grid grid-cols-1 gap-2 sm:grid-cols-2 sm:gap-5 md:grid-cols-3"
        initial="hidden"
        animate={controls}
      >
        {cardsToShow.map((route, index) => (
          <motion.div
            key={index}
            custom={index}
            variants={{
              hidden: { opacity: 0, y: 50 },
              visible: (i) => ({
                opacity: 1,
                y: 0,
                transition: {
                  delay: i * 0.1, // Stagger effect
                  duration: 0.3, // Duration of each card animation
                },
              }),
            }}
            initial="hidden"
            animate="visible" // Change to animate="visible" to trigger animation
          >
            <PopularFlightRoutesCard
              title={route.title}
              imageSrc={route.imageSrc}
              destinations={route.destinations}
            />
          </motion.div>
        ))}
      </motion.div>
      {!showAll && (
        <motion.button
          className="mx-auto my-5 mt-0 block font-semibold text-red-600 sm:my-0 sm:mt-5"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          onClick={() => setShowAll(true)}
        >
          View More &#x2193;
        </motion.button>
      )}
    </section>
  );
}
