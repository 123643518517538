import Heading from "components/Heading/Heading.";
import ScrollToTopOnRouteChange from "components/ScrollToTopOnRouteChange/ScrollToTopOnRouteChange";
import UserNavbar from "components/UserNavbar/UserNavbar";
import React from "react";
import { IoLocationSharp } from "react-icons/io5";
import avatar from "../../../assets/img/auth/admin_picture.png";
import Background from "components/background/Background";
import { charterplane } from "Utils/Images";
import { charterplanesm } from "Utils/Images";
import Banner from "components/Charters/Banner";
import { aboutmaharjabg } from "Utils/Images";
import About from "components/Charters/About";
import { bussinesperson } from "Utils/Images";
import { aboutmaharja } from "Utils/Images";
import Banner1 from "views/admin/marketplace/components/Banner";
import WhychooseUs from "components/Charters/WhychooseUs";
import { accountManager } from "Utils/Images";
import { ChiefInnovationExecutive } from "Utils/Images";
import { DirectorBusinessDevelopment } from "Utils/Images";
import { ceo } from "Utils/Images";
import { Oliver } from "Utils/Images";
import { ceowith } from "Utils/Images";

export default function AboutUs() {
  const teamMembers = [
    {
      name: "Oliver",
      position: "Director Business Development",
      image: Oliver,
    },
    {
      name: "Sumit malik",
      position: "Chief Innovation Executive",
      image: ChiefInnovationExecutive,
    },
    {
      name: "Sanjana verma",
      position: "Luxury Travel Advisor",
      image: DirectorBusinessDevelopment,
    },
    {
      name: "Aakash kumar",
      position: "Account manager",
      image: accountManager,
    },
  ];
  const maharajaFlightsInfo = [
    {
      title: "Cheap Airline Tickets",
      description:
        "At Maharaja Flights, we understand the importance of saving money while traveling. That's why we strive to provide the most competitive prices on airline tickets, ensuring that you can explore the world without breaking the bank.",
    },
    {
      title: "Head office in Ontario Canada",
      description:
        "Conveniently located in Ontario, our travel agency is staffed with experienced professionals who are dedicated to helping you plan the perfect trip. Whether you're looking for a quick getaway or a long-haul adventure, we're here to assist you every step of the way.",
    },
    {
      title: "Flights to India",
      description:
        "As specialists in flights to India, we offer a wide range of options to suit every traveler's needs. Whether you're visiting family, exploring iconic landmarks, or embarking on a spiritual journey, we'll help you find the best deals on flights to your desired destination.",
    },
    {
      title: "Worldwide Destinations",
      description:
        "In addition to India, Maharaja Flights also provides cheap flights to a variety of worldwide destinations. From bustling metropolises to serene beach resorts, we'll help you discover new and exciting places to explore.",
    },
    {
      title: "Multilingual Support",
      description:
        "Communication is key, which is why we proudly offer support in multiple languages including English, Hindi, Punjabi, and Urdu. Our team is dedicated to providing personalized assistance to ensure that your travel experience is seamless from start to finish.",
    },
    {
      title: "Comprehensive Travel Services",
      description:
        "In addition to airline tickets, we also offer a range of travel-related services including all-inclusive packages, charters, hotel bookings, and car rentals. Whatever your travel needs may be, Maharaja Flights has you covered.",
    },
    {
      title: "Multiple Locations",
      description:
        "With offices and a strong presence in Vancouver, BC, Syracuse NY, Miami, FL, San Francisco, CA and our head office in Toronto, ON, we're able to serve customers from coast to coast with ease and efficiency.",
    },
    {
      title: "Travel Insurance",
      description:
        "We offer comprehensive travel insurance options to protect you from unexpected events during your journey. Whether it’s medical emergencies, trip cancellations, or lost luggage, our insurance plans ensure you're covered.",
    },
    {
      title: "24/7 Customer Support",
      description:
        "Maharaja Flights provides round-the-clock customer support. No matter what time zone you're in or the nature of your query, our dedicated support team is always available to assist you.",
    },
    {
      title: "Exclusive Discounts and Offers",
      description:
        "We regularly offer exclusive discounts and promotions on flights and travel packages. Stay connected with us to take advantage of special deals that make your travel more affordable.",
    },
  ];

  return (
    <section className="text-gray-800">
      <UserNavbar />
      <ScrollToTopOnRouteChange />
      <Banner
        charterplane={aboutmaharjabg}
        charterplanesm={charterplanesm}
        heading={"Your Gateway to Affordable Air Travel"}
        paragraph={
          "Welcome to Maharaja Flights! Your budget travel solution based in Toronto, Canada. Specializing in affordable flights to India and beyond."
        }
        darkness={"rgba(0, 0, 0, 0.7)"}
      />
      <div className="m-auto max-w-8xl">
        <Heading
          Heading={"About the company"}
          paragraph={
            "We are a leading travel agency specializing in flight ticket booking and exceptional travel experiences."
          }
          discription={
            "Concise overview of our expertise in flight ticket booking and travel services."
          }
        />
        {/* <div className="mb-5">
          <iframe
            title="Embedded Map"
            width="100%"
            height="500"
            frameBorder="0"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2873.9017781008333!2d-80.10054372392592!3d43.92000757109073!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b009d7f17b41d%3A0x40579bd5fb157d4f!2s10%20Zina%20St%2C%20Orangeville%2C%20ON%20L9W%201E1%2C%20Canada!5e0!3m2!1sen!2sin!4v1714671574901!5m2!1sen!2sin"
          ></iframe>
        </div> */}
        {/* <div className="mb-5 grid grid-cols-1 gap-4 px-5 sm:grid-cols-2 sm:px-0 md:grid-cols-3">
          {locations.map((location, index) => (
            <div
              key={index}
              className="flex h-56 flex-col justify-center rounded-lg bg-gray-50 p-4 text-center "
            >
              <div className="mx-auto mb-2">
                <IoLocationSharp size={24} />
              </div>
              <h2 className="text-lg font-bold">{location.name}</h2>
              <p className="text-gray-700">{location.hours}</p>
              <p className="text-gray-700">{location.address}</p>
            </div>
          ))}
        </div> */}
        <About
          bussinesperson={aboutmaharja}
          heading={"Your Gateway to Affordable Air Travel"}
          // shortParagraph={"Experience luxury travel on a private jet"}
          paragraph={
            "Welcome to Maharaja Flights, your premier destination for cheap airline tickets and comprehensive travel services. With a strong presence in Toronto, Canada, we specialize in offering budget-friendly flights to India and a plethora of worldwide destinations."
          }
          security={{
            first: "Cheap Airline Tickets",
            second: "Head office in Ontario Canada",
            third: "Flights to India",
            fourth: "Worldwide Destinations",
            fifth: "Multilingual Support",
            six: "Comprehensive Travel Services",
            seven: "Multiple Locationsa",
          }}
          marginMt={"mt-10"}
        />

        <Heading
          Heading={"Our Leadership"}
          paragraph={"Meet the exceptional leaders driving our success."}
          discription={
            "Get to know the visionary CEOs whose expertise and dedication make our company unique."
          }
        />

        <About
          bussinesperson={ceowith}
          imageWidth={"w-[500px]"}
          heading={"Rajan Khadwal - CEO"}
          paragraph={
            "Rajan Khadwal, as the CEO of Maharaja Flights Limited, draws from over 17 years of distinguished experience in the travel industry. His journey began with major airlines and top business houses, where he honed his expertise in aviation and business management. With a solid educational foundation in business management and commerce, Rajan's career trajectory also includes a commendable stint in the police service, adding a unique perspective to his leadership style."
          }
          about={[
            {
              heading: "Sonia Khadwal - Executive Director",
              paragraph:
                "Sonia Khadwal serves as the Executive Director of Maharaja Flights Limited, bringing over 19 years of expertise as a financial planner in the financial industry. Her strategic acumen and meticulous approach to financial management have been instrumental in steering the company towards sustained growth and operational success.",
            },
            {
              heading: "Rajan and Sonia Khadwal",
              paragraphs: [
                "Rajan and Sonia Khadwal, celebrating three decades of marriage, bring a rich heritage of service and leadership to their roles at Maharaja Flights Limited. Hailing from a lineage deeply rooted in military and law enforcement backgrounds, they embody a blend of discipline, strategy, and commitment to excellence.",
                "Together, Rajan and Sonia Khadwal have leveraged their extensive industry knowledge and entrepreneurial spirit to establish Maharaja Flights Limited, an innovative all-in-one travel site. Their combined vision has transformed Maharaja Flights Limited into a premier provider of luxury travel experiences, renowned for its impeccable service and comprehensive travel solutions.",
                "Beyond their professional achievements, Rajan and Sonia Khadwal epitomize a partnership built on mutual respect, shared goals, and a commitment to excellence. Their journey together is a testament to their unwavering dedication to each other and to shaping the future of the aviation and travel industries.",
              ],
            },
          ]}
          border={"border-b-2"}
          marginMt={"mt-10"}
          direction={"lg:flex-row"}
        />

        <div className="">
          <WhychooseUs
            heading={"At Maharaja Flights,"}
            p={"we make your travel dreams a reality."}
            reasons={maharajaFlightsInfo}
          />
        </div>

        <Heading
          Heading={"Our Team"}
          paragraph={"Meet the incredible individuals who drive our success."}
          discription={
            "Get to know our dedicated team and discover what makes us unique."
          }
        />

        <div className="mb-5 grid grid-cols-1 gap-4 px-5 md:grid-cols-4">
          {teamMembers.map((member, index) => (
            <div
              key={index}
              className="rounded-md border border-gray-300 p-4 text-center"
            >
              <img
                src={member.image}
                alt={member.name}
                className="mb-4 h-auto w-full rounded-md"
              />
              <div className="text-lg font-semibold">{member.name}</div>
              <div className="text-sm text-gray-600">{member.position}</div>
            </div>
          ))}
        </div>
        {/* <div className="my-5">
          <Banner1
            paragraph={"We're hiring!"}
            discription={
              "Our team is growing fast and we're always looking for smart people."
            }
            button={"View"}
          />
        </div> */}
        <div className="px-5">
          <div
            className="my-5 rounded-lg py-5 px-5 text-white sm:px-0 sm:py-14"
            style={{ background: "#4415eb" }}
          >
            <Heading
              paragraph={"We're hiring!"}
              discription={
                "Our team is growing fast and we're always looking for smart people."
              }
              button={"View"}
              color="text-white"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
