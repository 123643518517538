import Login from "auth/Login";
import SignUp from "auth/SignUp";
import React, { useState } from "react";

export default function Loginsignup({ bg, padding }) {
  const [openLogin, setOpenLogin] = useState(false);
  const [openSignUp, setOpenSignUp] = useState(false);

  const handleLoginClick = () => {
    setOpenLogin(true);
    setOpenSignUp(false); // Close SignUp when opening Login
  };

  const handleSignUpClick = () => {
    setOpenSignUp(true);
    setOpenLogin(false); // Close Login when opening SignUp
  };

  return (
    <section>
      <div className="flex items-center">
        <p
          className={`ml-3 ${bg} w-full ${padding} text-gray-900`}
          onClick={handleLoginClick}
        >
          Login
        </p>
        <p
          className={`ml-3 w-full ${bg} ${padding} whitespace-nowrap text-gray-900`}
          onClick={handleSignUpClick}
        >
          Sign Up
        </p>
      </div>
      <Login openLogin={openLogin} setOpenLogin={setOpenLogin} />
      <SignUp open={openSignUp} setOpen={setOpenSignUp} />
    </section>
  );
}
