import axios from "axios";

const REACT_APP_AMADEUS_STATUS_API_TOKEN =
  process.env.REACT_APP_AMADEUS_STATUS_API_TOKEN;

const headers = {
  Authorization: `Bearer ${"2xro6TGesutVCjTA30WGBG07ZLGz  "}`,
};

console.log("ookk", REACT_APP_AMADEUS_STATUS_API_TOKEN);

const AmadeusFlightStatusResources = {
  flightStatus: (data) => {
    return axios.get("https://test.api.amadeus.com/v2/schedule/flights", {
      headers,
      params: data, // Assuming data is used as query parameters
    });
  },
};

export default AmadeusFlightStatusResources;
