import React from "react";
import { FaStar } from "react-icons/fa";
import { GiAutoRepair } from "react-icons/gi";
import { IoIosPerson } from "react-icons/io";
import { FaFan } from "react-icons/fa";
import { GiCarDoor } from "react-icons/gi";
export default function Carcard({ img, name }) {
  return (
    <section className="flex flex-col rounded-lg border p-3 shadow-xl">
      <div className="m-auto max-h-96 py-10 px-5 sm:py-3">
        <img src={img} alt="" className="relative z-[1]  m-auto  w-full" />
      </div>
      <div className="mb-5">
        <h2 className="text-gray-800">{name}</h2>
        <div className="flex items-center gap-1">
          <FaStar color="orange" />
          <h2>
            {" "}
            <span className="text-gray-800">4.8</span> (2.436 reviews)
          </h2>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-1 border-b border-gray-600 pb-5">
        <div className="flex items-center gap-1">
          <IoIosPerson size={20} color="gray" />
          <p className="text-md whitespace-nowrap">4 Passagers</p>
        </div>
        <div className="flex items-center gap-1">
          <GiAutoRepair size={20} color="gray" />
          <p className="text-md whitespace-nowrap">Auto</p>
        </div>
        <div className="flex items-center gap-1">
          <FaFan size={20} color="gray" />
          <p className="text-md whitespace-nowrap">air conditioner</p>
        </div>
        <div className="flex items-center gap-1">
          <GiCarDoor size={20} color="gray" />
          <p className="text-md whitespace-nowrap">4 Doors</p>
        </div>
      </div>
      <div className="my-2 flex justify-between text-sm">
        <h3>Price</h3>
        <h3 className="text-gray-800">$1,800 /day</h3>
      </div>
      <button className="bg-carousal mt-2 rounded-full py-2">Rent Now</button>
    </section>
  );
}
