import React, { useEffect, useState } from "react";
import "../../../assets/css/App.css";
import SearchComponent from "../SearchComponent/SearchComponent";
import FormSetupFormMobile from "components/FormSetupFormMobile/FormSetupFormMobile";
import TravelStories from "components/TravelStories/TravelStories";
import PhoneNumberPopup from "components/PhoneNumberPopup/PhoneNumberPopup";
import { useDispatch, useSelector } from "react-redux";
import { selectUserInfo } from "../../../redux/userSlice";
import userResources from "api/userResources";
import { setOffers } from "../../../redux/StoreExculsiceOffersData/offersSlice";

export default function Home() {
  const userInfo = useSelector(selectUserInfo);
  const [isMobile, setIsMobile] = useState("");
  const dispatch = useDispatch();

  const getOffers = async () => {
    try {
      const response = await userResources.offerList();
      dispatch(setOffers(response.data.data)); // Store offers in Redux
    } catch (error) {
      console.error("Error fetching offers:", error);
    }
  };
  useEffect(() => {
    getOffers();
  }, []);

  return (
    <div>
      {/* for phone screen */}
      <FormSetupFormMobile />
      <div className={`${userInfo.status ? "hidden" : ""} visible `}>
        <PhoneNumberPopup />
      </div>
      {/* For laptop screen and tab */}
      <SearchComponent />
      <TravelStories />
    </div>
  );
}
