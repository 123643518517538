import React from "react";
import SearchItemRow from "../SearchItemRow/SearchItemRow";
import ExclusiveDeals from "components/ExclusiveDeals/ExclusiveDeals";
import Background from "components/background/Background";
import {
  airport,
  airport2,
  beatch,
  Carousel1,
  customer_service,
  dinnerOffer,
  flight_primary,
  FlightNetwork,
  Freedinnerinner,
  guarantee,
  holiOffer,
  Hotel,
  SecureBooking,
} from "../../../Utils/Images";
import FlightWayRoute from "components/FlightWayRoute/FlightWayRoute";
import { useLocation } from "react-router-dom";
import PopularDestination from "components/PopularDestination/PopularDestination";
import WhyBook from "components/WhyBook/WhyBook";
import PopularFlightRoutes from "components/PopularFlightRoutes/PopularFlightRoutes";
import { useSelector } from "react-redux";
export default function Flight() {
  const location = useLocation();
  const offers = useSelector((state) => state?.offers?.offers);
  const flightOptions = [
    {
      icon: guarantee,
      title: "Lowest Fare Guarantee",
      description:
        "Find the lowest fares on flights with our lowest fare guarantee. If you find a cheaper fare elsewhere, we'll match it and refund the difference.",
      shortDis:
        "Find the lowest fares on flights with our lowest fare guarantee.",
    },

    {
      icon: SecureBooking,
      title: "Secure Booking",
      description:
        "Book your flight with confidence. We use the latest encryption technology to ensure your personal and payment information is kept secure.",
      shortDis: "We use encryption technology to keep your information secure.",
    },
    {
      icon: customer_service,
      title: "24/7 Customer Support",
      description:
        "Need help? Our customer support team is available 24/7 to assist you with any questions or concerns you may have before, during, or after your flight.",
      shortDis: "Our customer support team is available 24/7 to assist you.",
    },
    {
      icon: FlightNetwork,
      title: "Extensive Flight Network",
      description:
        "Choose from a vast selection of flights worldwide. From budget airlines to premium carriers, we offer flights to destinations across the globe.",
      shortDis: "Choose from a wide selection of flights worldwide.",
    },
  ];

  return (
    <section className="text-gray-800">
      <Background bg={beatch} hidden="hidden" block="block" />
      {/* Laptop size */}
      <div
        className={`absolute top-44 left-1/2 hidden w-5/6  -translate-x-1/2 transform rounded-lg sm:block lg:top-72  ${
          location.pathname === "/" ? "bgimage h-60" : "bg-white"
        } text-gray-900 `}
      >
        <SearchItemRow />
        <div className="mt-7">
          <FlightWayRoute />
        </div>
      </div>
      {/* Mobile size */}
      <div className="block text-red-500 sm:hidden">
        <FlightWayRoute />
      </div>
      <ExclusiveDeals carouselData={offers?.FLIGHT} />
      {/* <PopularDestination
        heading="Destination"
        discription="
Airports play a crucial role as vital transportation hubs that connect people, goods, and ideas from all corners of the globe. Serving as gateways to the world, airports facilitate not only travel but also international commerce, trade, and cultural exchange. With millions of passengers passing through their terminals every day, airports are essential in fostering connections between nations, businesses, and individuals."
        points={[
          {
            name: "Indira Gandhi International Airport",
            description:
              "Delhi International Airport, a bustling gateway to India's capital, welcomes travelers with open arms and top-notch facilities. From its modern terminals to efficient services, it ensures a seamless travel experience for passengers worldwide.",
          },
          {
            name: "Chhatrapati Shivaji International Airport",
            description:
              "Mumbai, the bustling metropolis on India's west coast, boasts Chhatrapati Shivaji Maharaj International Airport, a vibrant hub of connectivity and culture. Serving millions of passengers annually, it offers world-class amenities and a warm Indian hospitality. Experience the vibrant spirit of Mumbai at its international gateway.",
          },
          {
            name: "Shaheed Bhagat Singh International Airport",
            description:
              "Shaheed Bhagat Singh International Airport, a symbol of pride and progress in the heart of Punjab, India. With its modern facilities and efficient services, this airport ensures seamless travel experiences for passengers from around the globe. Discover the warmth of Punjab hospitality as you arrive at this dynamic aviation hub.",
          },
        ]}
        imageCollactions={{
          img1: flight_primary,
          img2: airport,
          img3: airport,
          img4: airport2,
        }}
      /> */}

      <PopularFlightRoutes />
      <WhyBook Options={flightOptions} heading={"Flight"} />
    </section>
  );
}
