import { Carousel1 } from "Utils/Images";
import { innerHolioffer } from "Utils/Images";
import { holiOffer } from "Utils/Images";
import DealsCard from "components/DealsCard/DealsCard";
import RadioButton from "components/RadioButton/RadioButton";
import ScrollToTopOnRouteChange from "components/ScrollToTopOnRouteChange/ScrollToTopOnRouteChange";
import UserNavbar from "components/UserNavbar/UserNavbar";
import React, { useEffect, useState } from "react";
import { FaArrowCircleRight } from "react-icons/fa";
export default function Offer() {
  const [stick, setSticky] = useState("top-5");
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setSticky("top-24");
      } else {
        setSticky("top-5");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const [selectedOption, setSelectedOption] = useState({
    name: "Hot Deals",
    id: "hot_deals",
  });

  const handleOptionChange = ({ name, id }) => {
    console.log("Name:", name);
    console.log("ID:", id);
    setSelectedOption({ name, id });
  };

  const options = [
    { name: "Hot Deals", id: "hot_deals" },
    { name: "Flight", id: "flight" },
    { name: "Hotel", id: "hotel" },
    { name: "Holidays", id: "holidays" },
    { name: "Cruise", id: "cruise" },
  ];

  const carouselData = [
    {
      id: "1",
      Image: holiOffer,
      heading: "car International at LOWEST FARE!",
      paragraph: "Book car France-KLM Airline Flight Tickets.",
      offer: "Flight",
      coupon: "HOLI45",
      innerImage: innerHolioffer,
      booking_validaity: "30/05/2024",
      terms: [
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
      ],
    },
    {
      id: "2",
      Image: holiOffer,
      heading: "car International at LOWEST FARE!",
      paragraph: "Book car France-KLM Airline Flight Tickets.",
      offer: "Flight",
      coupon: "HOLI45",
      innerImage: Carousel1,
      booking_validaity: "30/05/2024",
      terms: [
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
      ],
    },
    {
      id: "3",
      Image: holiOffer,
      heading: "car International at LOWEST FARE!",
      paragraph: "Book car France-KLM Airline Flight Tickets.",
      offer: "Flight",
      coupon: "HOLI45",
      innerImage: Carousel1,
      booking_validaity: "30/05/2024",
      terms: [
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
      ],
    },
    {
      id: "4",
      Image: holiOffer,
      heading: "car International at LOWEST FARE!",
      paragraph: "Book car France-KLM Airline Flight Tickets.",
      offer: "Flight",
      coupon: "HOLI45",
      innerImage: Carousel1,
      booking_validaity: "30/05/2024",
      terms: [
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
      ],
    },
    {
      id: "5",
      Image: holiOffer,
      heading: "car International at LOWEST FARE!",
      paragraph: "Book car France-KLM Airline Flight Tickets.",
      offer: "Flight",
      coupon: "HOLI45",
      innerImage: Carousel1,
      booking_validaity: "30/05/2024",
      terms: [
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
      ],
    },
    {
      id: "6",
      Image: holiOffer,
      heading: "car International at LOWEST FARE!",
      paragraph: "Book car France-KLM Airline Flight Tickets.",
      offer: "Flight",
      coupon: "HOLI45",
      innerImage: Carousel1,
      booking_validaity: "30/05/2024",
      terms: [
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
      ],
    },
    {
      id: "7",
      Image: holiOffer,
      heading: "car International at LOWEST FARE!",
      paragraph: "Book car France-KLM Airline Flight Tickets.",
      offer: "Flight",
      coupon: "HOLI45",
      innerImage: Carousel1,
      booking_validaity: "30/05/2024",
      terms: [
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
      ],
    },
    {
      id: "8",
      Image: holiOffer,
      heading: "car International at LOWEST FARE!",
      paragraph: "Book car France-KLM Airline Flight Tickets.",
      offer: "Flight",
      coupon: "HOLI45",
      innerImage: Carousel1,
      booking_validaity: "30/05/2024",
      terms: [
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
      ],
    },
    {
      id: "9",
      Image: holiOffer,
      heading: "car International at LOWEST FARE!",
      paragraph: "Book car France-KLM Airline Flight Tickets.",
      offer: "Flight",
      coupon: "HOLI45",
      innerImage: Carousel1,
      booking_validaity: "30/05/2024",
      terms: [
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
      ],
    },
  ];
  return (
    <section className="text-gray-800 ">
      <ScrollToTopOnRouteChange />
      <UserNavbar />
      <div className="m-auto my-5 max-w-8xl px-2 text-gray-800">
        <div className="flex items-center justify-between  px-2 sm:hidden">
          <h2 className="text-md ">{selectedOption.name}</h2>
          <div className="first-letter first-letter: dropdown dropdown-end bg-white  tracking-wide  ">
            <div
              tabIndex={0}
              role="button"
              className="text-md m-1 bg-white text-blue-500 hover:bg-white"
            >
              Filter
            </div>
            <ul
              tabIndex={0}
              className={` menu dropdown-content z-[1] w-52 rounded bg-gray-100 p-2 shadow `}
            >
              {options.map((option, index) => (
                <li key={index} className="active:bg-transparent">
                  <div
                    className={`${
                      selectedOption.id === option.id
                        ? "selected text-red-600"
                        : ""
                    } `}
                    onClick={() => handleOptionChange(option)}
                  >
                    {selectedOption.id === option.id && <FaArrowCircleRight />}
                    <span>{option.name}</span>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="mt-3 flex items-start justify-between gap-5 sm:my-5">
          <div
            className={`sticky ${stick} hidden h-60 w-2/4 border p-5 px-5 shadow-lg sm:block`}
          >
            <div className="flex justify-between">
              <h2>FILTER</h2>
              <h2 className="text-red-500">CLEAR ALL</h2>
            </div>
            <div className="my-2 flex flex-col">
              <ul className="list-none p-0">
                {options.map((option, index) => (
                  <li key={index}>
                    <div
                      className={`${
                        selectedOption.id === option.id ? "selected" : ""
                      } flex cursor-pointer items-center`}
                      onClick={() => handleOptionChange(option)}
                    >
                      {selectedOption.id === option.id && (
                        <div className="mr-3">
                          <FaArrowCircleRight />
                        </div>
                      )}
                      <span>{option.name}</span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div
            className="justify hide-scroll-bar  flex flex-wrap items-center gap-0 overflow-scroll sm:gap-0"
            style={{ height: "80vh" }}
          >
            {carouselData.map((item, index) => (
              <div
                key={index}
                className="mx-1.5 mb-2.5 w-full rounded-md border-2  md:w-1/2 lg:w-80"
              >
                <DealsCard item={item} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
