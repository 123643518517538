import DealsCard from "components/DealsCard/DealsCard";
import DealList from "components/DealsList.jsx/DealList";
import React from "react";
import { Link, useLocation } from "react-router-dom";
export default function ExclusiveDeals({ carouselData }) {
  const location = useLocation();
  const dealsList = [
    {
      name: "hot deal",
      link: "/hotdeals",
      color: location.pathname === "/hotdeals" ? "text-red-500" : "",
    },
    {
      name: "flight",
      link: "/flights",
      color:
        location.pathname === "/flights" ||
        location.pathname === "/flights/round-trip" ||
        location.pathname === "/flights/oneway" ||
        location.pathname === "/flights/multi-city" ||
        location.pathname === "/"
          ? "text-red-500"
          : "",
    },
    {
      name: "hotel",
      link: "/hotels",
      color: location.pathname === "/hotels" ? "text-red-500" : "",
    },
    {
      name: "holidays",
      link: "/holidays",
      color: location.pathname === "/holidays" ? "text-red-500" : "",
    },
    {
      name: "cruise",
      link: "/cruise",
      color: location.pathname === "/cruise" ? "text-red-500" : "",
    },
  ];
  return (
    <div className="m-auto  my-0 w-full max-w-8xl px-5 py-0 text-gray-900 sm:my-10 sm:py-5  sm:px-0  ">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-20">
          <h1 className="text-center text-xl font-light  md:text-left lg:text-4xl">
            Exclusive <span className="font-semibold">Deals</span>
          </h1>
          <div className="hidden sm:flex md:hidden lg:flex">
            <DealList dealsList={dealsList} />
          </div>
        </div>
        <div>
          <Link
            to={"/offers"}
            className=" cursor-pointer font-semibold text-red-500 underline "
          >
            View All Deals
          </Link>
        </div>
      </div>
      <div className="hide-scroll-bar block sm:hidden">
        <DealList
          dealsList={dealsList}
          mtop="mt-3"
          shadow="shadow-2xl"
          bg="bg-gray-100"
          overflow="overflow-scroll"
        />
      </div>
      <div className=" flex items-center">
        <div className="carousel my-4 h-full gap-2 rounded-box sm:my-8 ">
          {carouselData?.map((item, index) => (
            <div
              key={index}
              id={item.id}
              className="carousel-item w-80 rounded-md  border-2 sm:w-80 md:max-w-md lg:w-96"
            >
              <DealsCard item={item} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
