import userResources from "api/userResources";
import ScrollToTopOnRouteChange from "components/ScrollToTopOnRouteChange/ScrollToTopOnRouteChange";
import UserNavbar from "components/UserNavbar/UserNavbar";
import React, { useState } from "react";
import { toast } from "react-toastify";

export default function Job() {
  const [agentResume, setAgentResume] = useState(null);
  const [managerResume, setManagerResume] = useState(null);
  const [agentError, setAgentError] = useState(false);
  const [managerError, setManagerError] = useState(false);

  const handleAgentResumeUpload = (event) => {
    const file = event.target.files[0];
    setAgentResume(file);
    setAgentError(false);
  };

  const handleManagerResumeUpload = (event) => {
    const file = event.target.files[0];
    setManagerResume(file);
    setManagerError(false);
  };

  const handleAgentSubmit = (event) => {
    event.preventDefault();
    if (!agentResume) {
      setAgentError(true);
      return;
    }
    console.log("Agent Resume submitted:", agentResume);
    try {
      const response = userResources.agent(agentResume);
      console.log(response);
      toast.success("Application submitted successfully!");
      setAgentResume(null);
    } catch (error) {
      toast.error("Something Wrong !");
    }
  };

  const handleManagerSubmit = (event) => {
    event.preventDefault();
    if (!managerResume) {
      setManagerError(true);
      return;
    }
    console.log("Manager Resume submitted:", managerResume);
    try {
      const response = userResources.manager(managerResume);
      console.log(response);
      toast.success("Application submitted successfully!");
      setManagerResume(null);
    } catch (error) {
      toast.error("Something Wrong !");
    }
  };

  return (
    <section className="text-gray-800">
      <UserNavbar />
      <ScrollToTopOnRouteChange />

      <div className="m-auto max-w-8xl">
        <div className="container mx-auto p-6">
          <h2 className="mb-4 text-2xl font-bold">Job Opportunities</h2>

          <div className="mb-8">
            <h3 className="mb-2 text-xl font-semibold">Agent</h3>
            <h4 className="mb-2 font-semibold">Qualifications</h4>
            <ul className="mb-4 list-disc pl-5">
              <li>
                1 year sales experience in Travel industry would be an asset.
              </li>
              <li>
                Fluent in spoken and written English. Knowledge of Hindi and any
                other Indian language is a strong asset.
              </li>
              <li>Strong communication skills and market awareness.</li>
              <li>Exceptional customer service skills.</li>
              <li>Proficiency working with Excel.</li>
              <li>
                Communications: Ongoing communications with guide and operations
                management team - Creating Trip manifests (Guest Details).
              </li>
              <li>
                Office Management: Proficiency with Gmail, Google Docs, and
                Google Calendar etc.
              </li>
            </ul>
            <h4 className="mb-2 font-semibold">Responsibilities</h4>
            <ul className="mb-4 list-disc pl-5">
              <li>Handle customer inquiries and provide assistance</li>
              <li>Book flights, hotels, and other travel arrangements</li>
              <li>Provide travel information and advice to clients</li>
              <li>Process reservations and payments</li>
              <li>Resolve customer complaints and issues</li>
              <li>Keep updated on travel industry trends and changes</li>
            </ul>
            <p>We will provide training if you have no experience.</p>
            <form
              onSubmit={handleAgentSubmit}
              className="mt-5 flex flex-wrap items-center justify-between"
            >
              <input
                type="file"
                className={`file-input file-input-bordered file-input-info w-full max-w-lg bg-white sm:max-w-sm ${
                  agentError ? "border-red-500" : ""
                }`}
                accept=".pdf"
                onChange={handleAgentResumeUpload}
              />
              {agentError && (
                <p className="text-red-500">Please select a file.</p>
              )}
              <button
                type="submit"
                className="mt-5 w-full whitespace-nowrap rounded bg-blue-500 py-2 px-4 font-bold text-white hover:bg-blue-700 sm:mt-0 sm:w-auto"
              >
                Submit Agent Resume
              </button>
            </form>
          </div>

          <div className="mb-8">
            <h3 className="mb-2 text-xl font-semibold">Manager</h3>
            <h4 className="mb-2 font-semibold">Qualifications</h4>
            <ul className="mb-4 list-disc pl-5">
              <li>Education: College/University Degree</li>
              <li>
                Experience: at least 2 years in Travel trade or equivalent
                experience
              </li>
              <li>Must have TICO Certification for Supervisor/Manager</li>
            </ul>
            <h4 className="mb-2 font-semibold">Responsibilities</h4>
            <ul className="mb-4 list-disc pl-5">
              <li>Manage staff and assign duties</li>
              <li>
                Study market research and trends to determine consumer demand,
                potential sales volumes and effect of competitors' operations on
                sales
              </li>
              <li>Develop and implement marketing strategies</li>
              <li>Plan budgets and monitor revenues and expenses</li>
              <li>Determine staffing requirements</li>
              <li>
                Resolve problems that arise, such as customer complaints and
                supply shortages
              </li>
              <li>
                Plan, organize, direct, control and evaluate daily operations
              </li>
              <li>Recruit, hire and supervise staff and/or volunteers</li>
            </ul>
            <h4 className="mb-2 font-semibold">Personal suitability</h4>
            <ul className="mb-4 list-disc pl-5">
              <li>Adaptability</li>
              <li>Analytical</li>
              <li>Collaborative</li>
              <li>Efficiency</li>
              <li>Goal-oriented</li>
              <li>Excellent oral communication</li>
              <li>Excellent written communication</li>
              <li>Judgement</li>
              <li>Organized</li>
            </ul>
            <p>Work Term: Permanent</p>
            <p>
              Work Language: English, Knowledge of Hindi and another Indian
              language is an asset.
            </p>
            <p>
              Kindly submit your resume with us and we will get in touch soon.
            </p>
            <form
              onSubmit={handleManagerSubmit}
              className="mt-5 flex flex-wrap items-center justify-between"
            >
              <input
                type="file"
                accept=".pdf"
                className={`file-input file-input-bordered file-input-info w-full max-w-lg bg-white sm:max-w-sm ${
                  managerError ? "border-red-500" : ""
                }`}
                onChange={handleManagerResumeUpload}
              />
              {managerError && (
                <p className="text-red-500">Please select a file.</p>
              )}
              <button
                type="submit"
                className="mt-5 w-full whitespace-nowrap rounded bg-blue-500 py-2 px-4 font-bold text-white hover:bg-blue-700 sm:mt-0 sm:w-auto"
              >
                Submit Manager Resume
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
