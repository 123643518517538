import React from "react";
import { FaCheckCircle } from "react-icons/fa";

export default function About({
  bussinesperson,
  heading,
  shortParagraph,
  paragraph,
  discription,
  security,
  marginMt,
  direction,
  border,
  about,
  imageWidth,
}) {
  const flexClass = marginMt ? marginMt : "sm:mt-24";

  return (
    <div
      className={`mx-auto mt-10 flex flex-col justify-center gap-0 px-5 pb-0 sm:gap-14 sm:px-0 sm:pb-4 ${
        direction ? direction : "lg:flex-row-reverse"
      } ${flexClass}`}
    >
      <div
        className={`${border} mb-8 w-full lg:order-2 lg:mb-0 lg:w-1/2 ${
          about ? "flex justify-center" : ""
        }`}
      >
        <img
          src={bussinesperson}
          alt="Charter Plane"
          className={`${imageWidth ? imageWidth : "w-full"}`}
        />
      </div>
      <div className="w-full lg:order-1 lg:w-1/2 lg:pr-8">
        <div className="border-b-2 pb-5 sm:border-0">
          <h3 className="mb-2 text-xl font-semibold text-red-600">{heading}</h3>
          <h2 className="mb-4 text-2xl font-bold ">{shortParagraph}</h2>
          <h5 className="mb-4 text-gray-800">{paragraph}</h5>
          <p className="mb-4 text-gray-600">{discription}</p>
          <div>
            {security && (
              <div className="mb-2 flex items-center">
                <FaCheckCircle className="mr-2 text-green-500" />
                <span className="text-gray-800">
                  {security?.first}
                  {/* {security?.first || "100% Guarantee"} */}
                </span>
              </div>
            )}
            {security?.second && (
              <div className="mb-2 flex items-center">
                <FaCheckCircle className="mr-2 text-green-500" />
                <span className="text-gray-800">
                  {/* {security?.second || "100% Guarantee"} */}
                  {security?.second}
                </span>
              </div>
            )}
            {security?.third && (
              <div className="mb-2 flex items-center">
                <FaCheckCircle className="mr-2 text-green-500" />
                <span className="text-gray-800">
                  {/* {security?.third || "24/7 Support"} */}
                  {security?.third}
                </span>
              </div>
            )}
            {security?.fourth && (
              <div className="flex items-center">
                <FaCheckCircle className="mr-2 text-green-500" />
                <span className="text-gray-800">
                  {security?.fourth}
                  {/* {security?.fourth || "Luxury Experience"} */}
                </span>
              </div>
            )}

            {security?.fifth && (
              <div className="my-2 flex items-center">
                <FaCheckCircle className="mr-2 text-green-500" />
                <span className="text-gray-800">{security?.fifth || ""}</span>
              </div>
            )}
            {security?.six && (
              <div className="my-2 flex items-center">
                <FaCheckCircle className="mr-2 text-green-500" />
                <span className="text-gray-800">{security?.six || ""}</span>
              </div>
            )}
            {security?.saven && (
              <div className="my-2 flex items-center">
                <FaCheckCircle className="mr-2 text-green-500" />
                <span className="text-gray-800">{security?.saven || ""}</span>
              </div>
            )}
          </div>
          {about &&
            about.map((section, index) => (
              <div key={index} className="about-section">
                <h2 className="mb-2 text-xl font-semibold text-red-600">
                  {section.heading}
                </h2>
                {Array.isArray(section.paragraphs) ? (
                  section.paragraphs.map((para, i) => (
                    <p key={i} className="mb-5">
                      {para}
                    </p>
                  ))
                ) : (
                  <p>{section.paragraph}</p>
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
