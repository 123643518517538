import React from "react";
import { Link } from "react-router-dom";

export default function DealList({
  dealsList,
  display,
  mtop,
  shadow,
  bg,
  overflow,
}) {
  return (
    <ul
      className={`${
        display || "flex"
      } gap-6 hide-scroll-bar  font-semibold uppercase ${mtop} ${overflow}`}
    >
      {dealsList.map((item, index) => (
        <li
          key={index}
          className={`cursor-pointer hide-scroll-bar  whitespace-nowrap rounded-md ${bg} px-2 py-0.5 ${shadow} ${item.color} `}
        >
          <Link to={item.link}>{item.name}</Link>
        </li>
      ))}
    </ul>
  );
}
