import React, { useState, useEffect } from "react";
import Login from "auth/Login";
import SignUp from "auth/SignUp";
import { signup, user, canadaflag, indiaflag, language } from "Utils/Images";
import { useTranslation } from "react-i18next";

export default function LoginRegisterButton({ dropDown }) {
  const [openLogin, setOpenLogin] = useState(false);
  const [openSignUp, setOpenSignUp] = useState(false);
  const { i18n } = useTranslation();

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language") || "en";
    i18n.changeLanguage(savedLanguage);
  }, [i18n]);

  const languages = [
    {
      code: "en",
      img: canadaflag,
      lang: "English (Canadian)",
    },
    {
      code: "hi",
      img: indiaflag,
      lang: "Hindi (India)",
    },
    {
      code: "fr",
      img: indiaflag,
      lang: "franch",
    },
  ];

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng);
  };

  return (
    <div className={`dropdown ${dropDown} mr-2 w-full`}>
      <div
        tabIndex={0}
        role="button"
        className="btn m-1 mr-1 w-full border-none bg-red-500 py-0 uppercase text-white hover:bg-white hover:text-red-500"
      >
        login/register
      </div>
      <ul
        tabIndex={0}
        className="dropdown-content menu z-[1] w-full rounded-box bg-white p-2 shadow"
      >
        <div
          className="flex cursor-pointer items-center rounded-2xl p-3 hover:bg-gray-200"
          onClick={() => setOpenLogin(true)}
        >
          <img src={user} alt="User" />
          <p className="ml-3 w-full text-gray-900">Login</p>
        </div>
        <div
          className="flex cursor-pointer items-center rounded-2xl p-3 hover:bg-gray-200"
          onClick={() => setOpenSignUp(true)}
        >
          <img src={signup} alt="Sign Up" className="w-6" />
          <p className="ml-3 w-full text-gray-900">Sign Up</p>
        </div>
        {openLogin && (
          <Login openLogin={openLogin} setOpenLogin={setOpenLogin} />
        )}
        {openSignUp && <SignUp open={openSignUp} setOpen={setOpenSignUp} />}
        <div className="collapse hover:bg-gray-200">
          <input type="checkbox" className="peer" />
          <div className="collapse-title flex items-center text-primary-content">
            <img src={language} alt="language" className="w-6" />
            <a className="ml-3 w-full text-gray-900">Languages</a>
          </div>
          <div className="peer-checked:bg-gray-150 collapse-content text-primary-content peer-checked:text-gray-900">
            {languages.map((item) => (
              <div
                key={item.code}
                className={`mb-4 ml-1 flex cursor-pointer items-center ${
                  item.code === i18n.language ? "bg-red-500" : ""
                }`}
                onClick={() => changeLanguage(item.code)}
              >
                <img src={item.img} alt={`${item.lang} flag`} />
                <p className="ml-4">{item.lang}</p>
              </div>
            ))}
          </div>
        </div>
      </ul>
    </div>
  );
}
