import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  offers: [], // This will store the list of offers
};

const offerSlice = createSlice({
  name: "offers",
  initialState,
  reducers: {
    // Action to set offers
    setOffers: (state, action) => {
      state.offers = action.payload;
    },
    // Action to add a new offer
    addOffer: (state, action) => {
      state.offers.push(action.payload);
    },
    // Action to remove an offer
    removeOffer: (state, action) => {
      state.offers = state.offers.filter(
        (offer) => offer.id !== action.payload
      );
    },
  },
});

export const { setOffers, addOffer, removeOffer } = offerSlice.actions;
export default offerSlice.reducer;
