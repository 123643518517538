import React, { useState } from "react";
import SearchItemRow from "../SearchItemRow/SearchItemRow";
import ExclusiveDeals from "components/ExclusiveDeals/ExclusiveDeals";
import {
  Carousel1,
  Hotelicon,
  LuxuryCruise,
  airport,
  beatch,
  casino,
  cruise,
  cruise2,
  cruise3,
  cruise4,
  cruiseLocation,
  cruisemeals,
  dinnerOffer,
  holiOffer,
  innerHolioffer,
} from "../../../Utils/Images";
import options from "../Hotel/City";

import Background from "components/background/Background";
import PopularDestination from "components/PopularDestination/PopularDestination";
import WhyBook from "components/WhyBook/WhyBook";
import Select from "react-tailwindcss-select";
import { FaCalendarAlt } from "react-icons/fa";
import Datepicker from "react-tailwindcss-datepicker";
import CruiseFromPhone from "components/CruiseFromPhone/CruiseFromPhone";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export default function Cruise() {
  const offers = useSelector((state) => state?.offers?.offers);

  const [airpotsFrom, setAirpotsFrom] = useState(null);
  const [Room, setRoom] = useState(1);
  const [Guest, setGuest] = useState(2);
  const [depart, setDepart] = useState({
    startDate: new Date(),
    endDate: new Date().setMonth(11),
  });
  const [returnFlight, setReturnFlight] = useState({
    startDate: new Date(),
    endDate: new Date().setMonth(11),
  });

  const From = (value) => {
    setAirpotsFrom(value);
  };

  const departFun = (newValue) => {
    setDepart(newValue);
  };

  const setReturnFlightFun = (newValue) => {
    setReturnFlight(newValue);
  };

  const onSubmit = () => {
    const data = {
      airports_from: airpotsFrom,
      departure_date: depart,
      return_date: returnFlight,
      roundTrip: true,
      travelers: {
        Room: parseInt(Room),
        Guest: parseInt(Guest),
      },
    };
    console.log(data);
  };

  const cruiseOptions = [
    {
      icon: LuxuryCruise,
      title: "Luxury Cruises",
      description:
        "Experience the height of luxury on our cruise ships. Indulge in gourmet dining, spa treatments, and VIP service as you sail to exotic destinations.",
      shortDis: "Luxury cruise experience. Gourmet dining and VIP service.",
    },
    {
      icon: cruiseLocation,
      title: "Exciting Destinations",
      description:
        "Explore exciting destinations around the world on our cruises. From tropical islands to historic cities, we offer a wide range of cruise itineraries.",
      shortDis: "Explore exciting destinations. Wide range of itineraries.",
    },
    {
      icon: casino,
      title: "Onboard Entertainment",
      description:
        "Enjoy a variety of onboard entertainment options during your cruise. From live shows to casino nights, there's something for everyone to enjoy.",
      shortDis: "Variety of onboard entertainment. Something for everyone.",
    },
    {
      icon: cruisemeals,
      title: "All-Inclusive Packages",
      description:
        "Book an all-inclusive cruise package for a stress-free vacation. Everything from meals to onboard activities is included in one convenient package.",
      shortDis: "All-inclusive cruise packages. Stress-free vacation.",
    },
  ];

  const { t } = useTranslation();

  const heading = t("popularDestination.heading");
  const description = t("popularDestination.description.cruise");
  const points = t("popularDestination.points.cruise", { returnObjects: true });
  const imageCollactions = {
    img1: cruise,
    img2: cruise2,
    img3: cruise3,
    img4: cruise4,
  };

  return (
    <section className="text-gray-800">
      <Background bg={beatch} hidden="hidden" block="block" />
      <div className="absolute top-72 left-1/2 hidden h-72 w-5/6 -translate-x-1/2 transform rounded-lg bg-white text-gray-900 sm:block ">
        <SearchItemRow />
        <div className="my-12 px-5">
          <h2 className=" mb-4 text-center text-xl">
            Secure Your Memorable Cruise Experience Now!
          </h2>
          <form onSubmit={onSubmit} className="hidden sm:block">
            <div className="flex flex-wrap justify-start rounded-md border border-gray-700 py-5">
              <div className="flex w-1/4 flex-col border-r border-gray-700 px-3">
                <label className="text-md whitespace-nowrap">
                  Enter Destination or Preferred Cruise Line
                </label>
                <Select
                  value={airpotsFrom}
                  onChange={From}
                  options={options}
                  isSearchable={true}
                  placeholder="Toronto"
                  loading={false}
                  classNames={{
                    menuButton: ({ isDisabled }) =>
                      `flex w-full cursor-pointer relative -left-3 border-0 text-lg overflow-hidden text-gray-900 transition-all outline-none duration-300 focus:outline-none cursor-pointer ${
                        isDisabled
                          ? "bg-gray-200 "
                          : "bg-white hover:border-gray-400 cursor-pointer"
                      }`,
                    menu: "absolute -top-80 z-10 w-full bg-white shadow-lg border rounded py-1 mt-1.5 text-sm text-gray-700",
                    listItem: ({ isSelected }) =>
                      `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${
                        isSelected
                          ? `text-white bg-blue-500`
                          : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                      }`,
                  }}
                  formatGroupLabel={(data) => (
                    <div
                      className={`flex items-center justify-between py-2 text-xs`}
                    >
                      <span className="font-bold">{data.label}</span>
                      <span className="flex  h-5 items-center justify-center rounded-full bg-gray-200 p-1.5">
                        👉 Citys: {data.options.length}
                      </span>
                    </div>
                  )}
                  formatOptionLabel={(data) => (
                    <li
                      className={`mb-1 block cursor-pointer select-none truncate rounded px-2 py-2 transition duration-200 ${
                        !data.isSelected
                          ? `bg-gray-200 text-gray-900`
                          : `bg-blue-800 text-gray-200`
                      }`}
                    >
                      {data.label}
                    </li>
                  )}
                />
              </div>

              <div className=" w-1/4 border-r border-gray-700 px-3">
                <div className="flex items-center gap-2">
                  <FaCalendarAlt />
                  <label className="text-md">CHECK IN</label>
                </div>
                <Datepicker
                  containerClassName=""
                  inputClassName="p-0 pt-2 w-1/2 bg-white outline-0 text-gray-900 z-50 cursor-pointer "
                  toggleClassName="relative w-2/1 float-right hover:text-red-500 "
                  popoverDirection="up"
                  useRange={false}
                  asSingle={true}
                  value={depart}
                  showShortcuts={true}
                  // disabledDates={[
                  //   {
                  //     startDate: "2024-03-04",
                  //     endDate: "2023-02-05",
                  //   },
                  //   {
                  //     startDate: "2024-04-05",
                  //     endDate: "2023-02-12",
                  //   },
                  // ]}
                  onChange={departFun}
                />
              </div>
              <div className=" w-1/4 border-r border-gray-700 px-3">
                <div className="flex items-center gap-2">
                  <FaCalendarAlt />
                  <label className="text-md">CHECK OUT</label>
                </div>
                <Datepicker
                  containerClassName=""
                  inputClassName="p-0 bg-white pt-2 w-1/2 outline-0 text-gray-900 z-50 cursor-pointer "
                  toggleClassName="relative float-right w/2/1 hover:text-red-500 "
                  popoverDirection="up"
                  useRange={false}
                  asSingle={true}
                  value={returnFlight}
                  showShortcuts={true}
                  // disabledDates={[
                  //   {
                  //     startDate: "2024-03-04",
                  //     endDate: "2023-02-05",
                  //   },
                  //   {
                  //     startDate: "2024-04-05",
                  //     endDate: "2023-02-12",
                  //   },
                  // ]}
                  onChange={setReturnFlightFun}
                />
              </div>
              <div className="w-1/4 px-3">
                <div className="flex items-center gap-2">
                  <FaCalendarAlt />
                  <label className="text-md">CRUISE DETAILS</label>
                </div>
                <div className=" dropdown w-full ">
                  <div tabIndex={0} role="button" className="cursor-pointer">
                    <h3 className="text-lg">
                      {Room} Room {Guest} Guest
                    </h3>
                  </div>
                  <div
                    tabIndex={0}
                    className="menu dropdown-content z-[1] w-52 rounded-box bg-white p-2 shadow "
                  >
                    <div className="cursor-pointer rounded-md border-b p-2 hover:bg-gray-100">
                      <div className="flex items-center justify-between">
                        <h4>Rooms</h4>
                        <h4>{Room}</h4>
                        <div className="flex items-center rounded-md border">
                          <div
                            className="cursor-pointer border border-r-0 p-1 px-3 hover:bg-red-500 hover:text-white"
                            onClick={() => {
                              if (Room > 1) {
                                setRoom(Room - 1);
                              }
                            }}
                          >
                            -
                          </div>
                          <div
                            className="border-s-0 cursor-pointer border p-1 px-3 hover:bg-red-500 hover:text-white"
                            onClick={() => setRoom(Room + 1)}
                          >
                            +
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="cursor-pointer rounded-md border-b p-2 hover:bg-gray-100">
                      <div className="flex items-center justify-between">
                        <div>
                          <h4>Guests</h4>
                        </div>
                        <h4>{Guest}</h4>
                        <div className="flex items-center rounded-md border">
                          <div
                            className="cursor-pointer border border-r-0 p-1 px-3 hover:bg-red-500 hover:text-white"
                            onClick={() => {
                              if (Guest > 0) {
                                setGuest(Guest - 1);
                              }
                            }}
                          >
                            -
                          </div>
                          <div
                            className="border-s-0 cursor-pointer border p-1 px-3 hover:bg-red-500 hover:text-white"
                            onClick={() => setGuest(Guest + 1)}
                          >
                            +
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="absolute bottom-[-35px] flex w-full justify-center">
              <button className="btn-grad" type="submit">
                SUBMIT
              </button>
            </div>
          </form>
        </div>
      </div>
      {/* Mobile size */}
      <div className="block p-5 sm:hidden">
        <CruiseFromPhone options={options} />
      </div>
      <ExclusiveDeals carouselData={offers?.CRUISE} />
      {/* <PopularDestination
        heading={heading}
        description={description}
        points={points}
        imageCollactions={imageCollactions}
      /> */}
      <WhyBook Options={cruiseOptions} heading={"Cruise"} />
    </section>
  );
}
