import React, { useEffect, useState } from "react";
import VisaCard from "./VisaCard";
import { singaporeflagicon, singapurCard } from "Utils/Images";
import { passportNotFound } from "Utils/Images";
import userResources from "api/userResources";

export default function ToprankPassport() {
  const [value, setValue] = useState("");
  const [passport, setPassport] = useState([]);

  const getVisaRankList = async () => {
    try {
      const res = await userResources.visaRankList();
      setPassport(res.data.data);
    } catch (error) {
      console.error("Error fetching visa rank list:", error);
    }
  };

  useEffect(() => {
    getVisaRankList();
  }, []);

  return (
    <section className="m-auto max-w-7xl">
      <div>
        <div className="flex items-center justify-between">
          <div className="text-lg font-semibold sm:text-4xl">
            <h3>Top Ranked Passports</h3>
          </div>
          <div>
            <input
              type="text"
              className="hidden w-[600px] rounded-lg border border-red-100 bg-white p-2 shadow-lg shadow-red-50 outline-none sm:block"
              placeholder="Search"
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
          </div>
          <div className="font-semibold text-red-500">
            <p className="flex cursor-pointer flex-nowrap items-center justify-center sm:text-xl">
              View all <span className="ml-1 hidden sm:block">Passports</span>
            </p>
          </div>
        </div>
        <div>
          <input
            type="text"
            className="mt-4 block w-full rounded-md border border-red-100 bg-white p-2 px-2 shadow-md shadow-red-50 outline-none sm:hidden"
            placeholder="Search"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </div>
      </div>

      <div className="scroll flex w-full snap-x snap-mandatory flex-nowrap items-center justify-start gap-4 overflow-x-scroll py-5 sm:py-10">
        {passport.length > 0 ? (
          passport.map((item, index) => (
            <div key={index}>
              <VisaCard
                image={item.image}
                heading={item.heading}
                flag={item.flag}
                rank={item.rank}
              />
            </div>
          ))
        ) : (
          <div className=" flex h-44 w-full items-center justify-start overflow-hidden rounded-3xl sm:h-80">
            {/* <p>passportNotFound.</p> */}
            <img src={passportNotFound} alt="Passport Not Found" />
          </div>
        )}
      </div>
    </section>
  );
}
