import React, { useState } from "react";
import SearchItemRow from "../SearchItemRow/SearchItemRow";
import ExclusiveDeals from "components/ExclusiveDeals/ExclusiveDeals";
import {
  Carousel1,
  ExclusiveDealicon,
  ExpertGuidance,
  Hotelicon,
  SecureBooking,
  airport,
  beatch,
  dinnerOffer,
  holiOffer,
  holiday1,
  holiday2,
  holiday3,
  holiday4,
  innerHolioffer,
  tour,
} from "../../../Utils/Images";
import Background from "components/background/Background";
import PopularDestination from "components/PopularDestination/PopularDestination";
import Select from "react-tailwindcss-select";
import Datepicker from "react-tailwindcss-datepicker";
import { FaCalendarAlt } from "react-icons/fa";
import options from "../Hotel/City";
import WhyBook from "components/WhyBook/WhyBook";
import HolidayForPhone from "components/HolidayForPhone/HolidayForPhone";
import { useSelector } from "react-redux";

export default function Holidays() {
  const offers = useSelector((state) => state?.offers?.offers);

  const [airpotsFrom, setAirpotsFrom] = useState(null);
  const [depart, setDepart] = useState({
    startDate: new Date(),
    endDate: new Date().setMonth(11),
  });

  const From = (value) => {
    setAirpotsFrom(value);
  };

  const departFun = (newValue) => {
    setDepart(newValue);
  };

  const onSubmit = () => {
    const data = {
      airports_from: airpotsFrom,
      departure_date: depart,
      roundTrip: true,
    };
    console.log(data);
  };

  const holidayOptions = [
    {
      icon: ExclusiveDealicon,
      title: "Exclusive Deals",
      description:
        "Get access to exclusive deals on holiday packages. Save money on flights, accommodation, and activities with our special offers.",
      shortDis: "Exclusive deals on holiday packages. Save money.",
    },
    {
      icon: ExpertGuidance,
      title: "Expert Guidance",
      description:
        "Our travel experts are here to help you plan the perfect holiday. Get expert advice and personalized recommendations for your trip.",
      shortDis: "Expert guidance. Personalized recommendations for your trip.",
    },
    {
      icon: SecureBooking,
      title: "Hassle-Free Booking",
      description:
        "Book your holiday package with ease. Our user-friendly platform makes it simple to find, compare, and book your dream holiday.",
      shortDis: "Hassle-free booking. User-friendly platform.",
    },
    {
      icon: tour,
      title: "Tailor-Made Packages",
      description:
        "Create your dream holiday with our tailor-made packages. Customize every aspect of your trip to suit your preferences and budget.",
      shortDis: "Customize your dream holiday. Tailor-made packages.",
    },
  ];

  return (
    <section className="text-gray-800">
      <Background bg={beatch} hidden="hidden" block="block" />
      <div className="absolute top-72 left-1/2 hidden  w-5/6 -translate-x-1/2 transform rounded-lg bg-white text-gray-900 sm:block ">
        <SearchItemRow />
        <div className="my-12 px-5">
          <h2 className="mb-4 text-center text-xl">
            Choose your dream destination and plan your perfect holiday!
          </h2>
          <form onSubmit={onSubmit} className=" hidden sm:block">
            <div className="flex flex-wrap justify-start rounded-md border border-gray-700 py-5">
              <div className="flex w-1/2 flex-col border-r border-gray-700 px-3">
                <label className="text-md whitespace-nowrap">
                  Plan your holidays according to your desired location
                </label>
                <Select
                  value={airpotsFrom}
                  onChange={From}
                  options={options}
                  isSearchable={true}
                  placeholder="Toronto"
                  loading={false}
                  classNames={{
                    menuButton: ({ isDisabled }) =>
                      `flex w-full cursor-pointer relative -left-3 border-0 text-lg overflow-hidden text-gray-900 transition-all outline-none duration-300 focus:outline-none cursor-pointer ${
                        isDisabled
                          ? "bg-gray-200 "
                          : "bg-white hover:border-gray-400 cursor-pointer"
                      }`,
                    menu: "absolute -top-80 z-10 w-full bg-white shadow-lg border rounded py-1 mt-1.5 text-sm text-gray-700",
                    listItem: ({ isSelected }) =>
                      `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${
                        isSelected
                          ? `text-white bg-blue-500`
                          : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                      }`,
                  }}
                  formatGroupLabel={(data) => (
                    <div
                      className={`flex items-center justify-between py-2 text-xs`}
                    >
                      <span className="font-bold">{data.label}</span>
                      <span className="flex  h-5 items-center justify-center rounded-full bg-gray-200 p-1.5">
                        👉 Citys: {data.options.length}
                      </span>
                    </div>
                  )}
                  formatOptionLabel={(data) => (
                    <li
                      className={`mb-1 block cursor-pointer select-none truncate rounded px-2 py-2 transition duration-200 ${
                        !data.isSelected
                          ? `bg-gray-200 text-gray-900`
                          : `bg-blue-800 text-gray-200`
                      }`}
                    >
                      {data.label}
                    </li>
                  )}
                />
              </div>

              <div className=" w-1/2  border-gray-700 px-3">
                <div className="flex items-center gap-2 ">
                  <FaCalendarAlt />
                  <label className="text-md">STARTING ON</label>
                </div>
                <Datepicker
                  containerClassName=""
                  inputClassName="p-0 pt-2 w-1/2 bg-white outline-0 text-gray-900 z-50 cursor-pointer "
                  toggleClassName="relative w-2/1 float-right  hover:text-red-500"
                  popoverDirection="up"
                  useRange={false}
                  asSingle={true}
                  value={depart}
                  showShortcuts={true}
                  // disabledDates={[
                  //   {
                  //     startDate: "2024-03-04",
                  //     endDate: "2023-02-05",
                  //   },
                  //   {
                  //     startDate: "2024-04-05",
                  //     endDate: "2023-02-12",
                  //   },
                  // ]}
                  onChange={departFun}
                />
              </div>
            </div>

            <div className="absolute bottom-[-35px] flex w-full justify-center">
              <button className="btn-grad" type="submit">
                SUBMIT
              </button>
            </div>
          </form>
        </div>
      </div>
      {/* Mobile size */}
      {/* Mobile size */}
      <div className="block p-5 sm:hidden">
        <HolidayForPhone cityOptions={options} />
      </div>
      <ExclusiveDeals carouselData={offers?.HOLIDAY} />

      {/* <PopularDestination
        heading="Destination"
        discription="The world is dotted with all-inclusive holiday resorts that cater to travelers seeking a hassle-free and luxurious vacation experience. These resorts offer everything from accommodations and meals to entertainment and activities, all included in one convenient package. Among the most visited all-inclusive holiday resorts in the world are:"
        points={[
          {
            name: "Goa, India",
            description:
              "Popular for its beaches and vibrant nightlife, Goa has resorts like Caravela Beach Resort that offer all-inclusive options focusing on relaxation and entertainment.",
          },
          {
            name: "Phuket, Thailand",
            description:
              "Known for its stunning landscapes and vibrant culture, Phuket has all-inclusive resorts like Club Med Phuket, which cater to families and offer extensive activity programs.",
          },

          {
            name: "Hawaii, USA",
            description:
              "Known for its beautiful islands, Hawaii offers packages that include meals and activities at places like Travaasa Hana in Maui, offering a more inclusive approach to experiencing the island. ",
          },
          {
            name: "Montego Bay, Jamaica",
            description:
              "Offers a tropical getaway with resorts like Secrets St. James and Sandals Montego Bay, providing comprehensive all-inclusive packages including water sports and fine dining.",
          },
        ]}
        imageCollactions={{
          img1: holiday1,
          img2: holiday2,
          img3: holiday4,
          img4: holiday3,
        }}
      /> */}
      <WhyBook Options={holidayOptions} heading={"Holidays Package"} />
    </section>
  );
}
