/* eslint-disable */

import { HiX } from "react-icons/hi";
import Links from "./components/Links";
import { logout } from "../../redux/userSlice";
import routes from "routes.js";
import { logo } from "../../Utils/Images";
import { MdLock } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import userResources from "api/userResources";
import RoutesDropdown from "components/RoutesDropdown/RoutesDropdown";
import { FaCcVisa } from "react-icons/fa";
const Sidebar = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const filteredRoutes = routes.filter((route) => route.show);

  return (
    <div
      className={`sm:none duration-175 linear fixed !z-50 flex min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${
        open ? "translate-x-0" : "-translate-x-96"
      }`}
    >
      <span
        className="absolute top-4 right-4 block cursor-pointer xl:hidden"
        onClick={onClose}
      >
        <HiX />
      </span>

      <div className={`mx-[56px] mt-[30px] flex items-center`}>
        <div className="mt-1 ml-1 h-2.5 font-poppins text-[26px] font-bold uppercase text-navy-700 dark:text-white">
          <img src={logo} alt="" className="w-48" />
        </div>
      </div>
      <div className="mt-[58px] mb-7 h-px bg-gray-300 dark:bg-white/30" />
      {/* Nav item */}

      <ul className="mb-auto pt-1">
        <Links routes={filteredRoutes} />

        {/* User Management Dropdown */}
        <li className="mx-8 my-4 flex cursor-pointer items-center">
          <MdLock className="mr-4 h-6 w-6" />
          <RoutesDropdown
            options={[
              { value: "add-user", label: "Add User", route: "/auth/add-user" },
              {
                value: "user-list",
                label: "User List",
                route: "/admin/user-list",
              },
              {
                value: "employee-list",
                label: "Employee List",
                route: "/admin/employee-list",
              },
            ]}
            name={"User Management"}
          />
        </li>
        <li className="mx-8 my-4 flex cursor-pointer items-center">
          <FaCcVisa className="mr-4 h-6 w-6" />
          <RoutesDropdown
            options={[
              {
                value: "visa-rank",
                label: "Add Visa Rank",
                route: "/admin/visa-rank",
              },
            ]}
            name={"Visa Management"}
          />
        </li>
      </ul>
      <li
        onClick={() => {
          (async () => {
            try {
              const res = await userResources.logout();
              if (res.status) {
                navigate("/");
                dispatch(logout());
              }
              toast.success(res.message);
            } catch (error) {
              toast.error("Logout failed:");
              // Handle Logout error
            }
          })();
        }}
        className="mx-8 flex cursor-pointer"
      >
        <MdLock className="mr-4 h-6 w-6 text-gray-900 dark:text-white" />
        <p className="font-semibold text-gray-500 hover:text-gray-900 dark:hover:text-white">
          Logout
        </p>
      </li>

      {/* Nav item end */}
    </div>
  );
};

export default Sidebar;
