import React, { useState } from "react";
import Datepicker from "react-tailwindcss-datepicker";
import Select from "react-tailwindcss-select";
import { FaCalendarAlt } from "react-icons/fa";
import OneWayForPhone from "components/OneWayForPhone/OneWayForPhone";

const options = [
  {
    label: "India",
    options: [
      {
        value: "Indira Gandhi International Airport (New Delhi)",
        label: "Indira Gandhi International Airport (New Delhi)",
      },
      {
        value: "Chhatrapati Shivaji International Airport (Mumbai)",
        label: "Chhatrapati Shivaji International Airport (Mumbai)",
      },
      {
        value: "Kempegowda International Airport (Bengaluru)",
        label: "Kempegowda International Airport (Bengaluru)",
      },
      {
        value: "Rajiv Gandhi International Airport (Hyderabad)",
        label: "Rajiv Gandhi International Airport (Hyderabad)",
      },
      {
        value: "Chennai International Airport (Chennai)",
        label: "Chennai International Airport (Chennai)",
      },
      {
        value: "Netaji Subhas Chandra Bose International Airport (Kolkata)",
        label: "Netaji Subhas Chandra Bose International Airport (Kolkata)",
      },
      {
        value: "Sardar Vallabhbhai Patel International Airport (Ahmedabad)",
        label: "Sardar Vallabhbhai Patel International Airport (Ahmedabad)",
      },
      {
        value: "Cochin International Airport (Kochi)",
        label: "Cochin International Airport (Kochi)",
      },
      {
        value: "Dabolim Airport (Goa)",
        label: "Dabolim Airport (Goa)",
      },
      {
        value: "Pune Airport (Pune)",
        label: "Pune Airport (Pune)",
      },
    ],
  },
  {
    label: "🍁 Canada",
    options: [
      {
        value: "Calgary International Airport",
        label: "Calgary International Airport",
      },
      {
        value: "Edmonton International Airport",
        label: "Edmonton International Airport",
      },
      {
        value: "Fredericton International Airport",
        label: "Fredericton International Airport",
      },
      {
        value: "Gander International Airport",
        label: "Gander International Airport",
      },
      {
        value: "Halifax Stanfield International Airport",
        label: "Halifax Stanfield International Airport",
      },
      {
        value: "Greater Moncton Roméo LeBlanc International Airport",
        label: "Greater Moncton Roméo LeBlanc International Airport",
      },
      {
        value: "Montréal–Trudeau International Airport",
        label: "Montréal–Trudeau International Airport",
      },
      {
        value: "Ottawa Macdonald–Cartier International Airport",
        label: "Ottawa Macdonald–Cartier International Airport",
      },
      {
        value: "Québec/Jean Lesage International Airport",
        label: "Québec/Jean Lesage International Airport",
      },
      {
        value: "St. John's International Airport",
        label: "St. John's International Airport",
      },
      {
        value: "Toronto Pearson International Airport",
        label: "Toronto Pearson International Airport",
      },
      {
        value: "Vancouver International Airport",
        label: "Vancouver International Airport",
      },
      {
        value: "Winnipeg International Airport",
        label: "Winnipeg International Airport",
      },
    ],
  },
  {
    label: "America",
    options: [{ value: "America", label: "America" }],
  },
];

export default function OneWay() {
  const [airpotsFrom, setAirpotsFrom] = useState(null);
  const [airpotsTo, setAirpotsTo] = useState(null);
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(2);
  const [infants, setInfants] = useState(0);
  const [selectedFlightType, setSelectedFlightType] = useState("Economy");
  const [depart, setDepart] = useState({
    startDate: new Date(),
    endDate: new Date().setMonth(11),
  });
  const [returnFlight, setReturnFlight] = useState({
    startDate: new Date(),
    endDate: new Date().setMonth(11),
  });
  const handleFlightTypeChange = (event) => {
    setSelectedFlightType(event.target.value);
    console.log("Selected flight type:", event.target.value);
  };
  const From = (value) => {
    setAirpotsFrom(value);
  };

  const To = (value) => {
    setAirpotsTo(value);
  };

  const departFun = (newValue) => {
    setDepart(newValue);
  };

  const setReturnFlightFun = (newValue) => {
    setReturnFlight(newValue);
  };

  const onSubmit = () => {
    const data = {
      airports_from: airpotsFrom,
      airports_to: airpotsTo,
      departure_date: depart,
      return_date: returnFlight,
      oneway: true,
      travelers: {
        adults: parseInt(adults),
        children: parseInt(children),
        infants: parseInt(infants),
      },
    };
    console.log(data);
  };
  return (
    <section className="mb-0 max-w-full px-5 py-2 sm:mb-5 ">
      <form onSubmit={onSubmit} className="hidden sm:block">
        <div className="flex flex-wrap rounded-md border border-gray-700 py-5">
          <div className="flex w-1/5 flex-col border-r border-gray-700 px-3">
            <label className="text-md ">FROM</label>
            <Select
              value={airpotsFrom}
              onChange={From}
              options={options}
              isSearchable={true}
              placeholder="Toronto"
              loading={false}
              classNames={{
                menuButton: ({ isDisabled }) =>
                  `flex  w-full cursor-pointer relative -left-3 border-0 text-lg overflow-hidden text-gray-900 transition-all outline-none duration-300 focus:outline-none cursor-pointer ${
                    isDisabled
                      ? "bg-gray-200 "
                      : "bg-white hover:border-gray-400 cursor-pointer"
                  }`,
                menu: "absolute -top-80 z-10 w-full bg-white shadow-lg border rounded py-1 mt-1.5 text-sm text-gray-700",
                listItem: ({ isSelected }) =>
                  `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${
                    isSelected
                      ? `text-white bg-blue-500`
                      : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                  }`,
              }}
              formatGroupLabel={(data) => (
                <div
                  className={`flex items-center justify-between py-2 text-xs`}
                >
                  <span className="font-bold">{data.label}</span>
                  <span className="flex  h-5 items-center justify-center rounded-full bg-gray-200 p-1.5">
                    {data.options.length}
                    👉 Airpots
                  </span>
                </div>
              )}
              formatOptionLabel={(data) => (
                <li
                  className={`mb-1 block cursor-pointer select-none truncate rounded px-2 py-2 transition duration-200 ${
                    !data.isSelected
                      ? `bg-gray-200 text-gray-900`
                      : `bg-blue-800 text-gray-200`
                  }`}
                >
                  {data.label}
                </li>
              )}
            />
          </div>
          <div className="flex w-1/5 flex-col border-r border-gray-700 px-3">
            <label className="text-md ">To</label>

            <Select
              value={airpotsTo}
              onChange={To}
              options={options}
              isSearchable={true}
              placeholder="Delhi"
              loading={false}
              classNames={{
                menuButton: ({ isDisabled }) =>
                  `flex  w-full cursor-pointer  relative -left-3 border-0 text-lg overflow-hidden text-gray-900 transition-all outline-none duration-300 focus:outline-none cursor-pointer ${
                    isDisabled
                      ? "bg-gray-200"
                      : "bg-white hover:border-gray-400"
                  }`,
                menu: "absolute -top-80 z-10 w-full bg-white shadow-lg border rounded py-1 mt-1.5 text-sm text-gray-700",
                listItem: ({ isSelected }) =>
                  `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${
                    isSelected
                      ? `text-white bg-blue-500`
                      : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                  }`,
              }}
              formatGroupLabel={(data) => (
                <div
                  className={`flex items-center justify-between py-2 text-xs`}
                >
                  <span className="font-bold">{data.label}</span>
                  <span className="flex  h-5 items-center justify-center rounded-full bg-gray-200 p-1.5">
                    {data.options.length}
                    👉 Airpots
                  </span>
                </div>
              )}
              formatOptionLabel={(data) => (
                <li
                  className={`mb-1 block cursor-pointer select-none truncate rounded px-2 py-2 transition duration-200 ${
                    !data.isSelected
                      ? `bg-gray-200 text-gray-900`
                      : `bg-blue-800 text-gray-200`
                  }`}
                >
                  {/* // data represents each option in the list */}
                  {data.label}
                </li>
              )}
            />
          </div>

          <div className="w-1/5 border-r border-gray-700 px-3">
            <div className="flex items-center gap-2">
              <FaCalendarAlt />
              <label className="text-md">DEPART</label>
            </div>
            <Datepicker
              containerClassName=""
              inputClassName="p-0 pt-2 w-1/2  bg-white outline-0 text-gray-900 z-50 cursor-pointer "
              toggleClassName="relative w-2/1 float-right hover:text-red-500 "
              popoverDirection="up"
              useRange={false}
              asSingle={true}
              value={depart}
              showShortcuts={true}
              // disabledDates={[
              //   {
              //     startDate: "2024-03-04",
              //     endDate: "2023-02-05",
              //   },
              //   {
              //     startDate: "2024-04-05",
              //     endDate: "2023-02-12",
              //   },
              // ]}
              onChange={departFun}
            />
          </div>
          <div className="w-1/5 cursor-not-allowed border-r border-gray-700 px-3 text-gray-400">
            <div className="flex cursor-not-allowed items-center gap-2">
              <FaCalendarAlt />
              <label className="text-md cursor-not-allowed">RETURN</label>
            </div>
            <Datepicker
              containerClassName=""
              inputClassName="p-0 pt-2 w-1/2 bg-white outline-0 text-gray-200 z-50 cursor-not-allowed"
              toggleClassName="relative w-2/1 float-right cursor-not-allowed"
              popoverDirection="up"
              useRange={false}
              asSingle={true}
              // value={returnFlight}
              showShortcuts={true}
              disabled={true}
              // disabledDates={[
              //   {
              //     startDate: "2024-03-04",
              //     endDate: "2023-02-05",
              //   },
              //   {
              //     startDate: "2024-04-05",
              //     endDate: "2023-02-12",
              //   },
              // ]}
              onChange={setReturnFlightFun}
            />
          </div>
          <div className="w-1/5  px-3">
            <div className="flex items-center gap-2">
              <FaCalendarAlt />
              <label className="text-md">TRAVELLERS</label>
            </div>
            <div className=" dropdown w-full">
              <div tabIndex={0} role="button" className="cursor-pointer">
                <h3 className="text-lg">
                  {adults + children + infants} Travellers
                </h3>
                <p className="text-sm">{selectedFlightType}</p>
              </div>
              <div
                tabIndex={0}
                className="menu dropdown-content z-[1] w-52 rounded-box bg-white p-2 shadow"
              >
                <div className="cursor-pointer rounded-md border-b p-2 hover:bg-gray-100">
                  <div className="flex items-center justify-between">
                    <h4>Adults</h4>
                    <h4>{adults}</h4>
                    <div className="flex items-center rounded-md border">
                      <div
                        className="cursor-pointer border border-r-0 p-1 px-3 hover:bg-red-500 hover:text-white"
                        onClick={() => {
                          if (adults > 1) {
                            setAdults(adults - 1);
                          }
                        }}
                      >
                        -
                      </div>
                      <div
                        className="border-s-0 cursor-pointer border p-1 px-3 hover:bg-red-500 hover:text-white"
                        onClick={() => setAdults(adults + 1)}
                      >
                        +
                      </div>
                    </div>
                  </div>
                </div>
                <div className="cursor-pointer rounded-md border-b p-2 hover:bg-gray-100">
                  <div className="flex items-center justify-between">
                    <div>
                      <h4>Children</h4>
                      <h6 className=" text-gray-400">2-12 Years</h6>
                    </div>
                    <h4>{children}</h4>
                    <div className="flex items-center rounded-md border">
                      <div
                        className="cursor-pointer border border-r-0 p-1 px-3 hover:bg-red-500 hover:text-white"
                        onClick={() => {
                          if (children > 0) {
                            setChildren(children - 1);
                          }
                        }}
                      >
                        -
                      </div>
                      <div
                        className="border-s-0 cursor-pointer border p-1 px-3 hover:bg-red-500 hover:text-white"
                        onClick={() => setChildren(children + 1)}
                      >
                        +
                      </div>
                    </div>
                  </div>
                </div>
                <div className="cursor-pointer rounded-md border-b p-2 hover:bg-gray-100">
                  <div className="flex items-center justify-between">
                    <div>
                      <h4>Infants</h4>
                      <h6 className=" text-gray-400">0-23 Months</h6>
                    </div>
                    <h4>{infants}</h4>
                    <div className="flex items-center rounded-md border">
                      <div
                        className="cursor-pointer border border-r-0 p-1 px-3 hover:bg-red-500 hover:text-white"
                        onClick={() => {
                          if (infants > 0) {
                            setInfants(infants - 1);
                          }
                        }}
                      >
                        -
                      </div>
                      <div
                        className="border-s-0 cursor-pointer border p-1 px-3 hover:bg-red-500 hover:text-white"
                        onClick={() => setInfants(infants + 1)}
                      >
                        +
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="flex cursor-pointer items-center gap-2 rounded-md py-1 px-1 hover:bg-gray-100">
                    <input
                      type="radio"
                      id="economy"
                      name="flightType"
                      value="Economy"
                      defaultChecked
                      onChange={handleFlightTypeChange}
                    />
                    <label htmlFor="economy" className="cursor-pointer">
                      Economy
                    </label>
                  </div>
                  <div className="flex cursor-pointer items-center gap-2 rounded-md py-1 px-1 hover:bg-gray-100">
                    <input
                      type="radio"
                      id="premiumEconomy"
                      name="flightType"
                      value="Premium Economy"
                      onChange={handleFlightTypeChange}
                    />
                    <label htmlFor="premiumEconomy" className="cursor-pointer">
                      Premium Economy
                    </label>
                  </div>
                  <div className="flex cursor-pointer items-center gap-2 rounded-md py-1 px-1 hover:bg-gray-100">
                    <input
                      type="radio"
                      id="business"
                      name="flightType"
                      value="Business"
                      onChange={handleFlightTypeChange}
                    />
                    <label htmlFor="business" className="cursor-pointer">
                      Business
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <input
            className="mr-2 mt-2 cursor-pointer"
            type="checkbox"
            id="directFlight"
            name="directFlight"
            checked
          />
          <label htmlFor="directFlight" className="cursor-pointer">
            Direct Flight
          </label>
        </div>

        <div className="absolute bottom-[-35px] flex w-full justify-center">
          <button className="btn-grad" type="submit">
            SUBMIT
          </button>
        </div>
      </form>
      <div className="block sm:hidden">
        <OneWayForPhone options={options} />
      </div>
    </section>
  );
}
