import { useEffect, useMemo, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import userResources from "api/userResources";
import Loader from "components/Loader/Loader";

export default function SubscribersList() {
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const columns = useMemo(
    () => [
      {
        accessorKey: "email",
        header: "Email",
        size: 250,
      },
      {
        accessorKey: "status",
        header: "Status",
        size: 100,
        Cell: ({ cell }) => (cell.getValue() === 1 ? "Active" : "Inactive"),
      },
      {
        accessorKey: "created_at",
        header: "Created At",
        size: 200,
        Cell: ({ cell }) => new Date(cell.getValue()).toLocaleString(),
      },
    ],
    []
  );

  const getsubscribeList = async (pageIndex, pageSize, globalFilter) => {
    setIsLoading(true); // Set loading state to true when fetching starts
    try {
      const response = await userResources.subscribeList(
        pageIndex || 0,
        pageSize || 5,
        globalFilter || ""
      );
      setData(response.data.data);
      setTotalRows(response.data.paginate_data.total); // Set total rows for pagination
    } catch (error) {
      console.error("Error fetching subscribers:", error);
    } finally {
      setIsLoading(false); // Set loading state to false when fetching ends
    }
  };

  const handleGlobalFilterChange = (value) => {
    setGlobalFilter(value); // Update the global filter state with the new value
  };

  // Calculate total pages
  // const totalPages = Math.ceil(airlinesData.length / pagination.pageSize);

  useEffect(() => {
    getsubscribeList();
  }, []);

  useEffect(() => {
    getsubscribeList(pagination.pageIndex, pagination.pageSize, globalFilter);
  }, [pagination, globalFilter]);

  const handlePaginationChange = (updatedPagination) => {
    setPagination(updatedPagination);
  };

  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={data}
        state={{ pagination, isLoading }} // Use both states here
        // state={{ pagination, isLoading, showProgressBars: isLoading }} // Use both states here
        enablePagination
        onPaginationChange={handlePaginationChange}
        manualPagination
        rowCount={totalRows}
        pageCount={Math.ceil(totalRows / pagination.pageSize)}
        isLoading={isLoading}
        enableColumnFilters={false}
        enableSorting={false}
        enableColumnOrdering={false}
        muiTablePaginationProps={{
          showFirstButton: true,
          showLastButton: true,
        }}
        enableGlobalFilter // Keep this true to allow global filtering
        onGlobalFilterChange={handleGlobalFilterChange} // Update the state on filter change
        globalFilter={globalFilter} // Pass the state variable to the table
      />
    </>
  );
}
