import ScrollToTopOnRouteChange from "components/ScrollToTopOnRouteChange/ScrollToTopOnRouteChange";
import UserNavbar from "components/UserNavbar/UserNavbar";
import React from "react";

export default function Travelwithpets() {
  const tips = [
    {
      title: "Visit to the Veterinarian",
      points: [
        "Schedule an appointment with your veterinarian well in advance of your planned travel date.",
        "Discuss your intention to travel internationally with your pet and inquire about any specific health considerations for the destination country.",
        "Ensure your pet is up-to-date on all required vaccinations, including rabies, and obtain a health certificate from your veterinarian.",
        "Discuss microchipping options with your vet if required by the destination country.",
        "Inquire about any quarantine requirements or import permits necessary for traveling with your pet to the destination country.",
      ],
    },
    {
      title: "Research on Flying with a Pet",
      points: [
        "Research the pet travel policies of airlines that operate flights to your destination.",
        "Understand the specific requirements and restrictions imposed by each airline regarding pet travel, including documentation, carrier specifications, and fees.",
        "Consider factors such as the length of the flight, layovers, and temperature restrictions, especially if your pet will travel in the cargo hold.",
        "Check whether the airline has restrictions on specific breeds or types of pets and whether your pet meets the airline's criteria for travel.",
      ],
    },
    {
      title: "Airline Restrictions",
      points: [
        "Different airlines have varying policies and restrictions regarding pet travel.",
        "Some airlines may have specific requirements for pet carriers, including size, material, and ventilation.",
        "Be aware of any additional fees associated with pet travel, such as pet transport fees or excess baggage charges.",
        "Research airlines with established pet travel programs and safety records to minimize the risk associated with pet travel.",
      ],
    },
    {
      title: "Pet Comfort",
      points: [
        "Choose a suitable pet carrier that provides enough space for your pet to stand, turn around, and lie down comfortably.",
        "Familiarize your pet with the carrier in advance of the trip to reduce stress and anxiety.",
        "Pack familiar items such as blankets, toys, and treats to comfort your pet during travel.",
        "Provide access to food, water, and bathroom breaks as needed, especially during long flights or layovers.",
      ],
    },
    {
      title: "Cruise Ships and Travel by Sea",
      points: [
        "If traveling by sea, research the pet policies of the cruise line or ferry service you plan to use.",
        "Check whether pets are allowed onboard and whether designated pet-friendly accommodations are available.",
        "Ensure compliance with any documentation or vaccination requirements for pets traveling by sea, and inquire about any restrictions on pet access to specific areas of the ship.",
      ],
    },
    {
      title: "High-Risk Companies and Illness/Death of Pet",
      points: [
        "Research and choose reputable transportation companies with established pet travel programs and safety records.",
        "Consider factors such as the company's experience with pet travel, safety protocols, and customer reviews.",
        "Be aware of the potential risks associated with pet travel, including stress, injury, illness, or death, and take necessary precautions to minimize these risks.",
        "Have a contingency plan in case of emergencies or unexpected events during travel, including access to veterinary care or pet insurance coverage.",
      ],
    },
    {
      title: "Emergency Preparedness",
      points: [
        "Carry a pet first aid kit with essential supplies such as medications, bandages, and emergency contact information.",
        "Research veterinary clinics or emergency services at your destination in case your pet requires medical attention.",
        "Keep copies of your pet's medical records, vaccination certificates, and identification tags with you during travel.",
        "Consider purchasing pet travel insurance to provide coverage for unexpected expenses related to your pet's health or well-being.",
      ],
    },
  ];

  return (
    <section className="text-gray-800">
      <UserNavbar />
      <ScrollToTopOnRouteChange />

      <div className="m-auto my-5 max-w-8xl px-2">
        <h2 className="mb-5  text-2xl">
          Here are a few tips to prepare for traveling with your pets
        </h2>
        <ol className="list-decimal pl-5">
          {tips.map((tip, index) => (
            <li key={index}>
              <strong>{tip.title}</strong>
              <ul className="list-disc pl-1.5">
                {tip.points.map((point, index) => (
                  <li key={index}>{point}</li>
                ))}
              </ul>
            </li>
          ))}
        </ol>
      </div>
    </section>
  );
}
