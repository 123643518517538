import React, { useState } from "react";
import Datepicker from "react-tailwindcss-datepicker";
import Select from "react-tailwindcss-select";
import { FaCalendarAlt } from "react-icons/fa";
import SearchItemRow from "../SearchItemRow/SearchItemRow";
import {
  Carousel1,
  beatch,
  goa_hotel,
  holiOffer,
  Hotelicon,
  hotel_primary,
  innerHolioffer,
  royal_hotel,
  villa,
  SecureBooking,
  customer_service,
  BestPriceGuarantee,
  dinnerOffer,
} from "../../../Utils/Images";
import Background from "../../../components/background/Background";

import ExclusiveDeals from "components/ExclusiveDeals/ExclusiveDeals";
import PopularDestination from "components/PopularDestination/PopularDestination";
import HotelForPhone from "components/HotelForPhone/HotelForPhone";
import options from "./City";
import WhyBook from "components/WhyBook/WhyBook";
import { useSelector } from "react-redux";
export default function Hotel() {
  const offers = useSelector((state) => state?.offers?.offers);
  const [airpotsFrom, setAirpotsFrom] = useState(null);
  const [Room, setRoom] = useState(1);
  const [Guest, setGuest] = useState(2);
  const [depart, setDepart] = useState({
    startDate: new Date(),
    endDate: new Date().setMonth(11),
  });
  const [returnFlight, setReturnFlight] = useState({
    startDate: new Date(),
    endDate: new Date().setMonth(11),
  });

  const From = (value) => {
    setAirpotsFrom(value);
  };

  const departFun = (newValue) => {
    setDepart(newValue);
  };

  const setReturnFlightFun = (newValue) => {
    setReturnFlight(newValue);
  };

  const onSubmit = () => {
    const data = {
      airports_from: airpotsFrom,
      departure_date: depart,
      return_date: returnFlight,
      roundTrip: true,
      travelers: {
        Room: parseInt(Room),
        Guest: parseInt(Guest),
      },
    };
    console.log(data);
  };
  const carouselData = [
    {
      id: "1",
      image: dinnerOffer,
      heading: "For Canadian travellers ",
      paragraph:
        "Free dinner on us at the following for the passenger traveling when you buy International Air Tickets from us before 25th February 2025. ",
      long_Paragraph:
        "Attention Canadian travelers! Maharaja Flights Limited has an exciting offer for you! Purchase your International Air Tickets from us before February 25, 2025, and enjoy a complimentary dinner on us at select restaurants. Whether you're in Toronto, Edmonton, or Vancouver, you can indulge in a delicious meal with your family. In Toronto, savor the flavors of Punjabi By Nature or Curry Bistro in Mississauga and Brampton. In Edmonton, visit Namaste India Restaurant across all three locations. And in Vancouver, treat yourself at Bombay Kitchen on Denman. Just remember, this offer is valid on weekdays only, excluding Fridays, Saturdays, and Sundays, and make sure to present our coupons before placing your order. Don't miss out on this delightful dining experience!",
      deal: {
        name: "Flight",
      },
      coupon: {
        name: "CANADADINNER15",
        expiry_date: "2024-10-31",
      },

      inner_image: dinnerOffer,

      terms: [
        { name: "Must be used before 25th February 2025." },
        { name: "Weekdays only (not valid Friday, Saturday, and Sunday)" },
        { name: "Please present our coupons before your order" },
      ],
    },
  ];
  // Array containing information for each hotel option
  const hotelOptions = [
    {
      icon: BestPriceGuarantee,
      title: "Best Price Guarantee",
      description:
        "Find the best deals on hotels with our best price guarantee. If you find a better price elsewhere, we'll refund the difference.",
      shortDis: "Best deals. We'll refund if better price found.",
    },
    {
      icon: Hotelicon,
      title: "Wide Selection",
      description:
        "Choose from thousands of hotels worldwide. From budget hostels to luxury resorts, we have the perfect accommodation for every traveler.",
      shortDis: "Thousands of hotels worldwide. Budget to luxury.",
    },
    {
      icon: customer_service,
      title: "24/7 Customer Support",
      description:
        "Need help? Our customer support team is available 24/7 to assist you with any questions or concerns you may have before, during, or after your stay.",
      shortDis: "24/7 customer support. Assistance anytime, anywhere.",
    },
    {
      icon: SecureBooking,
      title: "Secure Booking",
      description:
        "Book your hotel with confidence. We use the latest encryption technology to ensure your personal and payment information is kept secure.",
      shortDis: "Book hotels securely. Your data is safe.",
    },
  ];

  return (
    <section className="text-gray-800">
      <Background bg={beatch} hidden="hidden" block="block" />

      <div className="absolute top-72 left-1/2 hidden  w-5/6 -translate-x-1/2 transform rounded-lg bg-white text-gray-900 sm:block">
        <SearchItemRow />
        <div className="my-12 px-5">
          <h2 className=" mb-4 text-center text-xl">
            Book Domestic and International Hotels
          </h2>
          <form onSubmit={onSubmit} className="hidden sm:block">
            <div className="flex flex-wrap justify-start rounded-md border border-gray-700 py-5">
              <div className="flex w-1/4 flex-col border-r border-gray-700 px-3">
                <label className="text-md whitespace-nowrap">
                  ENTER YOUR DESTINATION OR PROPERTY
                </label>
                <Select
                  value={airpotsFrom}
                  onChange={From}
                  options={options}
                  isSearchable={true}
                  placeholder="Toronto"
                  loading={false}
                  classNames={{
                    menuButton: ({ isDisabled }) =>
                      `flex w-full cursor-pointer relative -left-3 border-0 text-lg overflow-hidden text-gray-900 transition-all outline-none duration-300 focus:outline-none cursor-pointer ${
                        isDisabled
                          ? "bg-gray-200 "
                          : "bg-white hover:border-gray-400 cursor-pointer"
                      }`,
                    menu: "absolute -top-80 z-10 w-full bg-white shadow-lg border rounded py-1 mt-1.5 text-sm text-gray-700",
                    listItem: ({ isSelected }) =>
                      `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${
                        isSelected
                          ? `text-white bg-blue-500`
                          : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                      }`,
                  }}
                  formatGroupLabel={(data) => (
                    <div
                      className={`flex items-center justify-between py-2 text-xs`}
                    >
                      <span className="font-bold">{data.label}</span>
                      <span className="flex  h-5 items-center justify-center rounded-full bg-gray-200 p-1.5">
                        👉 Citys: {data.options.length}
                      </span>
                    </div>
                  )}
                  formatOptionLabel={(data) => (
                    <li
                      className={`mb-1 block cursor-pointer select-none truncate rounded px-2 py-2 transition duration-200 ${
                        !data.isSelected
                          ? `bg-gray-200 text-gray-900`
                          : `bg-blue-800 text-gray-200`
                      }`}
                    >
                      {data.label}
                    </li>
                  )}
                />
              </div>

              <div className=" w-1/4 border-r border-gray-700 px-3">
                <div className="flex items-center gap-2">
                  <FaCalendarAlt />
                  <label className="text-md">CHECK IN</label>
                </div>
                <Datepicker
                  containerClassName=""
                  inputClassName="p-0 pt-2 w-1/2 bg-white outline-0 text-gray-900 z-50 cursor-pointer "
                  toggleClassName="relative w-2/1 float-right hover:text-red-500 "
                  popoverDirection="up"
                  useRange={false}
                  asSingle={true}
                  value={depart}
                  showShortcuts={true}
                  // disabledDates={[
                  //   {
                  //     startDate: "2024-03-04",
                  //     endDate: "2023-02-05",
                  //   },
                  //   {
                  //     startDate: "2024-04-05",
                  //     endDate: "2023-02-12",
                  //   },
                  // ]}
                  onChange={departFun}
                />
              </div>
              <div className=" w-1/4 border-r border-gray-700 px-3">
                <div className="flex items-center gap-2">
                  <FaCalendarAlt />
                  <label className="text-md">CHECK OUT</label>
                </div>
                <Datepicker
                  containerClassName=""
                  inputClassName="p-0 bg-white pt-2 w-1/2 outline-0 text-gray-900 z-50 cursor-pointer "
                  toggleClassName="relative float-right w/2/1 hover:text-red-500 "
                  popoverDirection="up"
                  useRange={false}
                  asSingle={true}
                  value={returnFlight}
                  showShortcuts={true}
                  // disabledDates={[
                  //   {
                  //     startDate: "2024-03-04",
                  //     endDate: "2023-02-05",
                  //   },
                  //   {
                  //     startDate: "2024-04-05",
                  //     endDate: "2023-02-12",
                  //   },
                  // ]}
                  onChange={setReturnFlightFun}
                />
              </div>
              <div className="w-1/4 px-3">
                <div className="flex items-center gap-2">
                  <FaCalendarAlt />
                  <label className="text-md">ROOMS & GUESTS</label>
                </div>
                <div className=" dropdown w-full ">
                  <div tabIndex={0} role="button" className="cursor-pointer">
                    <h3 className="text-lg">
                      {Room} Room {Guest} Guest
                    </h3>
                  </div>
                  <div
                    tabIndex={0}
                    className="menu dropdown-content z-[1] w-52 rounded-box bg-white p-2 shadow "
                  >
                    <div className="cursor-pointer rounded-md border-b p-2 hover:bg-gray-100">
                      <div className="flex items-center justify-between">
                        <h4>Room</h4>
                        <h4>{Room}</h4>
                        <div className="flex items-center rounded-md border">
                          <div
                            className="cursor-pointer border border-r-0 p-1 px-3 hover:bg-red-500 hover:text-white"
                            onClick={() => {
                              if (Room > 1) {
                                setRoom(Room - 1);
                              }
                            }}
                          >
                            -
                          </div>
                          <div
                            className="border-s-0 cursor-pointer border p-1 px-3 hover:bg-red-500 hover:text-white"
                            onClick={() => setRoom(Room + 1)}
                          >
                            +
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="cursor-pointer rounded-md border-b p-2 hover:bg-gray-100">
                      <div className="flex items-center justify-between">
                        <div>
                          <h4>Guest</h4>
                        </div>
                        <h4>{Guest}</h4>
                        <div className="flex items-center rounded-md border">
                          <div
                            className="cursor-pointer border border-r-0 p-1 px-3 hover:bg-red-500 hover:text-white"
                            onClick={() => {
                              if (Guest > 0) {
                                setGuest(Guest - 1);
                              }
                            }}
                          >
                            -
                          </div>
                          <div
                            className="border-s-0 cursor-pointer border p-1 px-3 hover:bg-red-500 hover:text-white"
                            onClick={() => setGuest(Guest + 1)}
                          >
                            +
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="absolute bottom-[-35px] flex w-full justify-center">
              <button className="btn-grad" type="submit">
                SUBMIT
              </button>
            </div>
          </form>
        </div>
      </div>
      {/* Mobile size */}
      <div className="block p-5 sm:hidden">
        <HotelForPhone options={options} />
      </div>
      <ExclusiveDeals carouselData={offers?.HOTEL} />

      {/* <PopularDestination
        heading="Destination"
        discription="Hotel stays at the most expensive hotels in the world are renowned for their luxurious accommodations, exceptional service, and often unique locations. Prices can vary depending on the suite or package, but what sets these hotels apart is their commitment to providing an unparalleled experience to their guests."
        points={[
          {
            name: "Taj Mahal Palace, Mumbai, India",
            description:
              "Overlooking the Gateway of India with panoramic views of the Arabian Sea, this hotel combines Moorish, Oriental, and Florentine styles. It's noted for its historical significance and luxurious service.",
          },
          {
            name: "Burj Al Arab, Dubai, UAE",
            description:
              "Often noted as one of the most luxurious hotels in the world, the Burj Al Arab is famous for its distinctive sail-shaped silhouette and opulent interiors. It offers private butler service and has its own private beach.",
          },
          {
            name: "The Plaza, New York City, USA",
            description:
              "A historic landmark at the corner of Central Park, The Plaza has long been the symbol of New York luxury. Known for its grandiose suites and impeccable service, it has been a backdrop in numerous films and books.",
          },
          {
            name: "Marina Bay Sands, Singapore",
            description:
              "Known for its impressive architecture and large rooftop Infinity Pool, Marina Bay Sands offers a unique experience. It also includes a casino, museum, and various luxury dining options.",
          },
        ]}
        imageCollactions={{
          img1: hotel_primary,
          img4: royal_hotel,
          img3: goa_hotel,
          img2: villa,
        }}
      /> */}
      <WhyBook Options={hotelOptions} heading={"Hotels"} />
    </section>
  );
}
