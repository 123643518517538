import React from "react";
import "./CreossIcon.scss";
export default function Crossicon({ onClose }) {
  return (
    <div onClick={() => onClose()}>
      <h6 className="cross transform cursor-pointer rounded-full bg-red-500 text-white transition duration-300 ease-in-out hover:bg-white hover:text-red-500">
        X
      </h6>
    </div>
  );
}
