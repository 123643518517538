import React, { useEffect, useState } from "react";
import Select from "react-tailwindcss-select";
import { useForm, useFieldArray } from "react-hook-form";
import Datepicker from "react-tailwindcss-datepicker";
import { FaCalendarAlt } from "react-icons/fa";
import Crossicon from "components/CrossIcon/Crossicon";
import MultiCityForPhone from "components/MultiCityForPhone/MultiCityForPhone";

const options = [
  {
    label: "India",
    options: [
      {
        value: "Indira Gandhi International Airport (New Delhi)",
        label: "Indira Gandhi International Airport (New Delhi)",
      },
      {
        value: "Chhatrapati Shivaji International Airport (Mumbai)",
        label: "Chhatrapati Shivaji International Airport (Mumbai)",
      },
      {
        value: "Kempegowda International Airport (Bengaluru)",
        label: "Kempegowda International Airport (Bengaluru)",
      },
    ],
  },
  {
    label: "🍁 Canada",
    options: [
      {
        value: "Calgary International Airport",
        label: "Calgary International Airport",
      },
      {
        value: "St. John's International Airport",
        label: "St. John's International Airport",
      },
      {
        value: "Toronto Pearson International Airport",
        label: "Toronto Pearson International Airport",
      },
    ],
  },
  {
    label: "America",
    options: [{ value: "America", label: "America" }],
  },
];

export default function MultiCity() {
  const { register, handleSubmit, control, setValue } = useForm();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "flight",
  });
  const [airportsFrom, setAirportsFrom] = useState({});
  const [airportsTo, setAirportsTo] = useState({});
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(2);
  const [infants, setInfants] = useState(0);
  const [selectedFlightType, setSelectedFlightType] = useState("Economy");
  const [depart, setDepart] = useState({
    startDate: new Date(),
    endDate: new Date().setMonth(11),
  });

  const From = (value, index) => {
    setAirportsFrom((prev) => ({
      ...prev,
      [index]: value,
    }));
    // Set the value for "from" field in the form data
    setValue(`flight[${index}].from`, value.value);
  };
  const To = (value, index) => {
    setAirportsTo((prev) => ({
      ...prev,
      [index]: value,
    }));
    // Set the value for "from" field in the form data
    setValue(`flight[${index}].to`, value.value);
  };

  const departFun = (value, index) => {
    setDepart(value);
    setDepart((prev) => ({
      ...prev,
      [index]: value,
    }));
    // Set the value for "from" field in the form data
    setValue(`flight[${index}].depart`, value);
  };

  const handleFlightTypeChange = (event) => {
    setSelectedFlightType(event.target.value);
  };

  const onSubmit = (data) => {
    const finalData = {
      data,
      travelers: {
        adults: parseInt(adults),
        children: parseInt(children),
        infants: parseInt(infants),
      },
    };
    console.log(finalData);
  };

  useEffect(() => {
    append({
      from: "",
      to: "",
      depart: "",
    });
    append({
      from: "",
      to: "",
      depart: "",
    });
  }, [append]);

  return (
    <section className="my-2 max-w-full py-2 sm:my-0 sm:mb-5 ">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="hide-scroll-bar hidden h-72 overflow-scroll px-5 sm:block"
      >
        {fields.map((city, index) => (
          <div
            key={city.id}
            className="mb-5 flex flex-wrap rounded-md border border-gray-700 py-5 "
          >
            <div className="flex w-1/4 flex-col border-r border-gray-700 px-3">
              <label className="text-md">FROM</label>
              <Select
                {...register(`flight.${index}.from`)}
                value={airportsFrom[index]}
                onChange={(value) => From(value, index)}
                options={options}
                isSearchable={true}
                placeholder="Toronto"
                loading={false}
                classNames={{
                  menuButton: ({ isDisabled }) =>
                    `flex gap-5  w-full cursor-pointer relative -left-3 border-0 text-lg overflow-hidden text-gray-900 transition-all outline-none duration-300 focus:outline-none cursor-pointer ${
                      isDisabled
                        ? "bg-gray-200 "
                        : "bg-white hover:border-gray-400 cursor-pointer"
                    }`,
                  menu: "absolute  z-10 w-full bg-white shadow-lg border rounded py-1 mt-1.5 text-sm text-gray-700",
                  listItem: ({ isSelected }) =>
                    `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${
                      isSelected
                        ? `text-white bg-blue-500`
                        : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                    }`,
                }}
                formatGroupLabel={(data) => (
                  <div
                    className={`flex items-center justify-between py-2 text-xs`}
                  >
                    <span className="font-bold">{data.label}</span>
                    <span className="flex  h-5 items-center justify-center rounded-full bg-gray-200 p-1.5">
                      {data.options.length}
                      👉 Airpots
                    </span>
                  </div>
                )}
                formatOptionLabel={(data) => (
                  <li
                    className={`mb-1 block cursor-pointer select-none truncate rounded px-2 py-2 transition duration-200 ${
                      !data.isSelected
                        ? `bg-gray-200 text-gray-900`
                        : `bg-blue-800 text-gray-200`
                    }`}
                  >
                    {data.label}
                  </li>
                )}
              />
            </div>
            <div className="flex w-1/4 flex-col border-r border-gray-700 px-3">
              <label className="text-md">To</label>
              <Select
                {...register(`flight.${index}.to`)}
                value={airportsTo[index]}
                onChange={(value) => To(value, index)}
                options={options}
                isSearchable={true}
                placeholder="Delhi"
                loading={false}
                classNames={{
                  menuButton: ({ isDisabled }) =>
                    `flex  w-full cursor-pointer  relative -left-3 border-0 text-lg overflow-hidden text-gray-900 transition-all outline-none duration-300 focus:outline-none cursor-pointer ${
                      isDisabled
                        ? "bg-gray-200"
                        : "bg-white hover:border-gray-400"
                    }`,
                  menu: "absolute z-10 w-full bg-white shadow-lg border rounded py-1 mt-1.5 text-sm text-gray-700",
                  listItem: ({ isSelected }) =>
                    `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${
                      isSelected
                        ? `text-white bg-blue-500`
                        : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                    }`,
                }}
                formatGroupLabel={(data) => (
                  <div
                    className={`flex items-center justify-between py-2 text-xs`}
                  >
                    <span className="font-bold">{data.label}</span>
                    <span className="flex  h-5 items-center justify-center rounded-full bg-gray-200 p-1.5">
                      {data.options.length}
                      👉 Airpots
                    </span>
                  </div>
                )}
                formatOptionLabel={(data) => (
                  <li
                    className={`mb-1 block cursor-pointer select-none truncate rounded px-2 py-2 transition duration-200 ${
                      !data.isSelected
                        ? `bg-gray-200 text-gray-900`
                        : `bg-blue-800 text-gray-200`
                    }`}
                  >
                    {/* // data represents each option in the list */}
                    {data.label}
                  </li>
                )}
              />
            </div>

            <div className="w-1/4 border-r border-gray-700 px-3">
              <div className="flex items-center gap-2">
                <FaCalendarAlt />
                <label className="text-md">DEPART</label>
              </div>
              <Datepicker
                {...register(`flight.${index}.depart`)}
                containerClassName=""
                inputClassName="p-0 pt-2 w-1/2  bg-white outline-0 text-gray-900 z-50 cursor-pointer "
                toggleClassName="relative w-2/1 float-right hover:text-red-500 "
                popoverDirection="down"
                useRange={false}
                asSingle={true}
                value={depart[index]}
                showShortcuts={true}
                // disabledDates={[
                //   {
                //     startDate: "2024-03-04",
                //     endDate: "2023-02-05",
                //   },
                //   {
                //     startDate: "2024-04-05",
                //     endDate: "2023-02-12",
                //   },
                // ]}
                onChange={(value) => departFun(value, index)}
              />
            </div>

            <div className="flex w-1/4 justify-between px-3">
              {index === 0 && (
                <div className=" px-3">
                  <div className="flex items-center gap-2">
                    <FaCalendarAlt />
                    <label className="text-md">TRAVELLERS</label>
                  </div>
                  <div className=" dropdown w-full">
                    <div tabIndex={0} role="button" className="cursor-pointer">
                      <h3 className="text-lg">
                        {adults + children + infants} Travellers
                      </h3>
                      <p className="text-sm">{selectedFlightType}</p>
                    </div>
                    <div
                      tabIndex={0}
                      className="menu dropdown-content z-[1] w-52 rounded-box bg-white p-2 shadow"
                    >
                      <div className="cursor-pointer rounded-md border-b p-2 hover:bg-gray-100">
                        <div className="flex items-center justify-between">
                          <h4>Adults</h4>
                          <h4>{adults}</h4>
                          <div className="flex items-center rounded-md border">
                            <div
                              className="cursor-pointer border border-r-0 p-1 px-3 hover:bg-red-500 hover:text-white"
                              onClick={() => {
                                if (adults > 1) {
                                  setAdults(adults - 1);
                                }
                              }}
                            >
                              -
                            </div>
                            <div
                              className="border-s-0 cursor-pointer border p-1 px-3 hover:bg-red-500 hover:text-white"
                              onClick={() => setAdults(adults + 1)}
                            >
                              +
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="cursor-pointer rounded-md border-b p-2 hover:bg-gray-100">
                        <div className="flex items-center justify-between">
                          <div>
                            <h4>Children</h4>
                            <h6 className=" text-gray-400">2-12 Years</h6>
                          </div>
                          <h4>{children}</h4>
                          <div className="flex items-center rounded-md border">
                            <div
                              className="cursor-pointer border border-r-0 p-1 px-3 hover:bg-red-500 hover:text-white"
                              onClick={() => {
                                if (children > 0) {
                                  setChildren(children - 1);
                                }
                              }}
                            >
                              -
                            </div>
                            <div
                              className="border-s-0 cursor-pointer border p-1 px-3 hover:bg-red-500 hover:text-white"
                              onClick={() => setChildren(children + 1)}
                            >
                              +
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="cursor-pointer rounded-md border-b p-2 hover:bg-gray-100">
                        <div className="flex items-center justify-between">
                          <div>
                            <h4>Infants</h4>
                            <h6 className=" text-gray-400">0-23 Months</h6>
                          </div>
                          <h4>{infants}</h4>
                          <div className="flex items-center rounded-md border">
                            <div
                              className="cursor-pointer border border-r-0 p-1 px-3 hover:bg-red-500 hover:text-white"
                              onClick={() => {
                                if (infants > 0) {
                                  setInfants(infants - 1);
                                }
                              }}
                            >
                              -
                            </div>
                            <div
                              className="border-s-0 cursor-pointer border p-1 px-3 hover:bg-red-500 hover:text-white"
                              onClick={() => setInfants(infants + 1)}
                            >
                              +
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="flex cursor-pointer items-center gap-2 rounded-md py-1 px-1 hover:bg-gray-100">
                          <input
                            type="radio"
                            id="economy"
                            name="flightType"
                            value="Economy"
                            defaultChecked
                            onChange={handleFlightTypeChange}
                          />
                          <label htmlFor="economy" className="cursor-pointer">
                            Economy
                          </label>
                        </div>
                        <div className="flex cursor-pointer items-center gap-2 rounded-md py-1 px-1 hover:bg-gray-100">
                          <input
                            type="radio"
                            id="premiumEconomy"
                            name="flightType"
                            value="Premium Economy"
                            onChange={handleFlightTypeChange}
                          />
                          <label
                            htmlFor="premiumEconomy"
                            className="cursor-pointer"
                          >
                            Premium Economy
                          </label>
                        </div>
                        <div className="flex cursor-pointer items-center gap-2 rounded-md py-1 px-1 hover:bg-gray-100">
                          <input
                            type="radio"
                            id="business"
                            name="flightType"
                            value="Business"
                            onChange={handleFlightTypeChange}
                          />
                          <label htmlFor="business" className="cursor-pointer">
                            Business
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="m-0 flex items-center justify-center">
                {index === fields.length - 1 &&
                  index !== 0 &&
                  fields.length <= 6 && (
                    <div className="">
                      <button
                        type="button"
                        className="add-city-inner"
                        onClick={() => {
                          append({
                            from: "",
                            to: "",
                            depart: "",
                          });
                        }}
                      >
                        Add City
                      </button>
                    </div>
                  )}
              </div>
              <div className=" flex cursor-pointer items-center justify-center ">
                {fields.length > 1 && (
                  <div
                    onClick={() => remove(index)}
                    className="flex h-full  cursor-pointer items-center justify-center  px-2 text-center text-xs "
                  >
                    <Crossicon />
                    {/* REMOVE */}
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
        {fields.length <= 1 ? (
          <div className="float-right ">
            <button
              type="button"
              className="add-city"
              onClick={() => {
                append({
                  from: "",
                  to: "",
                  depart: "",
                });
              }}
            >
              Add City
            </button>
          </div>
        ) : (
          ""
        )}
        <div className="absolute bottom-[-35px] flex w-full justify-center">
          <button className="btn-grad" type="submit">
            SUBMIT
          </button>
        </div>
      </form>
      <div className="block sm:hidden">
        <MultiCityForPhone options={options} />
      </div>
    </section>
  );
}
