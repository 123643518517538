import React from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

export default function HomeSearch({ icon, text, link, expand, show }) {
  const navigate = useNavigate();
  <motion.div></motion.div>;
  return (
    <motion.div
      className={`madimi-one-regular hover:text-red-500 flex h-20 w-full flex-col items-center justify-center rounded-lg bg-red-50 px-5 text-gray-800 ${show} text-center leading-5`}
      onClick={() => {
        link && navigate(link);
        expand();
      }}
      initial={{ scale: 0.9, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      transition={{ duration: 0.3 }}
    >
      <div>{icon}</div>
      <div>{text}</div>
    </motion.div>
  );
}
