import React, { useState } from "react";
import { IoHomeSharp } from "react-icons/io5";
import { MdOutlineLocalOffer } from "react-icons/md";
import { FaUserCircle } from "react-icons/fa";
import { SiYourtraveldottv } from "react-icons/si";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUserInfo } from "../../redux/userSlice";
import Login from "auth/Login";

export default function BottomNavigationForMobile() {
  const userInfo = useSelector(selectUserInfo);
  const location = useLocation();
  const navigate = useNavigate();
  const [openLogin, setOpenLogin] = useState(false);

  return (
    <section>
      <div className="fixed bottom-0 left-0 right-0 z-[100] w-full rounded-lg bg-white px-5 text-gray-800 sm:hidden">
        <div className="flex items-center justify-between p-5">
          <div
            onClick={() => {
              navigate("/");
              window.scrollTo(0, 0);
            }}
            className={`${
              location.pathname === "/" ? "text-red-500" : "text-[#000317]"
            } `}
          >
            <IoHomeSharp size={25} />
          </div>
          <div
            onClick={() => {
              navigate("/flights");
              window.scrollTo(0, 0);
            }}
            className={`${
              location.pathname === "/flights" ||
              location.pathname === "/flights/round-trip" ||
              location.pathname === "/flights/oneway" ||
              location.pathname === "/flights/multi-city"
                ? "text-red-500"
                : "text-[#000317]"
            }`}
          >
            <SiYourtraveldottv size={25} />
          </div>
          <Link
            to={"/offers"}
            className={`${
              location.pathname === "/offers"
                ? "text-red-500"
                : "text-[#000317]"
            }`}
          >
            <MdOutlineLocalOffer size={25} />
          </Link>
          <div
            onClick={() => {
              if (userInfo.status === true) {
                navigate("/profile");
                window.scrollTo(0, 0);
              } else {
                setOpenLogin(true);
              }
            }}
            className={`${
              location.pathname === "/profile"
                ? "text-red-500"
                : "text-[#000317]"
            }`}
          >
            <FaUserCircle size={25} />
          </div>
        </div>
      </div>
      <Login openLogin={openLogin} setOpenLogin={setOpenLogin} />{" "}
    </section>
  );
}
