// import { m_logo } from "Utils/Images";
import LoginRegisterButton from "components/LoginRegisterButton/LoginRegisterButton";
import MaharajaLogo from "components/MaharajaLogo/MaharajaLogo";
import React from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { selectUserInfo } from "../../../redux/userSlice";
import LoginProfileDropdown from "components/LoginProfileDropdown/LoginProfileDropdown";

export default function SearchComponent() {
  const userInfo = useSelector(selectUserInfo);

  return (
    <section className="relative ">
      <div className="absolute top-4 z-10 w-full text-white ">
        <div className="m-auto hidden max-w-7xl sm:block">
          <div className="flex items-center justify-between">
            {/* <div className="flex flex-shrink-0 items-start">
              <img className=" h-12 w-12" src={m_logo} alt="Your Company" />

              <div className="leading-3">
                <Link to={"/"}>
                  <h2 className="madimi-one-regular relative top-6 text-xl text-white">
                    aharaja Flights Limited
                  </h2>
                </Link>
              </div>
            </div> */}
            <MaharajaLogo limited={"Limited"} />
            <div className="flex flex-col items-center justify-center">
              {/* Profile dropdown */}
              <LoginProfileDropdown />
              {/* login/register */}
              <div className="hidden sm:block ">
                {userInfo?.status ? (
                  <div>
                    <h3 className="text-white">{userInfo?.data?.name}</h3>
                  </div>
                ) : (
                  <LoginRegisterButton dropDown={"dropdown-bottom"} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Outlet />
    </section>
  );
}
