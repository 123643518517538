import i18next from "i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18next
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: "en",
    returnObjects: true,
    resources: {
      en: {
        translation: {
          greeting: "Popular",
          popularDestination: {
            heading: "Destination",
            description: {
              cruise:
                "Cruising has become an increasingly popular way to explore multiple destinations while enjoying the comfort and entertainment offered on board. From the sun-soaked beaches of the Caribbean to the historic cities of Europe, there are countless cruise options available to travelers.",
              airport:
                "Airports play a crucial role as vital transportation hubs that connect people, goods, and ideas from all corners of the globe. Serving as gateways to the world, airports facilitate not only travel but also international commerce, trade, and cultural exchange. With millions of passengers passing through their terminals every day, airports are essential in fostering connections between nations, businesses, and individuals.",
            },
            points: {
              cruise: [
                {
                  name: "Royal Caribbean International",
                  description:
                    "Known for its innovative ships, Royal Caribbean offers destinations across the Caribbean, Mediterranean, and Asia. Ships like Symphony of the Seas and Harmony of the Seas provide a vast range of activities, dining options, and entertainment.",
                },
                {
                  name: "Jalesh Cruises from India",
                  description:
                    "Specifically catering to the Indian market, Jalesh Cruises offers trips around the Indian coastline, visiting destinations like Goa, Diu, Mumbai, and also venturing to the Middle East. They focus on providing an experience tailored to Indian tastes in cuisine, entertainment, and onboard activities.",
                },
                {
                  name: "Disney Cruise Line",
                  description:
                    "Perfect for families, Disney cruises offer an unforgettable experience with Disney characters on board, magical entertainment, and themed activities. They sail to the Bahamas, Caribbean, and have special European routes.",
                },
              ],
            },
          },
        },
      },
      fr: {
        translation: {
          greeting: "bonjour – and au revoir",
          popularDestination: {
            heading: "Destination",
            description: {
              cruise:
                "Les croisières sont devenues un moyen de plus en plus populaire d'explorer plusieurs destinations tout en profitant du confort et des divertissements offerts à bord. Des plages ensoleillées des Caraïbes aux villes historiques d'Europe, il existe d'innombrables options de croisières disponibles pour les voyageurs.",
              airport:
                "Les aéroports jouent un rôle crucial en tant que plaques tournantes vitales du transport qui relient les personnes, les biens et les idées de tous les coins du globe. En tant que portes d'entrée vers le monde, les aéroports facilitent non seulement les voyages, mais aussi le commerce international, le commerce et les échanges culturels. Avec des millions de passagers traversant leurs terminaux chaque jour, les aéroports sont essentiels pour favoriser les connexions entre les nations, les entreprises et les individus.",
            },
            points: {
              cruise: [
                {
                  name: "Royal Caribbean International",
                  description:
                    "Connue pour ses navires innovants, Royal Caribbean propose des destinations à travers les Caraïbes, la Méditerranée et l'Asie. Des navires comme Symphony of the Seas et Harmony of the Seas offrent une vaste gamme d'activités, d'options de restauration et de divertissements.",
                },
                {
                  name: "Jalesh Cruises d'Inde",
                  description:
                    "Ciblant spécifiquement le marché indien, Jalesh Cruises propose des voyages autour de la côte indienne, visitant des destinations comme Goa, Diu, Mumbai et se rendant également au Moyen-Orient. Ils se concentrent sur l'offre d'une expérience adaptée aux goûts indiens en matière de cuisine, de divertissement et d'activités à bord.",
                },
                {
                  name: "Disney Cruise Line",
                  description:
                    "Parfaites pour les familles, les croisières Disney offrent une expérience inoubliable avec les personnages Disney à bord, des divertissements magiques et des activités à thème. Elles naviguent vers les Bahamas, les Caraïbes et proposent également des itinéraires européens spéciaux.",
                },
              ],
            },
          },
        },
      },
      hi: {
        translation: {
          greeting: "लोकप्रिय",
          popularDestination: {
            heading: "गंतव्य",
            description: {
              cruise:
                "क्रूज़िंग एक ऐसा तरीका बन गया है जिससे आप कई गंतव्यों का पता लगा सकते हैं और बोर्ड पर आराम और मनोरंजन का आनंद ले सकते हैं। कैरिबियन के धूप भरे समुद्र तटों से लेकर यूरोप के ऐतिहासिक शहरों तक, यात्रा के लिए अनगिनत क्रूज़ विकल्प उपलब्ध हैं।",
              airport:
                "हवाई अड्डे एक महत्वपूर्ण परिवहन केंद्र के रूप में महत्वपूर्ण भूमिका निभाते हैं जो लोगों, वस्तुओं और विचारों को दुनिया के सभी कोनों से जोड़ते हैं। विश्व के दरवाजों के रूप में काम करते हुए, हवाई अड्डे न केवल यात्रा को बल्कि अंतर्राष्ट्रीय वाणिज्य, व्यापार और सांस्कृतिक आदान-प्रदान को भी सुविधाजनक बनाते हैं। प्रतिदिन लाखों यात्री अपने टर्मिनलों से गुजरते हैं, हवाई अड्डे राष्ट्रों, व्यवसायों और व्यक्तियों के बीच संबंधों को बढ़ावा देने में महत्वपूर्ण हैं।",
            },
            points: {
              cruise: [
                {
                  name: "रॉयल कैरिबियन इंटरनेशनल",
                  description:
                    "अपने अभिनव जहाजों के लिए प्रसिद्ध, रॉयल कैरिबियन कैरिबियन, भूमध्य सागर और एशिया में गंतव्यों की पेशकश करता है। Symphony of the Seas और Harmony of the Seas जैसे जहाज गतिविधियों, भोजन विकल्पों और मनोरंजन की एक विस्तृत श्रृंखला प्रदान करते हैं।",
                },
                {
                  name: "जलेश क्रूज़ेस इंडिया से",
                  description:
                    "विशेष रूप से भारतीय बाजार के लिए, जलेश क्रूज़ेस भारत के तट के चारों ओर यात्रा प्रदान करता है, जैसे गोवा, दीव, मुंबई और मध्य पूर्व की यात्रा भी करता है। वे भारतीय स्वादों में भोजन, मनोरंजन और बोर्ड पर गतिविधियों के लिए एक अनुभव प्रदान करने पर ध्यान केंद्रित करते हैं।",
                },
                {
                  name: "डिज़नी क्रूज़ लाइन",
                  description:
                    "परिवारों के लिए परफेक्ट, डिज़नी क्रूज़ेस डिज़नी पात्रों के साथ एक अविस्मरणीय अनुभव प्रदान करते हैं, जादुई मनोरंजन और थीम वाले गतिविधियाँ। ये बहामास, कैरिबियन के लिए चलती हैं और विशेष यूरोपीय मार्ग भी प्रदान करती हैं।",
                },
              ],
            },
          },
        },
      },
    },
  });

export default i18next;
