import React from "react";

export default function Background({
  bg,
  hidden,
  block,
  alt,
  height,
  darkness,
}) {
  return (
    <div
      className={`${hidden} sm:${block}`}
      style={{
        position: "relative",
        // backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
      }}
    >
      <img src={bg} alt={alt} className={`${height}`} />
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: darkness ? darkness : "rgba(0, 0, 0, 0.3)",
        }}
      ></div>
    </div>
  );
}
