import React from "react";
import SearchButton from "../../../components/SearchButton/SearchButton";
import {
  GiCommercialAirplane,
  GiFishingBoat,
  GiPalmTree,
  GiHelicopter,
  GiCruiser,
  GiPassport,
} from "react-icons/gi";
import {
  FaBlog,
  FaCar,
  FaFileContract,
  FaHotel,
  FaSuitcase,
} from "react-icons/fa";
import { BsCreditCard2FrontFill, BsInfoCircleFill } from "react-icons/bs";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  MdLuggage,
  MdOutlineContactPhone,
  MdOutlinePets,
} from "react-icons/md";
import termsandconditions from "../../../assets/img/auth/agreement.png";
import aboutUsIcon from "../../../assets/img/auth/group.png";
import { motion } from "framer-motion";
import { AiOutlineInfoCircle } from "react-icons/ai";

const buttonVariants = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: { opacity: 1, scale: 1 },
};

const parentVariants = {
  hidden: {},
  visible: {
    transition: {
      staggerChildren: 0.3, // Adjust the stagger effect
      delayChildren: 0.5, // Optional: delay before the stagger starts
    },
  },
};

const delayStep = 0.2; // Delay in seconds for smoother animation

export default function SearchItemRow() {
  const navigate = useNavigate();
  const location = useLocation();
  const isHomePage =
    location.pathname === "/" || location.pathname === "/flights";

  return (
    <div className="absolute -top-5 left-1/2 z-10 h-auto w-2/3 -translate-x-1/2 -translate-y-1/2 transform rounded-lg text-white">
      <motion.div
        className="flex justify-center gap-5"
        initial={isHomePage ? "hidden" : "visible"}
        animate={isHomePage ? "visible" : "visible"}
        exit={isHomePage ? "hidden" : "visible"}
        variants={parentVariants}
      >
        <motion.div
          onClick={() => navigate("/flights")}
          variants={buttonVariants}
          transition={{ duration: 0.3, ease: "easeOut", delay: 0 * delayStep }}
        >
          <SearchButton
            title="Flights"
            icon={<GiCommercialAirplane size={"25px"} />}
            bg={
              location.pathname.startsWith("/flights")
                ? "bg-red-500"
                : "bg-white"
            }
            color={
              location.pathname.startsWith("/flights")
                ? "text-white"
                : "text-gray-900"
            }
            bgHover={
              location.pathname.startsWith("/flights") ? "" : "hover:bg-red-500"
            }
            colorHover={
              location.pathname.startsWith("/flights") ? "" : "hover:text-white"
            }
          />
        </motion.div>
        <motion.div
          onClick={() => navigate("/hotels")}
          variants={buttonVariants}
          transition={{ duration: 0.3, ease: "easeOut", delay: 1 * delayStep }}
        >
          <SearchButton
            title="Hotels"
            icon={<FaHotel size={"25px"} />}
            bg={location.pathname === "/hotels" ? "bg-red-500" : "bg-white"}
            color={
              location.pathname === "/hotels" ? "text-white" : "text-gray-900"
            }
            bgHover={location.pathname === "/hotels" ? "" : "hover:bg-red-500"}
            colorHover={
              location.pathname === "/hotels" ? "" : "hover:text-white"
            }
          />
        </motion.div>
        <motion.div
          onClick={() => navigate("/holidays")}
          variants={buttonVariants}
          transition={{ duration: 0.3, ease: "easeOut", delay: 2 * delayStep }}
        >
          <SearchButton
            title="Holidays"
            icon={<GiPalmTree size={"25px"} />}
            bg={location.pathname === "/holidays" ? "bg-red-500" : "bg-white"}
            color={
              location.pathname === "/holidays" ? "text-white" : "text-gray-900"
            }
            bgHover={
              location.pathname === "/holidays" ? "" : "hover:bg-red-500"
            }
            colorHover={
              location.pathname === "/holidays" ? "" : "hover:text-white"
            }
          />
        </motion.div>
        <motion.div
          onClick={() => navigate("/car-rental")}
          variants={buttonVariants}
          transition={{ duration: 0.3, ease: "easeOut", delay: 3 * delayStep }}
        >
          <SearchButton
            title="Car"
            icon={<FaCar size={"30px"} />}
            bg={location.pathname === "/car-rental" ? "bg-red-500" : "bg-white"}
            color={
              location.pathname === "/car-rental"
                ? "text-white"
                : "text-gray-900"
            }
            bgHover={
              location.pathname === "/car-rental" ? "" : "hover:bg-red-500"
            }
            colorHover={
              location.pathname === "/car-rental" ? "" : "hover:text-white"
            }
          />
        </motion.div>
        <motion.div
          onClick={() => navigate("/cruise")}
          variants={buttonVariants}
          transition={{ duration: 0.3, ease: "easeOut", delay: 3 * delayStep }}
        >
          <SearchButton
            title="Cruise"
            icon={<GiCruiser size={"30px"} />}
            bg={location.pathname === "/cruise" ? "bg-red-500" : "bg-white"}
            color={
              location.pathname === "/cruise" ? "text-white" : "text-gray-900"
            }
            bgHover={location.pathname === "/cruise" ? "" : "hover:bg-red-500"}
            colorHover={
              location.pathname === "/cruise" ? "" : "hover:text-white"
            }
          />
        </motion.div>
        <motion.div
          className="dropdown-hover dropdown"
          variants={buttonVariants}
          transition={{ duration: 0.3, ease: "easeOut", delay: 4 * delayStep }}
        >
          <div tabIndex={0} role="button">
            <SearchButton
              title="Charters"
              icon={<GiHelicopter size={"30px"} />}
              bg={
                location.pathname.startsWith("/charters")
                  ? "bg-red-500"
                  : "bg-white"
              }
              color={
                location.pathname.startsWith("/charters")
                  ? "text-white"
                  : "text-gray-900"
              }
              bgHover={
                location.pathname.startsWith("/charters")
                  ? ""
                  : "hover:bg-red-500"
              }
              colorHover={
                location.pathname.startsWith("/charters")
                  ? ""
                  : "hover:text-white"
              }
            />
          </div>
          <div
            tabIndex={0}
            className="menu dropdown-content z-[1] mt-1 w-52 rounded-box bg-white p-2 py-2 px-2 text-gray-900 shadow"
          >
            <div
              className="my-2 flex w-full cursor-pointer items-center hover:text-red-500"
              onClick={() => {
                navigate("/plane");
                window.scrollTo(0, 0);
              }}
            >
              <GiCommercialAirplane size={"20px"} className="mr-4" />
              <p>Plane</p>
            </div>
            <Link
              to={"/yacht"}
              className="my-2 flex w-full cursor-pointer items-center hover:text-red-500"
            >
              <GiFishingBoat size={"20px"} className="mr-4" />
              <p>Yacht</p>
            </Link>
          </div>
        </motion.div>
        <motion.div
          className="dropdown-hover dropdown"
          variants={buttonVariants}
          transition={{ duration: 0.3, ease: "easeOut", delay: 5 * delayStep }}
        >
          <div tabIndex={0} role="button">
            <SearchButton
              title="Useful info"
              icon={<BsInfoCircleFill size={"25px"} />}
              bg={
                location.pathname === "/useful-info" ? "bg-red-500" : "bg-white"
              }
              color={
                location.pathname === "/useful-info"
                  ? "text-white"
                  : "text-gray-900"
              }
              bgHover={
                location.pathname === "/useful-info" ? "" : "hover:bg-red-500"
              }
              colorHover={
                location.pathname === "/useful-info" ? "" : "hover:text-white"
              }
            />
          </div>
          <div
            tabIndex={0}
            className="menu dropdown-content z-[1] mt-1 w-52 rounded-box bg-white p-2 py-2 px-2 text-gray-900 shadow"
          >
            <Link
              to={"/visa"}
              className="my-2 flex w-full cursor-pointer items-center hover:text-red-500"
            >
              <GiPassport size={"20px"} className="mr-3" />
              <p>Visa information</p>
            </Link>
            <Link
              to={"/visa"}
              className="my-2 flex w-full cursor-pointer items-center hover:text-red-500"
            >
              <MdLuggage size={"20px"} className="mr-3" />
              <p>Baggage information</p>
            </Link>
            <Link
              to={"/traveling-with-pets"}
              className="my-2 flex w-full cursor-pointer items-center hover:text-red-500"
            >
              <MdOutlinePets size={"20px"} className="mr-3" />
              <p>Traveling with pets</p>
            </Link>
            <Link
              to={"/credit-card-authorization"}
              className="my-2 flex w-full cursor-pointer items-center hover:text-red-500"
            >
              <BsCreditCard2FrontFill size={"20px"} className="mr-3" />
              <p>Credit Card Authorization</p>
            </Link>
            <Link
              to={"/privacy-policy"}
              className="my-2 flex w-full cursor-pointer items-center hover:text-red-500"
            >
              <FaFileContract size={"20px"} className="mr-3" />
              <p>Terms and conditions</p>
            </Link>
          </div>
        </motion.div>
        <motion.div
          className="dropdown-hover dropdown dropdown-end"
          variants={buttonVariants}
          transition={{ duration: 0.3, ease: "easeOut", delay: 6 * delayStep }}
        >
          <div tabIndex={0} role="button">
            <SearchButton
              title="More"
              icon={<span className="loading loading-dots loading-xs"></span>}
              bg={location.pathname === "/mores" ? "bg-red-500" : "bg-white"}
              color={
                location.pathname === "/mores" ? "text-white" : "text-gray-900"
              }
              bgHover={location.pathname === "/mores" ? "" : "hover:bg-red-500"}
              colorHover={
                location.pathname === "/mores" ? "" : "hover:text-white"
              }
            />
          </div>
          <div
            tabIndex={0}
            className="menu dropdown-content z-[1] mt-1 w-52 rounded-box bg-white p-2 py-2 px-2 text-gray-900 shadow"
          >
            <Link
              to="/blogs"
              className="my-2 flex w-full cursor-pointer items-center hover:text-red-500"
            >
              <FaBlog size={"20px"} className="mr-3" />
              <p>Blog</p>
            </Link>
            <Link
              to="/careers"
              className="my-2 flex w-full cursor-pointer items-center hover:text-red-500"
            >
              <FaSuitcase size={"20px"} className="mr-3" />
              <p>Careers</p>
            </Link>
            <Link
              to="/about-us"
              className="my-2 flex w-full cursor-pointer items-center hover:text-red-500"
            >
              <AiOutlineInfoCircle size={"20px"} className="mr-3" />

              <p>About Us</p>
            </Link>
            <Link
              to="/contact-us"
              className="my-2 flex w-full cursor-pointer items-center hover:text-red-500"
            >
              <MdOutlineContactPhone size={"20px"} className="mr-3" />
              <p>Contact Us</p>
            </Link>
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
}
