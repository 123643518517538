import { m_logo } from "Utils/Images";
import React, { useState, useEffect } from "react";
import ChatBot from "react-simple-chatbot";
import { ThemeProvider } from "styled-components";
import { FaRobot } from "react-icons/fa";
import { chatbot } from "Utils/Images";
import Background from "components/background/Background";
import { selectUserInfo } from "../../redux/userSlice";
import { useSelector } from "react-redux";
import { user } from "Utils/Images";

const FlightBookingChatbot = () => {
  const userInfo = useSelector(selectUserInfo);
  const [location, setLocation] = useState(null);
  const [error, setError] = useState(null);
  const [bookingData, setBookingData] = useState({});
  const [currentStep, setCurrentStep] = useState(0);

  //   const handleUserInput = ({ steps }) => {
  //     const { id, message } = steps[steps.length - 1];
  //     console.log("User:", message); // Log user input

  //     // Bot response handling logic
  //     if (
  //       id !== "endFlight" &&
  //       id !== "endHotel" &&
  //       id !== "endHoliday" &&
  //       id !== "endCruise"
  //     ) {
  //       const botResponse = steps.find((step) => step.id === id + 1); // Assuming the next step ID follows the current step ID convention
  //       console.log("Bot:", botResponse.message); // Log bot response
  //     }

  //     // Extracting user input for booking data
  //     const { name, value } = steps[steps.length - 1];
  //     // Update bookingData state with user input
  //     setBookingData((prevData) => ({
  //       ...prevData,
  //       [name]: value,
  //     }));

  //     // Log updated bookingData
  //     console.log("Booking Data:", bookingData);

  //     return true;
  //   };

  // Function to validate phone number format
  const validatePhoneNumber = (phoneNumber) => {
    // You can use a regular expression to validate the phone number format
    // const phoneNumberRegex = /^\d{10}$/; // Change this regex according to your requirements
    // return phoneNumberRegex.test(phoneNumber);
    return phoneNumber;
  };

  // Function to validate email format
  const validateEmail = (email) => {
    // You can use a regular expression to validate the email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Change this regex according to your requirements
    return emailRegex.test(email);
  };

  // Function to confirm booking and handle errors
  const handleBookingConfirmation = () => {
    // Add logic to confirm booking and handle errors
    console.log("Booking confirmed:", bookingData);
  };

  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {
          setError(error.message);
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  };

  // Steps for the chatbot conversation
  const steps = [
    {
      id: "0",
      message:
        "Hello, Namaste and welcome, I am Raja. Let me know what can I help you with?",
      trigger: "options",
    },
    {
      id: "options",
      options: [
        {
          value: "Flights",
          label: "Flights",
          trigger: "askForContactInfoFlight",
        },
        {
          value: "Hotels",
          label: "Hotels",
          trigger: "askForContactInfoHotel",
        },
        {
          value: "Holidays",
          label: "Holidays",
          trigger: "askForContactInfoHoliday",
        },
        {
          value: "Cruise",
          label: "Cruise",
          trigger: "askForContactInfoCruise",
        },
        {
          value: "Car",
          label: "Car",
          trigger: "askForContactInfoCar",
        },
      ],
    },
    // Contact information collection steps for Flight option
    {
      id: "askForContactInfoFlight",
      message:
        "To assist you better with flight booking, could you please provide your contact information?",
      trigger: "askForPhoneNumberFlight",
    },
    {
      id: "askForPhoneNumberFlight",
      message: "Sure, what's your phone number?",
      trigger: "waitForPhoneNumberFlight",
    },
    {
      id: "waitForPhoneNumberFlight",
      user: true,
      validator: (value) => {
        if (validatePhoneNumber(value)) {
          return true;
        } else {
          return "Please enter a valid phone number.";
        }
      },
      trigger: "askForEmailFlight",
    },
    {
      id: "askForEmailFlight",
      message: "Great! Now, could you please provide your email address?",
      trigger: "waitForEmailFlight",
    },
    {
      id: "waitForEmailFlight",
      user: true,
      validator: (value) => {
        if (validateEmail(value)) {
          return true;
        } else {
          return "Please enter a valid email address.";
        }
      },
      trigger: "askDepartureCityFlight",
    },
    // Steps for Flight option
    {
      id: "askDepartureCityFlight",
      message: "Please enter your departure city:",
      trigger: "askArrivalCityFlight",
    },
    {
      id: "askArrivalCityFlight",
      user: true,
      trigger: "askDepartureDateFlight",
    },
    {
      id: "askDepartureDateFlight",
      message: "Please enter your departure date (YYYY-MM-DD):",
      trigger: "askReturnDateFlight",
    },
    {
      id: "askReturnDateFlight",
      message: "Please enter your return date (YYYY-MM-DD):",
      trigger: "endFlight",
    },
    {
      id: "endFlight",
      message:
        "Thank you for considering TravelBot for your flight booking. Have a great day!",
      end: true,
    },
    // Contact information collection steps for Hotel option
    {
      id: "askForContactInfoHotel",
      message:
        "To assist you better with hotel booking, could you please provide your contact information?",
      trigger: "askForPhoneNumberHotel",
    },
    {
      id: "askForPhoneNumberHotel",
      message: "Sure, what's your phone number?",
      trigger: "waitForPhoneNumberHotel",
    },
    {
      id: "waitForPhoneNumberHotel",
      user: true,
      validator: (value) => {
        if (validatePhoneNumber(value)) {
          return true;
        } else {
          return "Please enter a valid phone number.";
        }
      },
      trigger: "askForEmailHotel",
    },
    {
      id: "askForEmailHotel",
      message: "Great! Now, could you please provide your email address?",
      trigger: "waitForEmailHotel",
    },
    {
      id: "waitForEmailHotel",
      user: true,
      validator: (value) => {
        if (validateEmail(value)) {
          return true;
        } else {
          return "Please enter a valid email address.";
        }
      },
      trigger: "askCityForHotel",
    },
    // Steps for Hotel option
    {
      id: "askCityForHotel",
      message: "Please enter the city where you want to stay:",
      trigger: "askCheckInDateHotel",
    },
    {
      id: "askCheckInDateHotel",
      message: "When do you want to check-in (YYYY-MM-DD)?:",
      trigger: "askCheckOutDateHotel",
    },
    {
      id: "askCheckOutDateHotel",
      message: "When do you want to check-out (YYYY-MM-DD)?:",
      trigger: "endHotel",
    },
    {
      id: "endHotel",
      message:
        "Thank you for considering TravelBot for your hotel booking. Have a great day!",
      end: true,
    },
    // Contact information collection steps for Holiday option
    {
      id: "askForContactInfoHoliday",
      message:
        "To assist you better with holiday planning, could you please provide your contact information?",
      trigger: "askForPhoneNumberHoliday",
    },
    {
      id: "askForPhoneNumberHoliday",
      message: "Sure, what's your phone number?",
      trigger: "waitForPhoneNumberHoliday",
    },
    {
      id: "waitForPhoneNumberHoliday",
      user: true,
      validator: (value) => {
        if (validatePhoneNumber(value)) {
          return true;
        } else {
          return "Please enter a valid phone number.";
        }
      },
      trigger: "askForEmailHoliday",
    },
    {
      id: "askForEmailHoliday",
      message: "Great! Now, could you please provide your email address?",
      trigger: "waitForEmailHoliday",
    },
    {
      id: "waitForEmailHoliday",
      user: true,
      validator: (value) => {
        if (validateEmail(value)) {
          return true;
        } else {
          return "Please enter a valid email address.";
        }
      },
      trigger: "askPreferredHolidayType",
    },
    // Steps for Holiday option
    {
      id: "askPreferredHolidayType",
      message: "Which type of holiday are you interested in?",
      trigger: "endHoliday",
    },
    {
      id: "endHoliday",
      message:
        "Thank you for considering TravelBot for your holiday planning. Have a great day!",
      end: true,
    },
    // Contact information collection steps for Cruise option
    {
      id: "askForContactInfoCruise",
      message:
        "To assist you better with cruise booking, could you please provide your contact information?",
      trigger: "askForPhoneNumberCruise",
    },
    {
      id: "askForPhoneNumberCruise",
      message: "Sure, what's your phone number?",
      trigger: "waitForPhoneNumberCruise",
    },
    {
      id: "waitForPhoneNumberCruise",
      user: true,
      validator: (value) => {
        if (validatePhoneNumber(value)) {
          return true;
        } else {
          return "Please enter a valid phone number.";
        }
      },
      trigger: "askForEmailCruise",
    },
    {
      id: "askForEmailCruise",
      message: "Great! Now, could you please provide your email address?",
      trigger: "waitForEmailCruise",
    },
    {
      id: "waitForEmailCruise",
      user: true,
      validator: (value) => {
        if (validateEmail(value)) {
          return true;
        } else {
          return "Please enter a valid email address.";
        }
      },
      trigger: "askCruiseDestination",
    },
    // Steps for Cruise option
    {
      id: "askCruiseDestination",
      message: "Which destination are you dreaming of for your cruise?",
      trigger: "endCruise",
    },
    {
      id: "endCruise",
      message:
        "Thank you for considering TravelBot for your cruise booking. Have a great day!",
      end: true,
    },
    // Contact information collection steps for Car option
    {
      id: "askForContactInfoCar",
      message:
        "To assist you with car booking, could you please provide your contact information?",
      trigger: "askForPhoneNumberCar",
    },
    {
      id: "askForPhoneNumberCar",
      message: "Sure, what's your phone number?",
      trigger: "waitForPhoneNumberCar",
    },
    {
      id: "waitForPhoneNumberCar",
      user: true,
      validator: (value) =>
        validatePhoneNumber(value)
          ? true
          : "Please enter a valid phone number.",
      trigger: "askForEmailCar",
    },
    {
      id: "askForEmailCar",
      message: "Great! Now, could you please provide your email address?",
      trigger: "waitForEmailCar",
    },
    {
      id: "waitForEmailCar",
      user: true,
      validator: (value) =>
        validateEmail(value) ? true : "Please enter a valid email address.",
      trigger: "askPickupLocation",
    },
    // Step to choose between manual and current location
    {
      id: "askPickupLocation",
      message: "How would you like to enter the pickup location?",
      trigger: "askPickupLocationMethod",
    },
    {
      id: "askPickupLocationMethod",
      options: [
        {
          value: "Manual location",
          label: "Enter manually",
          trigger: "askPickupLocationCar",
        },
        {
          value: "Current location",
          label: "Use current location",
          trigger: "getLocationForCarPickup",
        },
      ],
    },

    // Step to get the current location
    {
      id: "getLocationForCarPickup",
      message: "Fetching your current location...",
      component: () => {
        getUserLocation();
        return null;
      },
      trigger: "showCurrentLocationForCarPickup",
    },
    {
      id: "showCurrentLocationForCarPickup",
      message:
        "Your current location has been noted as the pickup location. Please enter the drop-off location:",
      trigger: "askDropoffLocationCar",
    },
    // Continuation of steps for Car option
    {
      id: "askPickupLocationCar",
      message: "Please enter the pickup location for the car:",
      trigger: "askDropoffLocationCar",
    },
    {
      id: "askDropoffLocationCar",
      user: true,
      trigger: "askPickupDateCar",
    },
    {
      id: "askPickupDateCar",
      message: "Please enter the pickup date (YYYY-MM-DD):",
      trigger: "askDropoffDateCar",
    },
    {
      id: "askDropoffDateCar",
      message: "Please enter the drop-off date (YYYY-MM-DD):",
      trigger: "endCar",
    },
    {
      id: "endCar",
      message:
        "Thank you for choosing TravelBot for your car booking. Have a great day!",
      end: true,
    },
  ];

  // Auto-reply logic
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (currentStep < steps.length - 1) {
        setCurrentStep((prevStep) => prevStep + 1);
      }
    }, 3000); // Adjust delay time in milliseconds as needed

    return () => clearTimeout(timeout);
  }, [currentStep, steps]);

  // Set some properties of the bot
  const config = {
    // botAvatar: chatbot,
    floating: true,
  };

  return (
    <section>
      {userInfo?.data?.role === 1 ? null : (
        <ThemeProvider
          theme={{
            background: "#f5f8fb",
            fontFamily: "Arial, sans-serif",
            headerBgColor:
              "linear-gradient(to right, #ee0979 0%, #ff6a00 51%, #ee0979 100%)",
            headerFontColor: "#fff",
            headerFontSize: "16px",
            botBubbleColor: "white",
            botFontColor: "black",
            userBubbleColor: "red",
            userFontColor: "white",
            // botAvatar: chatbot,
          }}
        >
          <ChatBot
            steps={steps}
            handleEnd={handleBookingConfirmation}
            headerTitle=<div className="flex items-center gap-2">
              {/* <FaRobot /> */}
              <img src={chatbot} alt="chatbot" className="w-12" />
              Hello, I'm Raja
            </div>
            // floating={true} // Adjust floating as per your requirement
            botAvatar={chatbot} // Assuming m_logo is the correct avatar image
            // handleUserInput={handleUserInput} // Add handleUserInput function
            handleUserMessage={(message) => console.log(`Bot: ${message}`)}
            {...steps[currentStep]} // Pass current step to ChatBot component
            {...config}
            enableSmoothScroll={true}
            enableMobileAutoFocus={true}
            floating={true}
            floatingIcon=<img src={chatbot} alt="chatbot" className="w-10" />
            floatingStyle={{
              Background: "transparent",
              marginBottom: "50px",
              marginRight: "-5px",
              width: "50px",
              height: "50px",
              zIndex: "10",
            }}
            // userAvatar={chatbot}
            avatarStyle={{
              // Customize avatar style here
              // display: "none",
              width: "50px", // Example width
              height: "50px", // Example height
              borderRadius: "50%", // Example border radius for a circular avatar
              // Add more styles as needed
            }}
          />
        </ThemeProvider>
      )}
    </section>
  );
};

export default FlightBookingChatbot;
