import React from "react";
import About from "components/Charters/About";
import Banner from "components/Charters/Banner";
import BookingForm from "components/Charters/BookingForm";
import WhychooseUs from "components/Charters/WhychooseUs";
import UserNavbar from "components/UserNavbar/UserNavbar";
import { charterplane } from "Utils/Images";
import { bussinesperson } from "Utils/Images";
import { charterplanesm } from "Utils/Images";
import { contectuslight } from "Utils/Images";

export default function Plane() {
  const reasons = [
    {
      title: "Flexibility",
      description:
        "With our charter services, you dictate the schedule. No more adhering to rigid airline timetables. We tailor flights to your itinerary, ensuring you arrive at your destination promptly and on your terms.",
    },
    {
      title: "Privacy",
      description:
        "Enjoy the ultimate in privacy and comfort. Our charter planes offer exclusive use of the aircraft, allowing you to conduct business meetings, relax with loved ones, or simply enjoy some quiet time away from the crowds.",
    },
    {
      title: "Customization",
      description:
        "From aircraft selection to in-flight amenities, we provide a personalized experience. Choose from a range of aircraft sizes and configurations, and tailor every aspect of your journey to your preferences.",
    },
    {
      title: "Convenience",
      description:
        "Say goodbye to long security lines and crowded terminals. With our charter services, you'll enjoy streamlined boarding processes and access to private terminals, minimizing travel stress and maximizing efficiency.",
    },
    {
      title: "Safety",
      description:
        "Your safety is our top priority. Rest assured knowing that all our aircraft undergo rigorous maintenance checks and adhere to the highest safety standards. Our experienced pilots are dedicated to ensuring a smooth and secure flight from takeoff to landing.",
    },
  ];

  return (
    <section className="text-gray-800">
      <UserNavbar />

      <Banner
        charterplane={charterplane}
        charterplanesm={charterplanesm}
        heading={"Charter Your Private Plane Today"}
        paragraph={"Experience Seamless Travel with Our Plane Charter Services"}
        darkness={"rgba(0, 0, 0, 0.7)"}
        button={"Booking Enquiry"}
      />

      {/* <div className="relative ">
        <div class="custom-shape-divider-bottom-1714468197">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M1200 0L0 0 598.97 114.72 1200 0z"
              class="shape-fill"
            ></path>
          </svg>
        </div>
      </div> */}
      <div className="m-auto max-w-8xl">
        <About
          bussinesperson={bussinesperson}
          heading={"ABOUT OUR CHARTER FLIGHTS"}
          shortParagraph={"Experience luxury travel on a private jet"}
          paragraph={
            "At Maharaja Flights Limited, we understand that every journey is unique. Whether you're traveling for business or leisure, our charter flights offer the ultimate in luxury, comfort, and convenience. With a dedicated team committed to providing personalized service and attention to detail, we ensure that every aspect of your journey is tailored to your needs. Experience the difference of flying with Maharaja Flights Limited."
          }
        />
        <div className="">
          <WhychooseUs
            heading={"Why Choose Maharaja Flights Limited's Plane Charters?"}
            reasons={reasons}
          />
        </div>
        <BookingForm image={contectuslight} resoures={"planeCharter"} />
      </div>
    </section>
  );
}
