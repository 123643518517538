import React from "react";
import ScrollToTopOnRouteChange from "components/ScrollToTopOnRouteChange/ScrollToTopOnRouteChange";
import { MaterialReactTable } from "material-react-table";

export default function UserList() {
  // Sample data for the table, including roles, status, phone numbers, and account creation dates
  const data = React.useMemo(
    () => [
      {
        id: 4,
        name: "Alice Johnson",
        email: "alice@example.com",
        age: 22,
        role: "User",
        status: "Active",
        phone: "+1-555-9876",
        createdAt: "2023-11-02",
      },
      {
        id: 5,
        name: "Bob Brown",
        email: "bob@example.com",
        age: 45,
        role: "User",
        status: "Active",
        phone: "+1-555-4321",
        createdAt: "2024-01-15",
      },
      {
        id: 6,
        name: "Chris Green",
        email: "chris@example.com",
        age: 31,
        role: "User",
        status: "Inactive",
        phone: "+1-555-8765",
        createdAt: "2023-05-20",
      },
      {
        id: 8,
        name: "Ella Black",
        email: "ella@example.com",
        age: 24,
        role: "User",
        status: "Inactive",
        phone: "+1-555-2222",
        createdAt: "2023-07-14",
      },
      {
        id: 9,
        name: "Frank Stone",
        email: "frank@example.com",
        age: 38,
        role: "User",
        status: "Active",
        phone: "+1-555-3333",
        createdAt: "2023-09-03",
      },
      {
        id: 11,
        name: "Henry Miller",
        email: "henry@example.com",
        age: 36,
        role: "User",
        status: "Active",
        phone: "+1-555-5555",
        createdAt: "2023-06-18",
      },
      {
        id: 12,
        name: "Isabella Clark",
        email: "isabella@example.com",
        age: 23,
        role: "User",
        status: "Inactive",
        phone: "+1-555-6666",
        createdAt: "2024-05-29",
      },
      {
        id: 14,
        name: "Katherine Adams",
        email: "katherine@example.com",
        age: 41,
        role: "User",
        status: "Inactive",
        phone: "+1-555-8888",
        createdAt: "2024-01-01",
      },
      {
        id: 16,
        name: "Mia Scott",
        email: "mia@example.com",
        age: 26,
        role: "User",
        status: "Inactive",
        phone: "+1-555-1010",
        createdAt: "2023-03-04",
      },
      {
        id: 18,
        name: "Olivia Evans",
        email: "olivia@example.com",
        age: 25,
        role: "User",
        status: "Inactive",
        phone: "+1-555-1313",
        createdAt: "2024-04-19",
      },
      {
        id: 20,
        name: "Quinn Thomas",
        email: "quinn@example.com",
        age: 42,
        role: "User",
        status: "Active",
        phone: "+1-555-1515",
        createdAt: "2022-11-23",
      },
    ],
    []
  );

  // Column definitions for the table
  const columns = React.useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
      },
      {
        accessorKey: "name",
        header: "Name",
      },
      {
        accessorKey: "email",
        header: "Email",
      },
      {
        accessorKey: "age",
        header: "Age",
      },
      {
        accessorKey: "role",
        header: "Role",
      },
      {
        accessorKey: "status",
        header: "Status",
        Cell: ({ cell }) => (
          <span
            className={`${
              cell.getValue() === "Active" ? "text-green-600" : "text-red-600"
            }`}
          >
            {cell.getValue()}
          </span>
        ),
      },
      {
        accessorKey: "phone",
        header: "Phone Number",
      },
      {
        accessorKey: "createdAt",
        header: "Account Created",
        Cell: ({ cell }) => new Date(cell.getValue()).toLocaleDateString(),
      },
    ],
    []
  );

  return (
    <section className="mt-5">
      <ScrollToTopOnRouteChange />
      <MaterialReactTable
        columns={columns}
        data={data}
        // enableRowSelection
        enableClickToCopy
        state={{ showProgressBars: false }}
        // enableEditing={true}
        muiSelectCheckboxProps={{
          color: "secondary", //makes all checkboxes use the secondary color
        }}
      />
    </section>
  );
}
