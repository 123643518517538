import React from "react";
// import { useLocation } from "react-router-dom";

export default function SearchButton({
  title,
  icon,
  bg,
  color,
  bgHover,
  colorHover,
}) {
  // const location = useLocation();
  // console.log(location.pathname, "Current page URL");

  return (
    <div
      className={`flex h-20 w-24 ${color} cursor-pointer flex-col items-center justify-around rounded-md  shadow-xl ${bg} ${bgHover} ${colorHover}`}
    >
      <p>{icon}</p>
      <p>{title}</p>
    </div>
  );
}
