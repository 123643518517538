import React from "react";

export default function ContactNumber({ headingBg, text }) {
  const contactNumbers = [
    { location: "Toronto ON", number: "+1905-244-1300" },
    { location: "Vancouver BC", number: "+1778-244-1300" },
    { location: "Syracuse NY", number: "+1680-244-1300" },
    { location: "San Francisco CA", number: "+1628-244-1300" },
    { location: "Miami FL", number: "+1786-733-1300" },
    { location: "Toll Free", number: "+1855-243-1300" },
    { location: "India Toll Free", number: "+91-9819811300" },
  ];

  return (
    <nav className="flex flex-col">
      <h6
        className={`footer-title inline-block tracking-wider ${headingBg} ${text}`}
      >
        Need Help? 24/7 Support available.
      </h6>
      {contactNumbers.map((contact, index) => (
        <a
          key={index}
          href={`tel:${contact.number}`}
          className="link-hover link whitespace-nowrap text-base"
        >
          {contact.location} {contact.number}
        </a>
      ))}
    </nav>
  );
}
