import React from "react";
import ScrollToTopOnRouteChange from "components/ScrollToTopOnRouteChange/ScrollToTopOnRouteChange";
import { MaterialReactTable } from "material-react-table";
import { Edit, Lock, Person, Delete } from "@mui/icons-material"; // Importing Material UI icons

export default function Employee() {
  // Sample data for the table with employee information
  const data = React.useMemo(
    () => [
      {
        id: 1,
        employeeName: "Alice Johnson",
        roleName: "Employee",
        email: "alice@example.com",
        extension: "+1-555-9876",
      },
      {
        id: 2,
        employeeName: "Bob Brown",
        roleName: "Employee",
        email: "bob@example.com",
        extension: "+1-555-4321",
      },
      {
        id: 3,
        employeeName: "Chris Green",
        roleName: "Employee",
        email: "chris@example.com",
        extension: "+1-555-8765",
      },
      {
        id: 4,
        employeeName: "Ella Black",
        roleName: "Employee",
        email: "ella@example.com",
        extension: "+1-555-2222",
      },
      {
        id: 5,
        employeeName: "Frank Stone",
        roleName: "Employee",
        email: "frank@example.com",
        extension: "+1-555-3333",
      },
      {
        id: 6,
        employeeName: "Henry Miller",
        roleName: "Employee",
        email: "henry@example.com",
        extension: "+1-555-5555",
      },
    ],
    []
  );

  // Column definitions for the table
  const columns = React.useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
      },
      {
        accessorKey: "employeeName",
        header: "Employee Name",
      },
      {
        accessorKey: "roleName",
        header: "Role Name",
      },
      {
        accessorKey: "email",
        header: "Email",
      },
      {
        accessorKey: "extension",
        header: "Extension Number",
      },
      {
        accessorKey: "actions",
        header: "Actions",
        Cell: () => (
          <div className="flex space-x-2">
            <Edit className="cursor-pointer text-blue-500" />
            <Lock className="cursor-pointer text-yellow-500" />
            <Person className="cursor-pointer text-green-500" />
            <Delete className="cursor-pointer text-red-500" />
          </div>
        ),
      },
    ],
    []
  );

  return (
    <section className="mt-5">
      <ScrollToTopOnRouteChange />
      <MaterialReactTable
        columns={columns}
        data={data}
        enableClickToCopy
        state={{ showProgressBars: false }}
        muiSelectCheckboxProps={{
          color: "secondary", //makes all checkboxes use the secondary color
        }}
      />
    </section>
  );
}
