import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import RtlLayout from "layouts/rtl";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import Home from "views/UserInterface/Home/Home";
import UserNavbar from "components/UserNavbar/UserNavbar";
import Flight from "./views/UserInterface/flight/Flight";
import Hotel from "./views/UserInterface/Hotel/Hotel";
import Charters from "views/UserInterface/Charters/Charters";
import Holidays from "views/UserInterface/Holiday/Holidays";
import AboutUs from "views/UserInterface/AboutUs/AboutUs";
import { useSelector } from "react-redux";
import { selectUserInfo } from "./redux/userSlice";
import Hotdeals from "views/UserInterface/Hotdeals/Hotdeals";
import OneWay from "components/FlightWayRoute/OneWay";
import RoundTrip from "components/FlightWayRoute/RoundTrip";
import MultiCity from "components/FlightWayRoute/MultiCity";
import Offer from "views/UserInterface/Offer/Offer";
import OfferDetails from "views/UserInterface/OfferDetails/OfferDetails";
import BottomNavigationForMobile from "components/BottomNavigationForMobile/BottomNavigationForMobile";
import UserFooter from "components/UserFooter/UserFooter";
import Cruise from "views/UserInterface/Cruise/Cruise";
import TravelStories from "views/UserInterface/TravelStories/TravelStories";
import Job from "views/UserInterface/Job/Job";
import TermsAndConditions from "views/UserInterface/TermsAndConditions/TermsAndConditions";
import BaggageInformation from "views/UserInterface/baggageInformation/BaggageInformation";
import Travelwithpets from "views/UserInterface/Travelwithpets/Travelwithpets";
import CreditCardAuthorization from "views/UserInterface/CreditCardAuthorization/CreditCardAuthorization";
import ContactUs from "views/UserInterface/ContactUs/ContactUs";
import Blog from "views/UserInterface/log/Blog";
import Plane from "views/UserInterface/Plane/Plane";
import Yacht from "views/UserInterface/Yacht/Yacht";
import VisaInformation from "views/UserInterface/VisaInformation/VisaInformation";
import PrivacyPolicy from "views/UserInterface/PrivacyPolicy/PrivacyPolicy";
import Faq from "views/UserInterface/Faq/Faq";
import Bloginfo from "views/UserInterface/Bloginfo/Bloginfo";
import Insurance from "views/UserInterface/insurance/Insurance";
import Profile from "views/UserInterface/Profile/Profile";
import Techteam from "views/UserInterface/Techteam/Techteam";
import FlightTracker from "views/UserInterface/FlightTracker/FlightTracker";
import CarRental from "views/UserInterface/CarRental/CarRental";

const App = () => {
  const userInfo = useSelector(selectUserInfo);
  const [isVisible, setIsVisible] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const isAdmin = userInfo?.data?.role === 1;

  return (
    <div>
      <div
        className={`sticky top-0 z-10 ${
          isVisible
            ? "hidden delay-300 duration-500 ease-in-out"
            : " delay-300 duration-500 ease-in-out"
        }`}
      >
        {!isAdmin &&
        location.pathname !== "/offer" &&
        location.pathname !== "/offer-details" &&
        location.pathname !== "/contact-us" &&
        location.pathname !== "/visa" ? (
          <UserNavbar />
        ) : null}
      </div>
      <Routes>
        {isAdmin ? (
          <>
            <Route
              path="/"
              element={<Navigate to="/admin/default" replace />}
            />
            <Route
              path="/dashboard"
              element={<Navigate to="/admin" replace />}
            />
            <Route path="auth/*" element={<AuthLayout />} />
            <Route path="admin/*" element={<AdminLayout />} />
            <Route path="rtl/*" element={<RtlLayout />} />
          </>
        ) : (
          <>
            <Route path="/" element={<Home />}>
              <Route index element={<Flight />} />
              <Route path={"/flights"} element={<Flight />}>
                <Route index element={<RoundTrip />} />
                <Route path="oneWay" element={<OneWay />} />
                <Route path="round-trip" element={<RoundTrip />} />
                <Route path="multi-city" element={<MultiCity />} />
              </Route>
              <Route path="/hotdeals" element={<Flight />}>
                <Route index element={<RoundTrip />} />
                <Route path="oneWay" element={<OneWay />} />
                <Route path="round-trip" element={<RoundTrip />} />
                <Route path="multi-city" element={<MultiCity />} />
              </Route>
              <Route path="/hotels" element={<Hotel />} />
              <Route path="/holidays" element={<Holidays />} />
              <Route path="/charters" element={<Charters />} />
              <Route path="/hotdeals" element={<Flight />} />
              <Route path="/cruise" element={<Cruise />} />
            </Route>
            <Route path="/car-rental" element={<CarRental />} />
            <Route path="/profile" element={<Profile />} />
            <Route
              path="/baggage-information"
              element={<BaggageInformation />}
            />
            <Route path="/traveling-with-pets" element={<Travelwithpets />} />
            <Route
              path="/credit-card-authorization"
              element={<CreditCardAuthorization />}
            />
            <Route path="/Visa" element={<VisaInformation />} />
            <Route path="/insurance" element={<Insurance />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route path="/maharaja-technologies" element={<Techteam />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/careers" element={<Job />} />
            <Route path="/blogs" element={<Blog />} />
            <Route path="/blog" element={<Bloginfo />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/offers" element={<Offer />} />
            <Route path="/offer-details" element={<OfferDetails />} />
            <Route path="/travel-stories" element={<TravelStories />} />
            <Route path="/plane" element={<Plane />} />
            <Route path="/yacht" element={<Yacht />} />
            <Route path="/flight-tracker" element={<FlightTracker />} />
          </>
        )}
      </Routes>
      {!isAdmin &&
      location.pathname !== "/contact-us" &&
      location.pathname !== "/profile" &&
      location.pathname !== "/flight-tracker" &&
      location.pathname !== "/Visa" ? (
        <UserFooter />
      ) : null}
      <div className="">{!isAdmin ? <BottomNavigationForMobile /> : null}</div>
    </div>
  );
};

export default App;
