import { m_logo } from "Utils/Images";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

export default function MaharajaLogo({ text, limited, servise }) {
  const navigate = useNavigate();
  return (
    <div
      className="flex flex-shrink-0 cursor-pointer items-start"
      onClick={() => {
        navigate("/");
        window.scrollTo(0, 0);
      }}
    >
      <img className=" h-12 w-12" src={m_logo} alt="Maharaja Flight Limited" />

      <div className="cursor-pointer leading-3">
        <div>
          <h4
            className={`madimi-one-regular relative top-6 text-xl ${text} whitespace-nowrap `}
          >
            aharaja {servise ? servise : "Flights"} {limited}
          </h4>
        </div>
        {/* <p className="text-sm text-white">Travel Buddies Here</p> */}
      </div>
    </div>
  );
}
