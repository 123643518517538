import React, { useEffect, useMemo, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import userResources from "api/userResources";
import { useNavigate } from "react-router-dom";
import { MdDelete, MdEdit } from "react-icons/md";
import Select from "react-tailwindcss-select";
import {
  FaCar,
  FaPlane,
  FaShip,
  FaSun,
  FaUser,
  FaUsers,
  FaUserSecret,
  FaUserShield,
  FaUserTie,
} from "react-icons/fa";
import Crossicon from "components/CrossIcon/Crossicon";
import { toast } from "react-toastify";

export default function OffersData() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const [offerFor, setOfferfor] = useState({
    value: 1,
    label: "FLIGHT",
  });
  const [options, setOptions] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const getDealsFor = async () => {
    try {
      const response = await userResources.getDealList();
      const icons = [FaUser, FaUserTie, FaUsers, FaUserShield, FaUserSecret];

      const formattedRoles = response.data.data.map((item) => ({
        value: item.id,
        label: item.name,
        icon: icons[Math.floor(Math.random() * icons.length)],
      }));

      setOptions(formattedRoles);
    } catch (error) {
      console.log("Failed to fetch roles:", error);
    }
  };

  const handleChangeRole = (selectedOption) => {
    setOfferfor(selectedOption);
  };

  const deleteOffer = async (id) => {
    try {
      const response = await userResources.deleteOfferApi(id);
      if (response.status) {
        getOffers();
        toast.success("Offer deleted successfully!"); // Success toast
      } else {
        toast.error("Failed to delete the offer. Please try again."); // Error toast
      }
    } catch (error) {
      console.log("Failed to delete offer:", error);
      toast.error("An error occurred while deleting the offer."); // Error toast on failure
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "deal", // The key in your data object
        header: "Deals for", // Column header
        size: 250, // Column width
        Cell: ({ row }) => row?.original?.deal?.name, // Correctly accesses the original row data
      },
      {
        accessorKey: "heading",
        header: "Heading",
        size: 250,
      },
      {
        accessorKey: "image",
        header: "Image",
        size: 250,
        Cell: ({ cell }) => (
          <div className="text-xs">
            <img
              className="h-20 w-20"
              src={`https://api.maharajaflights.com/storage/${cell.getValue()}`}
              alt="Elon Musk"
            />
          </div>
        ), // Correctly accesses the original row data
      },
      {
        accessorKey: "paragraph",
        header: "Paragraph",
        size: 250,
      },
      {
        accessorKey: "inner_image",
        header: "Inner Image",
        size: 250,
        Cell: ({ cell }) => (
          <div className="text-xs">
            <img
              className="h-20 w-20"
              src={`https://api.maharajaflights.com/storage/${cell.getValue()}`}
              alt="Elon Musk"
            />
          </div>
        ), // Correctly accesses the original row data
      },
      {
        accessorKey: "long_paragraph",
        header: "Long Paragraph",
        size: 450,
        Cell: ({ cell }) => <div className="text-xs">{cell.getValue()}</div>, // Correctly accesses the original row data
      },
      {
        accessorKey: "coupon", // The key in your data object
        header: "Deals for", // Column header
        size: 250, // Column width
        Cell: ({ row }) => (
          <div className="flex flex-col">
            <p>Name: {row?.original?.coupon?.name}</p>
            <p>Discount: {row?.original?.coupon?.discount}</p>
            <p>Coupon code: {row?.original?.coupon?.coupon_code}</p>
            <p>Expiry date: {row?.original?.coupon?.expiry_date}</p>
          </div>
        ), // Correctly accesses the original row data
      },
      {
        accessorKey: "edit", // Key for the column
        header: "Edit", // Column header
        size: 250, // Column size
        Cell: ({ row }) => (
          <div className="flex items-center">
            <MdEdit
              size={20}
              style={{ cursor: "pointer", color: "blue" }} // Icon style
              onClick={() => {
                navigate("/admin/create-offers", {
                  state: { rowData: row.original },
                });
              }}
            />
            <MdDelete
              size={20}
              style={{ cursor: "pointer", color: "red" }} // Icon style
              onClick={() => {
                deleteOffer(row.original.id);
              }}
            />
          </div>
        ),
      },
    ],
    []
  );

  const getOffers = async (pageIndex, pageSize) => {
    setIsLoading(true); // Set loading state to true when fetching starts
    try {
      const response = await userResources.offerList(
        pageIndex || 0,
        pageSize || 5
        // offerFor?.value || ""
      );
      setData(response.data.data?.[offerFor.label]);
      setTotalRows(response?.data?.paginate_data?.total); // Set total rows for pagination
    } catch (error) {
      console.error("Error fetching offers:", error);
    } finally {
      setIsLoading(false); // Set loading state to false when fetching ends
    }
  };

  const handleGlobalFilterChange = (value) => {
    setGlobalFilter(value); // Update the global filter state with the new value
  };

  // Calculate total pages
  // const totalPages = Math.ceil(airlinesData.length / pagination.pageSize);

  useEffect(() => {
    getOffers();
    getDealsFor();
  }, []);
  useEffect(() => {
    getOffers();
  }, [offerFor]);

  useEffect(() => {
    getOffers(pagination.pageIndex, pagination.pageSize, globalFilter);
  }, [pagination, globalFilter]);

  const handlePaginationChange = (updatedPagination) => {
    setPagination(updatedPagination);
  };

  return (
    <section className="relative mt-5">
      <div className="mb-2">
        <Select
          value={offerFor}
          onChange={handleChangeRole}
          options={options}
          formatOptionLabel={({ label }, meta = {}) => {
            const { isSelected } = meta;

            const Icon =
              label === "HOT DEALS"
                ? FaUserTie
                : label === "CAR"
                ? FaCar
                : label === "HOLIDAY"
                ? FaSun
                : label === "CRUISE"
                ? FaShip
                : label === "FLIGHT"
                ? FaPlane
                : FaUserShield; // Default icon if no condition is met

            return (
              <div
                className={` block cursor-pointer select-none truncate rounded px-2 py-2 transition duration-200 ${
                  isSelected
                    ? `bg-gray-900 text-white`
                    : `text-gray-900 hover:bg-gray-900 hover:text-white`
                }`}
              >
                <div className="flex items-center">
                  {Icon && <Icon className="mr-2" />}
                  <span>{label}</span>
                </div>
              </div>
            );
          }}
          classNames={{
            menuButton: ({ isDisabled }) =>
              `flex text-sm w-full cursor-pointer text-gray-900 dark:text-white justify-between px-2 dark:bg-transparent border border-gray-300 dark:!border-white/10 rounded shadow-sm transition-all duration-300 focus:outline-none ${
                isDisabled ? "bg-gray-200" : "bg-white "
              }`,
            menu: "absolute top-10 z-10 w-full px-0 pl-0 bg-white shadow-lg border rounded py-1 mt-1.5 border-gray-300",
          }}
        />
        <div
          className="absolute -right-2 -top-2"
          onClick={() => setOfferfor(null)}
        >
          <Crossicon />
        </div>
      </div>
      <div className="">
        <MaterialReactTable
          columns={columns}
          data={data}
          state={{ pagination, isLoading }} // Use both states here
          // state={{ pagination, isLoading, showProgressBars: isLoading }} // Use both states here
          enablePagination
          onPaginationChange={handlePaginationChange}
          manualPagination
          rowCount={totalRows}
          pageCount={Math.ceil(totalRows / pagination.pageSize)}
          isLoading={isLoading}
          enableColumnFilters={false}
          enableSorting={false}
          enableColumnOrdering={false}
          muiTablePaginationProps={{
            showFirstButton: true,
            showLastButton: true,
          }}
          enableGlobalFilter={false} // Keep this true to allow global filtering
          onGlobalFilterChange={handleGlobalFilterChange} // Update the state on filter change
          globalFilter={globalFilter} // Pass the state variable to the table
          renderDetailPanel={({ row }) => (
            <div style={{ padding: "6px" }} className="max-w-7xl">
              {/* Check if terms exist and are an array */}
              {row.original.terms.length > 0 ? (
                <div>
                  <ul className="list-disc">
                    {row.original.terms.map((item) => (
                      <li key={item.id}>{item.name}</li>
                    ))}
                  </ul>
                </div>
              ) : (
                <p>No terms available.</p> // Fallback message
              )}
            </div>
          )}
        />
      </div>
    </section>
  );
}
