import React from "react";
import { useNavigate } from "react-router-dom";

export default function DealsCard({ item }) {
  
  const Navigate = useNavigate();
  return (
    <div className="card card-side cursor-pointer flex-wrap rounded-md sm:flex-nowrap ">
      <div
        className="h-64 w-full rounded-md sm:h-full sm:w-1/2"
        style={{
          backgroundImage: `url(https://api.maharajaflights.com/storage/${item?.image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        {/* <img
          src={item.Image}
          className="h-64 w-full rounded-md sm:block sm:h-full"
          alt="Image description"
        /> */}
      </div>
      <div className="card-body w-1/2 py-3 px-3">
        <h2 className="card-title text-sm sm:text-lg ">{item?.heading}</h2>
        <p>{item?.paragraph}</p>
        <div className="card-actions justify-end">
          <button
            // onClick={() =>
            //   Navigate(`/offer-details?${item.heading}`, {
            //     state: { data: item },
            //   })
            // }
            onClick={() =>
              Navigate(
                `/offer-details?${item?.heading
                  .toLowerCase()
                  .replace(/\s+/g, "-")}`,
                {
                  state: { data: item },
                }
              )
            }
            className="text-red-500"
          >
            BOOK NOW
          </button>
        </div>
      </div>
    </div>
  );
}
