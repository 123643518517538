import React from "react";

export default function VisaCard({ image, flag, heading, rank }) {
  return (
    <div className="w-52 rounded-xl border border-red-100  p-5 shadow-lg sm:w-72">
      <div className=" w-full rounded-r-lg">
        <img src={image} alt={heading} className="w-full" />
      </div>
      <div className="mt-5 flex items-center justify-start gap-5">
        <img src={flag} alt="flag" className="w-10" />
        <div>
          <h2>{heading}</h2>
          <p className="font-semibold">Rank: {rank}</p>
        </div>
      </div>
    </div>
  );
}
