import React from "react";
import About from "components/Charters/About";
import Banner from "components/Charters/Banner";
import BookingForm from "components/Charters/BookingForm";
import WhychooseUs from "components/Charters/WhychooseUs";
import UserNavbar from "components/UserNavbar/UserNavbar";
import { yacht } from "Utils/Images";
import { yachtsm } from "Utils/Images";
import { aboutyacht } from "Utils/Images";
import { aboutyachtcon } from "Utils/Images";

export default function Yacht() {
  const reasons = [
    {
      title: "Tailored Experiences",
      description:
        "Our boat charters are fully customizable to suit your preferences and desires. From the type of vessel to the itinerary and onboard amenities, we tailor every aspect of your journey to create a truly personalized experience.",
    },
    {
      title: "Luxurious Accommodations",
      description:
        "Step aboard our meticulously maintained fleet of luxury yachts and boats, equipped with state-of-the-art facilities and elegant furnishings. Whether you're hosting an intimate gathering or a grand celebration, our vessels provide the perfect setting for unforgettable moments.",
    },
    {
      title: "Expert Crew",
      description:
        "Our experienced captains and crew members are dedicated to ensuring your safety and comfort throughout your voyage. From navigating the waters to providing impeccable service, our team is committed to delivering a seamless and memorable experience.",
    },
    {
      title: "Scenic Destinations",
      description:
        "Explore breathtaking coastal landscapes, secluded coves, and vibrant marine life as you cruise to some of the world's most picturesque destinations. Whether you're dreaming of turquoise waters and white sandy beaches or rugged coastlines and dramatic cliffs, our boat charters offer access to a myriad of stunning locales.",
    },
    {
      title: "Unparalleled Service",
      description:
        "At Maharaja Flights Limited, customer satisfaction is our top priority. From the moment you inquire about our boat charters to the conclusion of your journey, our dedicated team is here to ensure every detail is taken care of, allowing you to relax and enjoy the experience to the fullest.",
    },
  ];

  return (
    <section className="text-gray-800">
      <UserNavbar />

      <Banner
        charterplane={yacht}
        charterplanesm={yachtsm}
        heading={"Charter Your Private Yacht Today"}
        paragraph={
          "Experience Seamless Travel with Maharaja Flights Limited's Yacht Charter Services"
        }
        darkness={"rgba(0, 0, 0, 0.4)"}
        button={"Booking Enquiry"}
      />

      <div className="m-auto max-w-8xl">
        <About
          bussinesperson={aboutyacht}
          heading={"ABOUT OUR YACHTS"}
          shortParagraph={"Experience luxury travel on a private yacht"}
          paragraph={
            "Embark on a journey of luxury and indulgence with Maharaja Flights Limited's yacht charters. Whether you're planning a romantic getaway, a family vacation, or a corporate retreat, our fleet of luxurious yachts offers the perfect setting for your next adventure on the water. With impeccable service, luxurious amenities, and breathtaking destinations, we promise an unforgettable experience from the moment you step on board."
          }
        />
        <WhychooseUs
          heading={"Why Choose Maharaja Flights Limited's Yacht Charters?"}
          reasons={reasons}
        />
        <BookingForm image={aboutyachtcon} resoures={"yachtCharter"} />
      </div>
    </section>
  );
}
